import React from "react";
import { Link } from "react-router-dom";
import { LastOrderScrollView } from "../LastOrderScrollView";
import { getStateDescription, getStatusIconPath } from "../Utils/stateIconMapping";
import { ErrorPopup } from "../Utils/ErrorPopup";
import { NavigationbarTop } from "../NavigationbarTop";
import "./OrderDetailDesktop.css";
import {Footer} from "../Footer/Footer";

export default function OrderDetailDesktop({
                                               order,
                                               orderDate,
                                               netPrice,
                                               taxPrice,
                                               formattedTotalPrice,
                                               initiateCancelOrder,
                                               errorMessage,
                                               handleCloseErrorPopup,
                                           }) {
    return (
        <div className="order-detail-desktop">
            <NavigationbarTop />
            <div className="order-detail-desktop-content">
                <aside className="order-detail-desktop-aside">
                    <div className="order-detail-desktop-header">
                        <h1>Meine Bestellung</h1>

                    </div>

                    <div className="order-detail-desktop_info-section">
                        <div className="order-detail-desktop-meta">
                            <p>Datum: {orderDate}</p>
                            <p className="order-detail-desktop_status-line">
                                Status: {getStateDescription(order.state)}
                                <img
                                    src={getStatusIconPath(order.state)}
                                    alt="Status Icon"
                                    className="status-icon"
                                />
                            </p>
                            <p>Bestellnummer: {order.orderId}</p>
                            {/* cancel button only visible when pending state */}
                            {order?.state === 'PENDING' && (
                                <button
                                    className="account-screen-desktop_cancel-button"
                                    onClick={() => initiateCancelOrder(order?.orderId)}
                                >
                                    Bestellung stornieren
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="order-detail-desktop_back-link-container">
                        <Link to="/myorder-screen" className="order-detail-desktop_back-link">
                            <img className="order-detail-desktop_back-arrow" alt="Back" src="/img/ep_back_blue.png" />
                            Zurück
                        </Link>
                    </div>
                </aside>

                <div className="order-detail-desktop-main">
                    <div className="order-detail-desktop-products">
                        <h2>Produkte</h2>
                        <LastOrderScrollView products={order.productOrders}/>
                    </div>

                    <div className="order-detail-desktop-summary-section">
                        <h2>Rechnungsübersicht</h2>
                        {/* product table*/}
                        <div className="order-detail-desktop-items-list">
                            <div className="order-detail-desktop-item-row header">
                                <span className="order-detail-desktop_item-name">Pilzsorte</span>
                                <span className="order-detail-desktop_item-weight">Menge</span>
                                <span className="order-detail-desktop_item-price">Preis</span>
                            </div>

                            {order.productOrders && Array.isArray(order.productOrders) ? (
                                order.productOrders.map((product) => (
                                    <div className="order-detail-desktop-item-row" key={product.productOrderId}>
                                        <span
                                            className="order-detail-desktop_item-name">{product.mushroomSpecies}</span>
                                        <span className="order-detail-desktop_item-weight">{product.weightG} g</span>
                                        <span
                                            className="order-detail-desktop_item-price">{product.price.toFixed(2)} €</span>
                                    </div>
                                ))
                            ) : (
                                <div className="order-detail-desktop-item-row">
                                    <span className="order-detail-desktop_item-name">Keine Produkte</span>
                                    <span className="order-detail-desktop_item-weight"></span>
                                    <span className="order-detail-desktop_item-price"></span>
                                </div>
                            )}

                        </div>
                        <div className="summary-divider-row"/>

                        {/* summary part */}
                        <div className="summary-right-container">
                            <div className="summary-content">
                                <div className="summary-line">
                                    <span className="summary-label">Gesamtgewicht:</span>
                                    <span className="summary-value">{order.totalWeight} g</span>
                                </div>
                                <div className="summary-line">
                                    <span className="summary-label">Netto:</span>
                                    <span className="summary-value">{netPrice} €</span>
                                </div>
                                <div className="summary-line">
                                    <span className="summary-label">USt:</span>
                                    <span className="summary-value">{taxPrice} €</span>
                                </div>
                                <div className="summary-line total-line">
                                    <span className="summary-label">Gesamtsumme:</span>
                                    <span className="summary-value">{formattedTotalPrice} €</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                {errorMessage && (
                <ErrorPopup message={errorMessage} onClose={handleCloseErrorPopup} />
            )}
            <Footer/>
        </div>
    );
}
