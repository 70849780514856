import React from "react";
import { formatRelativeTime } from "../../Utils/formateRelativeTime";
import "./NotificationsDekstopStyle.css";
import {NavigationbarTop} from "../../components/NavigationbarTop";
import {Footer} from "../../components/Footer/Footer";

export default function NotificationsDesktopScreen({ notifications, handleDeleteClick, handleBackClick }) {
    return (
        <>
            <NavigationbarTop/>

        <div className="notifications-desktop-layout">
            <aside className="notifications-desktop-aside">
                <h1 className="aside-title">Mitteilungen</h1>
                <p className="aside-subtitle">
                    Hier findest du alle Benachrichtigungen auf einen Blick.
                </p>
                <button
                    className="notifications-back-button"
                    onClick={handleBackClick}
                >
                    <img src="/img/ep_back_blue.png" alt="Zurück" className="feedback-desktop_back-link-icon"/>
                    <span className="feedback-desktop_back-link-text">Zurück</span>
                </button>
            </aside>

            <div className="notifications-desktop-main">
                <div className="notifications-header">

                    <h1 className="notifications-title">Deine Mitteilungen</h1>
                </div>

                {notifications.length > 0 ? (
                    <div className="notifications-desktop-list">
                        {notifications
                            .slice()    // copy
                           // .reverse()  // newest first
                            .map((notification) => (
                                <div className="notifications-desktop-item" key={notification.id}>
                                    <div className="notifications-desktop-content">
                                        <h3 className="notifications-desktop-item-title">{notification.title}</h3>
                                        <p className="notifications-desktop-item-body">{notification.body}</p>
                                        <span className="notifications-desktop-timestamp">
                                            {formatRelativeTime(notification.timestamp)}
                                        </span>
                                    </div>
                                    <button
                                        className="notifications-desktop-delete-button"
                                        onClick={() => handleDeleteClick(notification.id)}
                                        title="Benachrichtigung löschen"
                                    >
                                        <img
                                            className="notifications-desktop-delete-icon"
                                            src="/img/trash-icon-27.png"
                                            alt="Remove"
                                        />
                                    </button>
                                </div>
                            ))}
                    </div>
                ) : (
                    <div className="notifications-desktop_no-notifications-message">
                        Keine Benachrichtigungen vorhanden.
                    </div>
                )}
            </div>
        </div>
            <Footer/>
        </>
    );
}
