/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const KingTrumpetIcon = ({ className }) => {
  return (
    <div className={`king-trumpet-icon ${className}`}>
      <img
        className="img-2"
        alt="King trumpet icon"
        src="/img/king-trumpet-icon-1-3.png"
      />
    </div>
  );
};
