import React from "react";
import "./FeedbackScreenDesktop.css";
import { NavigationbarTop } from "../../components/NavigationbarTop";
import ValidationInput from "../../Validation/ValidationInput";
import { Footer } from "../../components/Footer/Footer";

export default function FeedbackScreenDesktop(props) {
    const {
        sliderValue,
        handleSliderChange,
        emojiData,
        handleEmojiClick,
        selectedEmoji,
        orderNumber,
        setOrderNumber,
        validateOrderNumber,
        feedbackText,
        setFeedbackText,
        handleSendFeedback,
        handleBackClick,
    } = props;

    return (
        <>
            <NavigationbarTop />
            <div className="feedback-desktop-layout">
                <aside className="feedback-desktop-aside">
                    <h1 className="feedback-aside-title">Feedback</h1>
                    <button onClick={handleBackClick} className="feedback-desktop-back-button">
                        <img src="/img/ep_back_white.png" alt="Zurück" className="feedback-desktop_back-link-icon"/>
                        <span className="feedback-desktop_back-link-text">Zurück</span>
                    </button>
                </aside>

                <div className="feedback-desktop-main">
                    <div className="feedback-desktop-content">
                        <h2 className="feedback-desktop-heading">Teile deine Erfahrung</h2>
                        <p className="feedback-desktop-subheading">
                            Wähle eine Bewertung und teile dein Feedback.
                        </p>

                        <div className="feedback-desktop-emoji-container">
                            {emojiData.map((emoji) => (
                                <div
                                    key={emoji.id}
                                    className={`feedback-desktop-emoji-card ${sliderValue === emoji.id ? "selected" : ""}`}
                                    onClick={() => handleEmojiClick(emoji.id)}
                                >
                                    <img
                                        src={selectedEmoji === emoji.id ? emoji.icon : emoji.staticIcon}
                                        alt={emoji.label}
                                    />
                                    <span>{emoji.label}</span>
                                </div>
                            ))}
                        </div>

                        <div className="feedback-desktop-slider-container">
                            <input
                                type="range"
                                min="1"
                                max="5"
                                step="1"
                                value={sliderValue}
                                className="feedback-desktop-slider"
                                onChange={(e) => handleSliderChange(Number(e.target.value))}
                            />
                        </div>

                        <ValidationInput
                            value={orderNumber}
                            onChange={setOrderNumber}
                            placeholder="Bestellnummer (optional)"
                            validate={validateOrderNumber}
                            validationMessage="Die Bestellnummer muss eine positive ganze Zahl sein."
                            className="feedback-desktop-input"
                            inputProps={{ type: "number" }}
                        />

                        <textarea
                            className="feedback-desktop-textarea"
                            placeholder="Teile hier dein Feedback zur App, Website, Bestellung oder Pilzsorte..."
                            value={feedbackText}
                            onChange={(e) => setFeedbackText(e.target.value)}
                        ></textarea>

                        <button className="feedback-desktop_send-button" onClick={handleSendFeedback}>
                            Senden
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
