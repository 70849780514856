import React from "react";
import "./style.css";
import {useLocation, useNavigate} from "react-router-dom";


export const NavbarBottomPhone = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // navigation elements with active and inactive state
    const navItems = [
        {
            label: "Pilze",
            path: "/start-screen",
            iconActive: "/img/mushroom-icon-active.png",
            iconInactive: "/img/mushroom-icon-inactive.png",
        },
        {
            label: "Warenkorb",
            path: "/shoppingcart-screen",
            iconActive: "/img/shopping-cart-icon-active.png",
            iconInactive: "/img/shopping-cart-icon-inactive.png",
        },
        {
            label: "Ort",
            path: "/location-screen",
            iconActive: "/img/location-icon-active.png",
            iconInactive: "/img/location-icon-inactive.png",
        },
        {
            label: "Account",
            path: "/account-screen",
            iconActive: "/img/account-icon-active.png",
            iconInactive: "/img/account-icon-inactive.png",
        },
    ];

    return (
        <div className="navbar-bottom-phone">
            {navItems.map((item, index) => {
                const isActive = location.pathname === item.path || (index === 0 && location.pathname === '/');     //active path or start screen when entering first time
                return (
                    <div
                        key={index}
                        className={`navbar-item ${isActive ? "active" : ""}`}
                        onClick={() => navigate(item.path)}
                    >
                        <img
                            src={isActive ? item.iconActive : item.iconInactive}
                            alt={`${item.label} Icon`}
                            className="navbar-icon-img"
                        />
                        <div className={`navbar-label ${isActive ? 'active-label' : 'inactive-label'}`}>
                            {item.label}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
