// Footer.jsx
import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                {/* Company Logo */}
                <div className="footer-logo">
                    <img
                        src="/img/leonardo-phoenix-change-the-lettering-to-darkstone-mycology-as-0-7.png"
                        alt="Company Logo"
                        className="footer-company-logo"
                    />
                </div>

                {/* Footer Sections */}
                <div className="footer-sections">
                    <div className="footer-section">
                        <h3>Account</h3>
                        <ul>
                            <li><Link to="/desktop-account">Profil</Link></li>
                            <li><Link to="/desktop-shoppingcart">Warenkorb</Link></li>
                            <li><Link to="/desktop-order-details">Bestellungen</Link></li>
                            <li><Link to="/desktop-favorites">Favoriten</Link></li>
                            <li><Link to="/desktop-notifications">Benachrichtigungen</Link></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>Nützliches</h3>
                        <ul>
                            <li><Link to="/about-us">Über mich</Link></li>
                            <li><Link to="/contact">Kontakt</Link></li>
                            <li><Link to="/recipes">Rezepte</Link></li>
                            <li><Link to="/promotions">Promotions</Link></li>
                            <li><Link to="/new-arrivals">Neuheiten</Link></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>Hilfe Center</h3>
                        <ul>
                            <li><Link to="/payment-options">Zahlungsmöglichkeiten</Link></li>
                            <li><Link to="/pickups">Abholungen</Link></li>
                            <li><Link to="/faq">Q&A</Link></li>
                            <li><Link to="/business-customers">Business Kunden</Link></li>
                            <li><Link to="/desktop-impressum">Impressum</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};
