import React from "react";
import "./errorStyle.css";

export const ErrorPopup = ({ message, onClose }) => {
    const isNetworkError = ["keine verbindung", "network error", "netzwerkfehler"]
        .some((errorPhrase) => (message?.toLowerCase() || "").includes(errorPhrase));


    const handleReload = () => {
        window.location.reload();
    };


    return (
        <div className="error-popup-overlay">
            <div className="error-popup">
                <p>{message}</p>
                <div className="error-popup-buttons">
                    <button className="error-popup-button-ok" onClick={onClose}>OK</button>
                    {isNetworkError && (
                        <button onClick={handleReload} className="reload-button">
                            Neu laden
                        </button>
                    )}

                </div>
                </div>
            </div>
            );
            };
