import React from "react";
import "./TermsOfService.css";

export const TermsOfService = () => {
    return (
        <div className="terms-of-service-container">
            <h1>Nutzungsbedingungen</h1>
            <p>Mit der Nutzung unserer Anwendung akzeptieren Sie die folgenden Bedingungen:</p>
            <ul>
                <li>Sie dürfen diese Anwendung nur für rechtmäßige Zwecke nutzen.</li>
                <li>Wir übernehmen keine Haftung für die Nutzung unserer Anwendung.</li>
                <li>Ihre Daten werden gemäß unserer Datenschutzerklärung behandelt.</li>
            </ul>
        </div>
    );
};
