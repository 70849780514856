import React from 'react';
import './NotificationsHelpDesktop.css';
import {NavigationbarTop} from "../../components/NavigationbarTop";
import {Footer} from "../../components/Footer/Footer";


const NotificationsHelpDesktop = () => {
    return (
        <>
            <NavigationbarTop/>
        <div className="notifications-help-desktop-container">
            {/* section 1: add to home screen*/}
            <section className="pwa-installation-card">
                <h2>PWA zum Home-Bildschirm hinzufügen</h2>
                <p>Füge die Progressive Web App (PWA) zu deinem Home-Bildschirm hinzu, um eine native App-ähnliche Erfahrung zu erhalten.</p>

                <h3>Android (Chrome)</h3>
                <ol>
                    <li>Öffne Chrome auf deinem Android-Gerät.</li>
                    <li>Gehe auf die Website <strong>www.darkstone-mycology.at</strong>.</li>
                    <li>Tippe rechts oben auf das Drei-Punkte-Menü <strong>⋮</strong>.</li>
                    <li>Wähle <strong>"Zum Home-Bildschirm hinzufügen"</strong> aus.</li>
                    <li>Tippe auf <strong>"Hinzufügen"</strong>, um die App auf deinem Home-Bildschirm zu speichern.</li>
                </ol>
                <h3>iOS (Safari)</h3>
                <ol>
                    <li>Öffne <strong>Safari</strong> auf deinem iPhone oder iPad und gehe auf <strong>www.darkstone-mycology.at</strong>.</li>
                    <li>Tippe unten auf das <strong>Teilen-Symbol (Quadrat mit Pfeil nach oben)</strong>.</li>
                    <li>Wähle <strong>"Zum Home-Bildschirm hinzufügen"</strong> aus.</li>
                    <li>Tippe auf <strong>"Hinzufügen"</strong>, um die App auf deinem Home-Bildschirm zu speichern.</li>
                </ol>
                <p>Durch das Hinzufügen der PWA zum Home-Bildschirm kannst du sie schnell und einfach starten.</p>
            </section>

            {/* section 2: notification*/}
            <section className="notifications-activation-card">
                <h2>Benachrichtigungen aktivieren</h2>
                <p>Stelle sicher, dass du Benachrichtigungen in den Einstellungen deines Geräts und Browsers aktiviert hast. Wenn du
                einen Ad-Blocker verwendest füge diese Website als Ausnahme hinzu oder deaktiviere ihn.</p>

                <h3>Android (Chrome)</h3>
                <ol>
                    <li>Öffne Chrome auf deinem Android-Gerät.</li>
                    <li>Gehe auf die Website <strong>www.darkstone-mycology.at</strong>.</li>
                    <li>Tippe rechts oben auf das Drei-Punkte-Menü <strong>⋮</strong>.</li>
                    <li>Wähle <strong>"Einstellungen"</strong> aus.</li>
                    <li>Scrolle nach unten zu <strong>"Website-Einstellungen"</strong> und tippe darauf.</li>
                    <li>Wähle <strong>"Benachrichtigungen"</strong>.</li>
                    <li>Suche <strong>www.darkstone-mycology.at</strong> in der Liste und stelle sicher, dass Benachrichtigungen erlaubt sind.</li>
                </ol>
                <p>Sollte die Seite bereits blockiert sein, kannst du sie hier wieder freigeben.</p>

                <h3>iOS (Safari)</h3>
                <p>Für iOS sind Web-Push-Benachrichtigungen nur verfügbar, wenn du die PWA zum Home-Bildschirm hinzugefügt hast (iOS 16.4 oder neuer).</p>
                <ol>
                    <li>Öffne <strong>Safari</strong> auf deinem iPhone oder iPad und gehe auf <strong>www.darkstone-mycology.at</strong>.</li>
                    <li>Tippe unten auf das <strong>Teilen-Symbol (Quadrat mit Pfeil nach oben)</strong>.</li>
                    <li>Wähle <strong>"Zum Home-Bildschirm hinzufügen"</strong> aus.</li>
                    <li>Tippe auf <strong>"Hinzufügen"</strong>, um die App auf deinem Home-Bildschirm zu speichern.</li>
                </ol>
                <p>Nun kannst du in den iOS-Systemeinstellungen die Benachrichtigungseinstellungen für diese Web-App anpassen:</p>
                <ol>
                    <li>Öffne die <strong>Einstellungen</strong> deines iPhones/iPads.</li>
                    <li>Scrolle zu der neu hinzugefügten App (Name deiner PWA) in der Liste.</li>
                    <li>Tippe darauf und wähle <strong>"Mitteilungen"</strong>.</li>
                    <li>Aktiviere hier Benachrichtigungen.</li>
                </ol>
            </section>

            {/* section 3: PWA */}
            <section className="pwa-overview-card">
                <h2>Was ist eine Progressive Web App (PWA)?</h2>
                <p>
                    Eine Progressive Web App (PWA) kombiniert die besten Eigenschaften von Webseiten und nativen Apps. Sie bietet eine schnelle,
                    zuverlässige und ansprechende Benutzererfahrung direkt in deinem Browser.
                </p>
                <h3>Vorteile der PWA:</h3>
                <ul>
                    <li><strong>Vollbildmodus:</strong> Genieße die App ohne Browserleiste für eine immersive Erfahrung.</li>
                    <li><strong>Offline-Funktionalität:</strong> Greife auch ohne Internetverbindung auf wichtige Funktionen zu.</li>
                    <li><strong>Schnelle Ladezeiten:</strong> Verbesserte Performance durch Caching und optimierte Ressourcen.</li>
                    <li><strong>Einfache Installation:</strong> Füge die App einfach zum Home-Bildschirm hinzu und starte sie wie eine native App.</li>
                </ul>
                <p>
                    Durch das Hinzufügen der PWA zum Home-Bildschirm kannst du die App schneller starten und von den oben genannten Vorteilen profitieren.
                </p>
            </section>
        </div>
        <Footer/>
        </>
    );
}

export default NotificationsHelpDesktop;
