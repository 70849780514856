export const validatePhoneNumber = (phoneNumber) => {
    const PHONE_PATTERN = /^\+?[0-9 ()-]{7,25}$/;


    if (!PHONE_PATTERN.test(phoneNumber)) {
        return "Ungültige Telefonnummer: 7–25 Zeichen, nur Zahlen, Klammern, Bindestriche und ein Pluszeichen erlaubt.";
    }

    return null;
};
