import React from "react";
import { ErrorPopup } from "../../components/Utils/ErrorPopup";
import { SuccessPopup } from "../../components/Utils/SuccessPopup";
import { NavigationbarTop } from "../../components/NavigationbarTop";
import {validatePassword} from "../../Validation/validatePassword";
import {validateConfirmPassword} from "../../Validation/validateConfirmPassword";
import "./PasswordChangeDesktop.css";

export default function PasswordChangeDesktop(props) {
    const {
        oldPassword, setOldPassword,
        newPassword, setNewPassword,
        confirmPassword, setConfirmPassword,
        newPasswordError, setNewPasswordError,
        confirmPasswordError, setConfirmPasswordError,
        touchedFields,
        handleBackClick,
        handleUpdate,
        handleInputChange,
        showSuccessPopup,
        responseMessage,
        errorMessage,
        handleCloseErrorPopup,
        handleCloseSuccessPopup,
    } = props;

    return (
        <>
            <NavigationbarTop />
            <div className="password-change-desktop-layout">
                <aside className="password-change-desktop-aside">
                    <div className="password-change-desktop-aside-header">
                        <h1>Passwort ändern</h1>
                        <p>Hier kannst du dein Passwort ändern. Passwörter müssen mindestens 6 Zeichen lang sein
                        und aus Buchstaben und Zahlen bestehen!</p>
                        <button className="login-desktop_aside-back-button" onClick={handleBackClick}>
                            <img src="/img/ep_back_white.png" alt="Zurück" className="login-desktop_back-link-icon"/>
                            <span className="login-desktop_back-link-text">Zurück</span>
                        </button>
                    </div>
                </aside>

                <div className="password-change-desktop-main">
                    <div className="password-change-desktop-form-container">

                        {/* old password */}
                        <div className="desktop-input-container">
                        <input
                                type="password"
                                className="desktop-input-field"
                                placeholder=" "
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                            <label className="desktop-input-label">altes Passwort</label>
                        </div>

                        {/* new password */}
                        <div
                            className={`desktop-input-container ${newPasswordError ? "invalid" : touchedFields.newPassword && newPassword ? "valid" : ""}`}>
                            <input
                                type="password"
                                className="desktop-input-field"
                                placeholder=" "
                                value={newPassword}
                                onChange={(e) =>
                                    handleInputChange(
                                        e.target.value,
                                        setNewPassword,
                                        setNewPasswordError,
                                        validatePassword,
                                        "newPassword"
                                    )
                                }
                            />
                            <label className="desktop-input-label">neues Passwort</label>
                            <span
                                className={`desktop-validation-icon ${newPasswordError ? "" : newPassword ? "valid" : ""}`}>
                                {newPasswordError ? "❌" : newPassword ? "✅" : ""}
                             </span>
                            {newPasswordError && <p className="desktop-validation-error">{newPasswordError}</p>}
                        </div>

                        <div
                            className={`desktop-input-container ${confirmPasswordError ? "invalid" : touchedFields.confirmPassword && confirmPassword ? "valid" : ""}`}>
                            <input
                                type="password"
                                className="desktop-input-field"
                                placeholder=" "
                                value={confirmPassword}
                                onChange={(e) =>
                                    handleInputChange(
                                        e.target.value,
                                        setConfirmPassword,
                                        setConfirmPasswordError,
                                        (val) => validateConfirmPassword(newPassword, val),
                                        "confirmPassword"
                                    )
                                }
                            />
                            <label className="desktop-input-label">Passwort wiederholen</label>
                            <span
                                className={`desktop-validation-icon ${confirmPasswordError ? "" : confirmPassword ? "valid" : ""}`}>
                                {confirmPasswordError ? "❌" : confirmPassword ? "✅" : ""}
                            </span>
                            {confirmPasswordError && <p className="desktop-validation-error">{confirmPasswordError}</p>}
                        </div>

                        <button className="desktop-update-button" onClick={handleUpdate}>bestätigen</button>
                    </div>

                    {errorMessage && (
                        <ErrorPopup
                            message={errorMessage}
                            onClose={handleCloseErrorPopup}
                        />
                    )}

                    {showSuccessPopup && (
                        <SuccessPopup
                            message={responseMessage}
                            onClose={handleCloseSuccessPopup}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
