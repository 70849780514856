import React, { createContext, useContext, useReducer, useCallback } from "react";

// initial
const initialState = {
    orders: [],
};

// actions
const SET_ORDERS = "SET_ORDERS";
const ADD_ORDER = "ADD_ORDER";
const UPDATE_ORDER = "UPDATE_ORDER";
const REMOVE_ORDER = "REMOVE_ORDER";

// reducer
function orderReducer(state, action) {
    switch (action.type) {
        case SET_ORDERS:
            return {
                ...state,
                orders: action.payload,
            };
        case ADD_ORDER:
            return {
                ...state,
                orders: [...state.orders, action.payload],
            };
        case UPDATE_ORDER:
            return {
                ...state,
                orders: state.orders.map((order) =>
                    order.orderId === action.payload.orderId ? action.payload : order
                ),
            };
        case REMOVE_ORDER:
            return {
                ...state,
                orders: state.orders.filter(
                    (order) => order.orderId !== action.payload
                ),
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

// context
export const OrderContext = createContext(undefined);

// provider
export const OrderProvider = ({ children }) => {
    const [state, dispatch] = useReducer(orderReducer, initialState);

    // actions
    const setOrders = useCallback((orders) => {
        dispatch({ type: SET_ORDERS, payload: orders });
    }, []);

    const addOrder = useCallback((order) => {
        dispatch({ type: ADD_ORDER, payload: order });
    }, []);

    const updateOrder = useCallback((order) => {
        dispatch({ type: UPDATE_ORDER, payload: order });
    }, []);

    const removeOrder = useCallback((orderId) => {
        dispatch({ type: REMOVE_ORDER, payload: orderId });
    }, []);

    return (
        <OrderContext.Provider
            value={{
                orders: state.orders,
                setOrders,
                addOrder,
                updateOrder,
                removeOrder,
            }}
        >
            {children}
        </OrderContext.Provider>
    );
};

// custom Hook
export const useOrders = () => {
    const context = useContext(OrderContext);
    if (!context) {
        throw new Error("useOrders must be used within an OrderProvider");
    }
    return context;
};
