import { fetchOrdersByTimeRange} from "../../Utils/DatabaseFetch";
import {useFetchWithErrorHandling} from "../../Utils/genericFetchWithErrorHandling";
import {useAuth} from "../../context/AuthContext";
import {useOrders} from "../../context/OrderContext";
import {getStateDescription} from "../../components/Utils/stateIconMapping";
import {matchesDate, normalizeDateInput} from "../../Utils/normalizeDateInput";

import MyOrderScreenMobile from "./MyOrderScreenMobile";
import MyOrderScreenDesktop from "./MyOrderScreenDesktop";
import {useWindowWidth} from "../../breakpoints";
import {useEffect, useState} from "react";


export const MyOrderScreen = () => {
    const screenWidth = useWindowWidth();
    const { user } = useAuth();
    const [filterOption, setFilterOption] = useState("30Days"); // default
    const [yearOptions, setYearOptions] = useState([]); // dynamic year options
    const [searchTerm, setSearchTerm] = useState("");
    const { orders: contextOrders, setOrders } = useOrders();

    const { data: orders, loading, errorMessage, setErrorMessage } = useFetchWithErrorHandling(
        () => fetchOrdersByTimeRange(user.customerId, filterOption),
        [user?.customerId, filterOption]
    );




    useEffect(() => {
        if (orders && orders !== contextOrders) {
            setOrders(orders); // save in context only when changed
        }
    }, [orders, contextOrders, setOrders]);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = 2024; year <= currentYear; year++) {
            years.push(year);
        }
        setYearOptions(years);
    }, []);



    const filteredOrders = orders
        ? orders.filter((order) => {
            const term = searchTerm.toLowerCase().trim();

            // if no search term show everything
            if (!term) return true;

            // status-filter
            const stateDescription = getStateDescription(order.state);
            const matchesState = stateDescription
                ? stateDescription.toLowerCase().includes(term)
                : false;

            // species-filter
            const matchesMushroomSpecies = order.productOrders.some((product) =>
                product.mushroomSpecies.toLowerCase().includes(term)
            );

            // date-filter
            const matchesOrderDate = matchesDate(order.date, term);

            // show if filter matches
            return matchesState || matchesMushroomSpecies || matchesOrderDate;
        })
            .sort((a, b) => b.orderId - a.orderId) // last order first
        : [];

    const handleFilterChange = (event) => {
        setFilterOption(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleCloseErrorPopup = () => setErrorMessage(null);


    return screenWidth < 1024 ? (
        <MyOrderScreenMobile
            loading={loading}
            errorMessage={errorMessage}
            filteredOrders={filteredOrders}
            handleFilterChange={handleFilterChange}
            handleSearchChange={handleSearchChange}
            handleCloseErrorPopup={handleCloseErrorPopup}
            searchTerm={searchTerm}
            filterOption={filterOption}
            yearOptions={yearOptions}
        />
    ) : (
        <MyOrderScreenDesktop
            loading={loading}
            errorMessage={errorMessage}
            filteredOrders={filteredOrders}
            handleFilterChange={handleFilterChange}
            handleSearchChange={handleSearchChange}
            handleCloseErrorPopup={handleCloseErrorPopup}
            searchTerm={searchTerm}
            filterOption={filterOption}
            yearOptions={yearOptions}
        />
    );
};
