import React from "react";
import { Link } from "react-router-dom";
import { ErrorPopup } from "../../components/Utils/ErrorPopup";
import "./MyAccountDesktop.css";
import {NavigationbarTop} from "../../components/NavigationbarTop";
import {validateName} from "../../Validation/validateName";
import {validateEmail} from "../../Validation/validateEmail";
import {Footer} from "../../components/Footer/Footer";

export default function MyAccountDesktop(props) {
    const {
        firstName, setFirstName,
        lastName, setLastName,
        email, setEmail,
        phoneNumber, setPhone,
        address, setAddress,
        addressPlaceholder, setAddressPlaceholder,
        firstNameError, lastNameError, emailError,
        touchedFields,
        handleBackClick,
        handleUpdate,
        handleInputChange,
        showErrorPopup,
        errorMessage,
        handleCloseErrorPopup,
        handleConfirmDelete
    } = props;

    return (
        <>
        <NavigationbarTop />

        <div className="my-account-desktop-layout">
            <aside className="my-account-desktop-aside">
                <div className="my-account-desktop-aside-header">
                    <h1>Mein Konto</h1>
                    <p>Hier kannst du deine Kontodaten ändern.</p>
                    <button className="login-desktop_aside-back-button" onClick={handleBackClick}>
                        <img src="/img/ep_back_white.png" alt="Zurück" className="login-desktop_back-link-icon"/>
                        <span className="login-desktop_back-link-text">Zurück</span>
                    </button>
                </div>
            </aside>

            <div className="my-account-desktop-main">
                <div className="my-account-desktop-form-container">
                    {/* firstname */}
                    <div
                        className={`desktop-input-container ${firstNameError ? "invalid" : touchedFields.firstName && "valid"}`}>
                        <input
                            type="text"
                            className="desktop-input-field"
                            placeholder=" "
                            value={firstName}
                            onChange={(e) =>
                                handleInputChange(e.target.value, setFirstName, props.setFirstNameError, validateName, "firstName")
                            }
                        />
                        <label className="desktop-input-label">Vorname</label>
                        <span className={`desktop-validation-icon ${firstNameError ? "" : firstName ? "valid" : ""}`}>
                          {firstNameError ? "❌" : firstName ? "✅" : ""}
                        </span>
                        {firstNameError && <p className="desktop-validation-error">{firstNameError}</p>}
                    </div>

                    {/* lastname */}
                    <div
                        className={`desktop-input-container ${lastNameError ? "invalid" : touchedFields.lastName && "valid"}`}>
                        <input
                            type="text"
                            className="desktop-input-field"
                            placeholder=" "
                            value={lastName}
                            onChange={(e) =>
                                handleInputChange(e.target.value, setLastName, props.setLastNameError, validateName, "lastName")
                            }
                        />
                        <label className="desktop-input-label">Nachname</label>
                        <span className={`desktop-validation-icon ${lastNameError ? "" : lastName ? "valid" : ""}`}>
                          {lastNameError ? "❌" : lastName ? "✅" : ""}
                        </span>
                        {lastNameError && <p className="desktop-validation-error">{lastNameError}</p>}
                    </div>

                    {/* email */}
                    <div
                        className={`desktop-input-container ${emailError ? "invalid" : touchedFields.email && "valid"}`}>
                    <input
                            type="email"
                            className="desktop-input-field"
                            placeholder=" "
                            value={email}
                            onChange={(e) =>
                                handleInputChange(e.target.value, setEmail, props.setEmailError, validateEmail, "email")
                            }
                        />
                        <label className="desktop-input-label">E-Mail</label>
                        <span className={`desktop-validation-icon ${emailError ? "" : email ? "valid" : ""}`}>
                          {emailError ? "❌" : email ? "✅" : ""}
                        </span>
                        {emailError && <p className="desktop-validation-error">{emailError}</p>}
                    </div>

                    {/* phone number */}
                    <div className="desktop-input-container">
                        <input
                            type="text"
                            className="desktop-input-field"
                            placeholder=" "
                            value={phoneNumber}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <label className="desktop-input-label">Telefonnummer (optional)</label>
                    </div>

                    {/* address */}
                    <div className="desktop-input-container">
                        <input
                            type="text"
                            className="desktop-input-field"
                            placeholder={addressPlaceholder || " "}
                            onFocus={() => setAddressPlaceholder("Straße / Hausnummer / PLZ")}
                            onBlur={() => setAddressPlaceholder("")}
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                        <label className="desktop-input-label">Adresse (optional)</label>
                    </div>

                    <div className="my-account-desktop-button-container">
                        <button className="my-account-desktop-update-button" onClick={handleUpdate}>aktualisieren</button>

                        <Link className="my-account-desktop-password-change-link" to="/password-change">
                            Passwort ändern
                        </Link>


                        <button className="my-account-desktop_delete-account-button" onClick={handleConfirmDelete}>
                            Konto löschen
                        </button>
                    </div>
                </div>

                    {showErrorPopup && (
                        <ErrorPopup
                            message={errorMessage}
                            onClose={handleCloseErrorPopup}
                        />
                    )}
                </div>
            </div>
            <Footer/>
        </>
    );
}
