import React from "react";
import PropTypes from "prop-types";
import { MushroomImage } from "../Utils/MushroomImage";
import "./LastOrderScrollView.css";

export const LastOrderScrollView = ({ products }) => {
    if (!products || products.length === 0) {
        return <div className="no-orders">Keine Bestellungen gefunden</div>;
    }

    return (
        <div className="last-order-scrollview">
            {products.map((product, index) => (
                <div className="last-order-item" key={index}>
                    <MushroomImage mushroomSpecies={product.mushroomSpecies} size="medium" />
                    <div className="last-order-item-info">{product.weightG} g</div>
                </div>
            ))}
        </div>
    );
};


LastOrderScrollView.propTypes = {
    products: PropTypes.arrayOf(
        PropTypes.shape({
            mushroomSpecies: PropTypes.string.isRequired,
            weightG: PropTypes.number.isRequired,
        })
    ).isRequired,
};
