import React from "react";
import { NavbarBottomPhone } from "../../components/NavbarBottomPhone";
import ValidationInput from "../../Validation/ValidationInput";
import "./FeedbackScreenMobile.css";

export default function FeedbackScreenMobile(props) {
    const {
        sliderValue,
        handleSliderChange,
        emojiData,
        handleEmojiClick,
        selectedEmoji,
        orderNumber,
        setOrderNumber,
        validateOrderNumber,
        feedbackText,
        setFeedbackText,
        handleSendFeedback,
        handleBackClick,
    } = props;

    return (
        <div className="feedbackscreen">
            <header className="feedback-header">
                <img
                    className="feedbackscreen-back-icon"
                    src="/img/ep-back.png"
                    alt="Back"
                    onClick={handleBackClick}

                />
                <div className="feedbackscreen-title"> Feedback </div>
            </header>

            <div className="feedback-body">
                <p>Teile deine Erfahrung mit der Skala</p>
                <div className="emoji-container">
                    {emojiData.map((emoji) => (
                        <div
                            key={emoji.id}
                            className={`emoji ${sliderValue === emoji.id ? "selected" : ""}`}
                            onClick={() => handleEmojiClick(emoji.id)}
                        >
                            <img
                                src={selectedEmoji === emoji.id ? emoji.icon : emoji.staticIcon}
                                alt={emoji.label}
                            />
                            <span>{emoji.label}</span>
                        </div>
                    ))}
                </div>

                <input
                    type="range"
                    min="1"
                    max="5"
                    step="1"
                    value={sliderValue}
                    className="slider"
                    onChange={(e) => handleSliderChange(Number(e.target.value))}
                />

                <ValidationInput
                    value={orderNumber}
                    onChange={setOrderNumber}
                    placeholder="Bestellnummer (optional)"
                    validate={validateOrderNumber}
                    validationMessage="Die Bestellnummer muss eine positive ganze Zahl sein."
                    className="feedbackscreen-input"
                    inputProps={{type: "number"}}
                />

                <textarea
                    className="feedback-textarea"
                    placeholder="Feedback zur App/Website, Bestellung, Pilzsorte, etc..."
                    value={feedbackText}
                    onChange={(e) => setFeedbackText(e.target.value)}
                ></textarea>

                <button
                    className="send-button"
                    onClick={handleSendFeedback}>
                    Senden

                </button>
            </div>
            <NavbarBottomPhone/>
        </div>
    );
}
