import React from "react";
import "./RegisterScreenDesktop.css";
import { ErrorPopup } from "../../components/Utils/ErrorPopup";
import { SuccessPopup } from "../../components/Utils/SuccessPopup";
import { Link } from "react-router-dom";
import {validateName} from "../../Validation/validateName";
import {validateEmail} from "../../Validation/validateEmail";
import {validatePhoneNumber} from "../../Validation/validatePhoneNumber";
import {validatePassword} from "../../Validation/validatePassword";
import {validateConfirmPassword} from "../../Validation/validateConfirmPassword";
import {Footer} from "../../components/Footer/Footer";
import {NavigationbarTop} from "../../components/NavigationbarTop";

export default function RegisterScreenDesktop(props) {
    const {
        firstName, setFirstName, firstNameError, setFirstNameError,
        lastName, setLastName, lastNameError, setLastNameError,
        email, setEmail, emailError, setEmailError,
        phoneNumber, setPhone, phoneError, setPhoneError,
        address, setAddress, addressPlaceholder, setAddressPlaceholder,
        password, setPassword, passwordError, setPasswordError,
        confirmPassword, setConfirmPassword, confirmPasswordError, setConfirmPasswordError,
        touchedFields,
        handleBackClick,
        handleRegister,
        handleInputChange,
        errorMessage, clearError,
        showSuccessPopup, successMessage, handleCloseSuccessPopup
    } = props;

    return (
        <>
            <NavigationbarTop/>
        <div className="register-desktop-layout">
            <aside className="register-desktop-aside">
                <div className="aside-header">
                    <h1>Registrierung</h1>
                    <p>Erstelle deinen Account!</p>
                </div>
                <button className="register-desktop_aside-back-button" onClick={handleBackClick}>
                    <img src="/img/ep_back_white.png" alt="Zurück" className="register-desktop_back-link-icon"/>
                    <span className="register-desktop_back-link-text">Zurück</span>
                </button>
            </aside>

            <div className="register-desktop-main">
                <h2 className="main-title">Neues Konto erstellen</h2>

                {/* firstname */}
                <div
                    className={`desktop-input-container ${firstNameError ? "invalid" : touchedFields.firstName && "valid"}`}>
                    <input
                        type="text"
                        className="desktop-input-field"
                        placeholder=" "
                        value={firstName}
                        onChange={(e) =>
                            handleInputChange(e.target.value, setFirstName, setFirstNameError, validateName, "firstName")
                        }
                    />
                    <label className="desktop-input-label">Vorname</label>
                    <span className={`desktop-validation-icon ${firstNameError ? "" : firstName ? "valid" : ""}`}>
                          {firstNameError ? "❌" : firstName ? "✅" : ""}
                    </span>
                    {firstNameError && <p className="desktop-validation-error">{firstNameError}</p>}
                </div>

                {/* lastname */}
                <div
                    className={`desktop-input-container ${lastNameError ? "invalid" : touchedFields.lastName && "valid"}`}>
                    <input
                        type="text"
                        className="desktop-input-field"
                        placeholder=" "
                        value={lastName}
                        onChange={(e) =>
                            handleInputChange(e.target.value, setLastName, setLastNameError, validateName, "lastName")
                        }
                    />
                    <label className="desktop-input-label">Nachname</label>
                    <span className={`desktop-validation-icon ${lastNameError ? "" : lastName ? "valid" : ""}`}>
                          {lastNameError ? "❌" : lastName ? "✅" : ""}
                    </span>
                    {lastNameError && <p className="desktop-validation-error">{lastNameError}</p>}
                </div>

                {/* email */}
                <div className={`desktop-input-container ${emailError ? "invalid" : touchedFields.email && "valid"}`}>
                    <input
                        type="email"
                        className="desktop-input-field"
                        placeholder=" "
                        value={email}
                        onChange={(e) =>
                            handleInputChange(e.target.value, setEmail, setEmailError, validateEmail, "email")
                        }
                    />
                    <label className="desktop-input-label">E-Mail</label>
                    <span className={`desktop-validation-icon ${emailError ? "" : email ? "valid" : ""}`}>
                          {emailError ? "❌" : email ? "✅" : ""}
                    </span>
                    {emailError && <p className="desktop-validation-error">{emailError}</p>}
                </div>

                {/* phone number */}
                <div
                    className={`desktop-input-container ${phoneError ? "invalid" : touchedFields.phoneNumber && "valid"}`}>
                <input
                        type="text"
                        className="desktop-input-field"
                        placeholder=" "
                        value={phoneNumber}
                        onChange={(e) =>
                            handleInputChange(e.target.value, setPhone, setPhoneError, validatePhoneNumber, "phoneNumber")
                        }
                    />
                    <label className="desktop-input-label">Telefonnummer</label>
                    {phoneError && <p className="desktop-validation-error">{phoneError}</p>}
                </div>

                {/* address */}
                <div className="desktop-input-container">
                    <input
                        type="text"
                        className="desktop-input-field"
                        placeholder={addressPlaceholder || " "}
                        onFocus={() => setAddressPlaceholder("Straße / Hausnummer / PLZ")}
                        onBlur={() => setAddressPlaceholder("")}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    <label className="desktop-input-label">Adresse (optional)</label>
                </div>

                {/* password */}
                <div
                    className={`desktop-input-container ${passwordError ? "invalid" : touchedFields.password && "valid"}`}>
                    <input
                        type="password"
                        className="desktop-input-field"
                        placeholder=" "
                        value={password}
                        onChange={(e) =>
                            handleInputChange(e.target.value, setPassword, setPasswordError, validatePassword, "password")
                        }
                    />
                    <label className="desktop-input-label">Passwort</label>
                    <span
                        className={`register-screen_validation-input-icon ${passwordError ? "" : password ? "valid" : ""}`}>
                        {passwordError ? "❌" : password ? "✅" : ""}
                    </span>
                    {passwordError && <p className="desktop-validation-error">{passwordError}</p>}
                </div>

                {/* password confirm */}
                <div
                    className={`desktop-input-container ${confirmPasswordError ? "invalid" : touchedFields.confirmPassword && "valid"}`}>
                    <input
                        type="password"
                        className="desktop-input-field"
                        placeholder=" "
                        value={confirmPassword}
                        onChange={(e) =>
                            handleInputChange(e.target.value, setConfirmPassword, setConfirmPasswordError, (val) => validateConfirmPassword(password, val), "confirmPassword")
                        }
                    />
                    <label className="desktop-input-label">Passwort wiederholen</label>
                    <span
                        className={`register-screen_validation-input-icon ${confirmPasswordError ? "" : confirmPassword ? "valid" : ""}`}>
                        {confirmPasswordError ? "❌" : confirmPassword ? "✅" : ""}
                     </span>
                    {confirmPasswordError && <p className="desktop-validation-error">{confirmPasswordError}</p>}
                </div>

                <button className="desktop-register-button" onClick={handleRegister}>
                    Konto erstellen
                </button>

                <Link to="/login-screen" className="desktop-login-link">
                    Bereits registriert? Jetzt anmelden
                </Link>

                {errorMessage && (
                    <ErrorPopup message={errorMessage} onClose={clearError}/>
                )}



            </div>
        </div>
            <Footer/>
        </>
    );
}
