import React from "react";
import PropTypes from "prop-types";
import "./DescriptionBar.css";

export const DescriptionBar = ({ description, remainingWeight }) => {
    return (
        <div className="description-bar">
            <div className="description-item">
                <img
                    src="/img/mushroom-icon-small-72.png"
                    alt="Mushroom Icon"
                    className="mushroom-icon"
                />
                <p className="mushroom-name">{description}</p>
            </div>
            <div className="weight-info">
                <p className="weight-label">Verfügbares Gewicht:</p>
                <p className="weight-value">{remainingWeight} g</p>
            </div>
        </div>
    );
};

DescriptionBar.propTypes = {
    description: PropTypes.string.isRequired,
    remainingWeight: PropTypes.number.isRequired,
};
