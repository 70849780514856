import React, { createContext, useContext, useEffect, useReducer } from "react";
import { useAuth } from "./AuthContext";
import {
    getAllNotifications,
    addNotificationToDB,
    removeNotificationFromDB
} from "../Utils/indexedDB";
/*
import {
    fetchNotificationHistoryFromBackend,
    deleteNotificationFromBackend,
    addNotificationToBackend
} from "../Utils/DatabaseFetch";
*/
// helper function check if messages are older than 7 days
const isOlderThan7Days = (timestamp) => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    return new Date(timestamp) < sevenDaysAgo;
};

// initial state
const initialState = [];

// action
const ADD_NOTIFICATION = "ADD_NOTIFICATION";
const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";

// reducer
function notificationReducer(state, action) {
    switch (action.type) {
        case ADD_NOTIFICATION:
            if (state.some(notification => notification.id === action.payload.id)) {
                console.log(`Notification with id ${action.payload.id} already exists. Skipping add.`);
                return state;
            }
            return [...state, action.payload];
        case REMOVE_NOTIFICATION:
            return state.filter((notification) => notification.id !== action.payload);
        case SET_NOTIFICATIONS:
            return action.payload; // overwrite
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

// context
const NotificationContext = createContext(undefined);

// provider
export const NotificationProvider = ({ children }) => {
    const { user } = useAuth();
    const [state, dispatch] = useReducer(notificationReducer, initialState);

    // load notifications when user changes
    useEffect(() => {
        const loadNotifications = async () => {
            if (user) {
                try {
                    const storedNotifications = await getAllNotifications(user.customerId);
                    console.log("Stored Notifications:", storedNotifications);
                    const filteredNotifications = storedNotifications.filter(
                        (notification) => notification.customerId === user.customerId && !isOlderThan7Days(notification.timestamp)
                    );
                    console.log("Filtered Notifications:", filteredNotifications);
                    dispatch({ type: SET_NOTIFICATIONS, payload: filteredNotifications });
                } catch (error) {
                    console.error("Fehler beim Laden der Benachrichtigungen:", error);

                }
            } else {
                dispatch({ type: SET_NOTIFICATIONS, payload: [] }); // empty notifications if no user is logged in
            }
        };

        // maybe delete, it's a try
        const timeoutId = setTimeout(loadNotifications, 1000); // 1 sec

        return () => clearTimeout(timeoutId);
    }, [user]);

    // synchronize state with indexeDB and delete old messages
    useEffect(() => {
        if (user) {
            const syncNotifications = async () => {
                try {
                    const filteredNotifications = state.filter(
                        (notification) => notification.customerId === user.customerId && !isOlderThan7Days(notification.timestamp)
                    );
                    console.log("Sync Notifications to DB:", filteredNotifications);
                    // add to indexedDB
                    await Promise.all(filteredNotifications.map(notification => addNotificationToDB(notification)));
                } catch (error) {
                    console.error("Fehler beim Synchronisieren der Benachrichtigungen:", error);
                }
            };
            syncNotifications();
        }
    }, [state, user]);

    const addNotification = (notification) => {
        if (!user?.customerId) {
            console.warn("No user is logged in. Notification cannot be added.");
            return;
        }

        const notificationWithUserId = {
            ...notification,
            customerId: user.customerId,
        };
        dispatch({ type: ADD_NOTIFICATION, payload: notificationWithUserId });
        console.log("Adding notification:", notificationWithUserId);

        // add to indexedDB
        addNotificationToDB(notificationWithUserId).then(() => {
            console.log("Notification added to IndexedDB.");
        }).catch(error => console.error("Fehler beim Hinzufügen der Benachrichtigung zur DB:", error));
    };

    const removeNotification = (id) => {
        console.log(`Removing notification with id: ${id}`);
        dispatch({ type: REMOVE_NOTIFICATION, payload: id });
        removeNotificationFromDB(id).then(() => {
            console.log(`Notification with id ${id} removed from IndexedDB.`);
        }).catch(error => console.error("Fehler beim Entfernen der Benachrichtigung:", error));
    };

    return (
        <NotificationContext.Provider value={{ notifications: state, addNotification, removeNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};

// hook
export const useNotifications = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error("useNotifications must be used within a NotificationProvider");
    }
    return context;
};
