export const handleFetchError = (error) => {
   /* console.error("Fehler beim Abrufen der Daten:", error);
    let message = "Es gab ein Problem beim Laden der Daten.";

    if (error.name === "NetworkError") {
        message = "Keine Verbindung gefunden. Bitte überprüfe deine Internetverbindung oder versuche es später erneut.";
    } else if (error.name === "HttpError") {
        if (error.status === 404) {
            message = error.message || "Die angeforderte Ressource wurde nicht gefunden (404).";
        } else if (error.status === 500) {
            message = error.message || "Serverfehler (500). Bitte versuche es später erneut.";
        } else {
            message = `HTTP-Fehler ${error.status}: ${error.message}`;
        }
    } else {
        message = error.message || message;
    }

    return message;

    */

    console.error("Fehler beim Abrufen der Daten:", error);

    // TypeError auf NetworkError ändern, wenn es ein Netzwerkproblem ist
    if (error.name === 'TypeError') {
        error.name = 'NetworkError';
        error.message = 'Netzwerkfehler: Bitte überprüfe deine Internetverbindung. Die angezeigten Daten könnten veraltet sein';
    }

       switch (error.name) {
        case 'NetworkError':
            return { name: error.name, message: error.message };
        case 'SyntaxError':
            return { name: error.name, message: 'Fehler beim Verarbeiten der Serverantwort.' };
        case 'HttpError':
            return { name: error.name, message: error.message };
        case 'Error':
            return { name: error.name, message: error.message };
        default:
            return { name: 'UnknownError', message: 'Ein unbekannter Fehler ist aufgetreten.' };
    }

};
