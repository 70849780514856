import React from "react";
import { NavigationbarTop } from "../../components/NavigationbarTop";
import { OutroBottom } from "../../components/OutroBottom";
import "./style.css";

export const DesktopImpressum = () => {
  return (
    <div className="desktop-impressum">
      <NavigationbarTop
        className="navigationbar-top-15"
        property1="logout"
        shoppingCartIconShoppingCartIcon="/img/shopping-cart-icon-53.png"
        to="/desktop-login"
        to1="/desktop-register"
      />
      <div className="frame-wrapper">
        <div className="impressum-wrapper">
          <p className="impressum">
            Impressum
            <br />
            Informationen über den Diensteanbieter.
            <br />
            MusterFirma
            <br />
            Max Mustermann
            <br />
            Musterstrasse 1, Stiege 1 Tür 1, <br />
            1234 Musterhausen, <br />
            Österreich
            <br />
            Tel.: 01234/56789
            <br />
            Fax: 01234/56789-0
            <br />
            E-Mail: office@musterfirma.com
            <br />
            Unternehmensgegenstand: IT Dienstleistungen
            <br />
            UID-Nummer: ATU12345678
            <br />
            GLN (Global Location Number): 1234567891234
            <br />
            GISA (Gewerbeinformationssystem Austria): 12345678
            <br />
            Mitglied bei: WKO, Landesinnung, etc.
            <br />
            Berufsrecht: Muster Berufsrechtliche Regelung
            <br /> z.B: www.ris.bka.gv.at
            <br />
            Aufsichtsbehörde:
            <br />
            Bezirkshauptmannschaft Musterhausen
            <br />
            Musterstraße 47, 12312 Musterstadt, <br /> Musterland
            <br />
            Webseite: https://www.aufsichtsbeoerde-musterhausen.at/
            <br />
            Berufsbezeichnung: Webdesigner, Grafiker
            <br />
            Verleihungsstaat: Österreich
            <br />
            Datenschutz Verantwortlicher
            <br />
            Max Muster
            <br /> Musterstraße 47, 12312 Musterstadt,
            <br /> Musterland
            <br />
            E-Mail: office@musterfirma.com
            <br />
            Tel.: 01234/56789
            <br />
            Impressum: https://www.testfirma.at/impressum/
            <br />
            Vertretungsberechtigter: Markus Mustermann
            <br />
            EU-Streitschlichtung
            <br />
            Gemäß Verordnung über&nbsp;&nbsp;Online-Streitbeilegung in
            Verbraucherangelegenheiten (ODR-Verordnung)&nbsp;&nbsp;möchten wir
            Sie über die Online-Streitbeilegungsplattform (OS-Plattform)
            informieren.
            <br /> Verbraucher haben die Möglichkeit, Beschwerden an die Online
            Streitbeilegungsplattform der Europäischen Kommission unter
            https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE
            zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb
            in unserem Impressum.
            <br />
            Wir möchten Sie jedoch darauf hinweisen,&nbsp;&nbsp;dass wir nicht
            bereit oder verpflichtet sind,
            an&nbsp;&nbsp;Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle&nbsp;&nbsp;teilzunehmen.
            <br />
            Bildernachweis
            <br />
            Die Bilder, Fotos und Grafiken auf dieser Webseite sind
            urheberrechtlich geschützt.
            <br />
            Die Bilderrechte liegen bei:
            <br />
            Fotograf Max Mustermann
            <br /> Fotografin Pia Musterfrau
            <br />
            Alle Texte sind urheberrechtlich geschützt.
            <br />
            Quelle: Erstellt mit dem Impressum Generator von AdSimple
            <br />
            Datenschutzerklärung
            <br />
            Einleitung und Überblick
            <br />
            Wir haben diese Datenschutzerklärung (Fassung 08.11.2024-122904580)
            verfasst, um Ihnen gemäß der Vorgaben der
            Datenschutz-Grundverordnung (EU) 2016/679 und anwendbaren nationalen
            Gesetzen zu erklären, welche&nbsp;&nbsp;personenbezogenen Daten
            (kurz Daten) wir als Verantwortliche – und die&nbsp;&nbsp;von uns
            beauftragten Auftragsverarbeiter (z. B. Provider) – verarbeiten,
            zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten
            Sie&nbsp;&nbsp;haben. Die verwendeten Begriffe sind
            geschlechtsneutral zu verstehen.
            <br />
            Kurz gesagt: Wir informieren Sie umfassend über Daten, die wir über
            Sie verarbeiten.
            <br />
            Datenschutzerklärungen klingen für gewöhnlich sehr technisch
            und&nbsp;&nbsp;verwenden juristische Fachbegriffe. Diese
            Datenschutzerklärung soll&nbsp;&nbsp;Ihnen hingegen die wichtigsten
            Dinge so einfach und transparent wie&nbsp;&nbsp;möglich beschreiben.
            Soweit es der Transparenz förderlich ist,
            werden&nbsp;&nbsp;technische Begriffe leserfreundlich erklärt, Links
            zu weiterführenden Informationen geboten und Grafiken zum Einsatz
            gebracht. Wir informieren damit in klarer und
            einfacher&nbsp;&nbsp;Sprache, dass wir im Rahmen unserer
            Geschäftstätigkeiten nur dann&nbsp;&nbsp;personenbezogene Daten
            verarbeiten, wenn eine entsprechende
            gesetzliche&nbsp;&nbsp;Grundlage gegeben ist. Das ist sicher nicht
            möglich, wenn man möglichst&nbsp;&nbsp;knappe, unklare und
            juristisch-technische Erklärungen abgibt, so wie sie im Internet oft
            Standard sind, wenn es um Datenschutz geht. Ich
            hoffe,&nbsp;&nbsp;Sie finden die folgenden Erläuterungen interessant
            und informativ und&nbsp;&nbsp;vielleicht ist die eine oder andere
            Information dabei, die Sie noch&nbsp;&nbsp;nicht kannten.
            <br /> Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an
            die unten&nbsp;&nbsp;bzw. im Impressum genannte verantwortliche
            Stelle zu wenden, den&nbsp;&nbsp;vorhandenen Links zu folgen und
            sich weitere Informationen auf&nbsp;&nbsp;Drittseiten anzusehen.
            Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.
            <br />
            Anwendungsbereich
            <br />
            Diese Datenschutzerklärung gilt für alle von uns im
            Unternehmen&nbsp;&nbsp;verarbeiteten personenbezogenen Daten und für
            alle personenbezogenen&nbsp;&nbsp;Daten, die von uns beauftragte
            Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen
            Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie
            zum Beispiel Name, E-Mail-Adresse und
            postalische&nbsp;&nbsp;Anschrift einer Person. Die Verarbeitung
            personenbezogener Daten sorgt&nbsp;&nbsp;dafür, dass wir unsere
            Dienstleistungen und Produkte anbieten und&nbsp;&nbsp;abrechnen
            können, sei es online oder offline. Der
            Anwendungsbereich&nbsp;&nbsp;dieser Datenschutzerklärung umfasst:
            <br />
            alle Onlineauftritte (Websites, Onlineshops), die wir betreiben
            <br />
            Social Media Auftritte und E-Mail-Kommunikation
            <br />
            mobile Apps für Smartphones und andere Geräte
            <br />
            Kurz gesagt: Die&nbsp;&nbsp;Datenschutzerklärung gilt für alle
            Bereiche, in denen personenbezogene&nbsp;&nbsp;Daten im Unternehmen
            über die genannten Kanäle strukturiert
            verarbeitet&nbsp;&nbsp;werden. Sollten wir außerhalb dieser Kanäle
            mit Ihnen in&nbsp;&nbsp;Rechtsbeziehungen eintreten, werden wir Sie
            gegebenenfalls gesondert&nbsp;&nbsp;informieren.
            <br />
            Rechtsgrundlagen
            <br />
            In der folgenden Datenschutzerklärung geben wir Ihnen
            transparente&nbsp;&nbsp;Informationen zu den rechtlichen Grundsätzen
            und Vorschriften, also den&nbsp;&nbsp;Rechtsgrundlagen der
            Datenschutz-Grundverordnung, die uns
            ermöglichen,&nbsp;&nbsp;personenbezogene Daten zu verarbeiten.
            <br /> Was das EU-Recht betrifft, beziehen wir uns auf die
            VERORDNUNG (EU)&nbsp;&nbsp;2016/679 DES EUROPÄISCHEN PARLAMENTS UND
            DES RATES vom 27. April 2016.&nbsp;&nbsp;Diese
            Datenschutz-Grundverordnung der EU können Sie
            selbstverständlich&nbsp;&nbsp;online auf EUR-Lex, dem Zugang zum
            EU-Recht, unter
            https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679
            nachlesen.
            <br />
            Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden
            Bedingungen zutrifft:
            <br />
            Einwilligung (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre
            Einwilligung&nbsp;&nbsp;gegeben, Daten zu einem bestimmten Zweck zu
            verarbeiten. Ein Beispiel&nbsp;&nbsp;wäre die Speicherung Ihrer
            eingegebenen Daten eines Kontaktformulars.
            <br />
            Vertrag (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag
            oder&nbsp;&nbsp;vorvertragliche Verpflichtungen mit Ihnen zu
            erfüllen, verarbeiten wir&nbsp;&nbsp;Ihre Daten. Wenn wir zum
            Beispiel einen Kaufvertrag mit Ihnen&nbsp;&nbsp;abschließen,
            benötigen wir vorab personenbezogene Informationen.
            <br />
            Rechtliche Verpflichtung (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir
            einer rechtlichen&nbsp;&nbsp;Verpflichtung unterliegen, verarbeiten
            wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet
            Rechnungen für die Buchhaltung aufzuheben.&nbsp;&nbsp;Diese
            enthalten in der Regel personenbezogene Daten.
            <br />
            Berechtigte Interessen (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle
            berechtigter Interessen,&nbsp;&nbsp;die Ihre Grundrechte nicht
            einschränken, behalten wir uns die&nbsp;&nbsp;Verarbeitung
            personenbezogener Daten vor. Wir müssen zum
            Beispiel&nbsp;&nbsp;gewisse Daten verarbeiten, um unsere Website
            sicher und wirtschaftlich&nbsp;&nbsp;effizient betreiben zu können.
            Diese Verarbeitung ist somit ein&nbsp;&nbsp;berechtigtes Interesse.
            <br />
            Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im
            öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie dem
            Schutz&nbsp;&nbsp;lebenswichtiger Interessen treten bei uns in der
            Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig
            sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.
            <br />
            Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:
            <br />
            In Österreich ist dies das Bundesgesetz zum Schutz natürlicher
            Personen bei der Verarbeitung personenbezogener Daten
            (Datenschutzgesetz), kurz DSG.
            <br />
            In Deutschland gilt das Bundesdatenschutzgesetz, kurz BDSG.
            <br />
            Sofern weitere regionale oder nationale Gesetze zur Anwendung
            kommen, informieren wir Sie in den folgenden Abschnitten darüber.
            <br />
            Kontaktdaten des Verantwortlichen
            <br />
            Sollten Sie Fragen zum Datenschutz oder zur
            Verarbeitung&nbsp;&nbsp;personenbezogener Daten haben, finden Sie
            nachfolgend die Kontaktdaten&nbsp;&nbsp;der verantwortlichen Person
            bzw. Stelle:
            <br />
            Max Muster
            <br /> Musterstraße 47, 12312 Musterstadt,
            <br /> Musterland
            <br />
            Vertretungsberechtigt: Markus Mustermann
            <br /> E-Mail: office@musterfirma.com
            <br /> Telefon: 01234/56789
            <br /> Impressum: https://www.testfirma.at/impressum/
            <br />
            Speicherdauer
            <br />
            Dass wir personenbezogene Daten nur so lange speichern, wie es
            für&nbsp;&nbsp;die Bereitstellung unserer Dienstleistungen und
            Produkte unbedingt&nbsp;&nbsp;notwendig ist, gilt als generelles
            Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten
            löschen, sobald der Grund für die&nbsp;&nbsp;Datenverarbeitung nicht
            mehr vorhanden ist. In einigen Fällen sind wir&nbsp;&nbsp;gesetzlich
            dazu verpflichtet, bestimmte Daten auch nach Wegfall
            des&nbsp;&nbsp;ursprüngliches Zwecks zu speichern, zum Beispiel zu
            Zwecken der&nbsp;&nbsp;Buchführung.
            <br />
            Sollten Sie die Löschung Ihrer Daten wünschen oder die
            Einwilligung&nbsp;&nbsp;zur Datenverarbeitung widerrufen, werden die
            Daten so rasch wie möglich&nbsp;&nbsp;und soweit keine Pflicht zur
            Speicherung besteht, gelöscht.
            <br />
            Über die konkrete Dauer der jeweiligen Datenverarbeitung
            informieren&nbsp;&nbsp;wir Sie weiter unten, sofern wir weitere
            Informationen dazu haben.
            <br />
            Rechte laut Datenschutz-Grundverordnung
            <br />
            Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die
            folgenden&nbsp;&nbsp;Rechte, die Ihnen zustehen, damit es zu einer
            fairen und transparenten&nbsp;&nbsp;Verarbeitung von Daten kommt:
            <br />
            Sie haben laut Artikel 15 DSGVO ein&nbsp;&nbsp;Auskunftsrecht
            darüber, ob wir Daten von Ihnen verarbeiten. Sollte
            das&nbsp;&nbsp;zutreffen, haben Sie Recht darauf eine Kopie der
            Daten zu erhalten und&nbsp;&nbsp;die folgenden Informationen zu
            erfahren:
            <br />
            zu welchem Zweck wir die Verarbeitung durchführen;
            <br />
            die Kategorien, also die Arten von Daten, die verarbeitet werden;
            <br />
            wer diese Daten erhält und wenn die Daten an Drittländer übermittelt
            werden, wie die Sicherheit garantiert werden kann;
            <br />
            wie lange die Daten gespeichert werden;
            <br />
            das Bestehen des Rechts auf Berichtigung, Löschung oder
            Einschränkung der Verarbeitung und dem
            Widerspruchsrecht&nbsp;&nbsp;gegen die Verarbeitung;
            <br />
            dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu
            diesen Behörden finden Sie weiter unten);
            <br />
            die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;
            <br />
            ob Profiling durchgeführt wird, ob also&nbsp;&nbsp;Daten automatisch
            ausgewertet werden, um zu einem persönlichen Profil&nbsp;&nbsp;von
            Ihnen zu gelangen.
            <br />
            Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der
            Daten, was bedeutet, dass wir Daten richtig&nbsp;&nbsp;stellen
            müssen, falls Sie Fehler finden.
            <br />
            Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf
            Vergessenwerden“), was konkret bedeutet, dass&nbsp;&nbsp;Sie die
            Löschung Ihrer Daten verlangen dürfen.
            <br />
            Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der
            Verarbeitung, was bedeutet, dass wir die Daten&nbsp;&nbsp;nur mehr
            speichern dürfen aber nicht weiter verwenden.
            <br />
            Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit,
            was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem
            gängigen Format zur Verfügung stellen.
            <br />
            Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach
            Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
            <br />
            Wenn die Verarbeitung Ihrer Daten auf&nbsp;&nbsp;Artikel 6 Abs. 1
            lit. e (öffentliches Interesse, Ausübung
            öffentlicher&nbsp;&nbsp;Gewalt) oder Artikel 6 Abs. 1 lit. f
            (berechtigtes Interesse) basiert,&nbsp;&nbsp;können Sie gegen die
            Verarbeitung Widerspruch einlegen. Wir prüfen&nbsp;&nbsp;danach so
            rasch wie möglich, ob wir diesem Widerspruch
            rechtlich&nbsp;&nbsp;nachkommen können.
            <br />
            Werden Daten verwendet, um Direktwerbung&nbsp;&nbsp;zu betreiben,
            können Sie jederzeit gegen diese Art der Datenverarbeitung
            widersprechen. Wir dürfen Ihre Daten danach nicht mehr
            für&nbsp;&nbsp;Direktmarketing verwenden.
            <br />
            Werden Daten verwendet, um Profiling zu&nbsp;&nbsp;betreiben, können
            Sie jederzeit gegen diese Art der
            Datenverarbeitung&nbsp;&nbsp;widersprechen. Wir dürfen Ihre Daten
            danach nicht mehr für Profiling&nbsp;&nbsp;verwenden.
            <br />
            Sie haben laut Artikel 22 DSGVO unter&nbsp;&nbsp;Umständen das
            Recht, nicht einer ausschließlich auf
            einer&nbsp;&nbsp;automatisierten Verarbeitung (zum Beispiel
            Profiling) beruhenden&nbsp;&nbsp;Entscheidung unterworfen zu werden.
            <br />
            Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt,
            Sie können sich jederzeit bei der&nbsp;&nbsp;Datenschutzbehörde
            beschweren, wenn Sie der Meinung sind, dass
            die&nbsp;&nbsp;Datenverarbeitung von personenbezogenen Daten gegen
            die DSGVO verstößt.
            <br />
            Kurz gesagt: Sie haben Rechte – zögern Sie nicht, die oben gelistete
            verantwortliche Stelle bei uns zu kontaktieren!
            <br />
            Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen
            das&nbsp;&nbsp;Datenschutzrecht verstößt oder Ihre
            datenschutzrechtlichen Ansprüche in&nbsp;&nbsp;sonst einer Weise
            verletzt worden sind, können Sie sich bei
            der&nbsp;&nbsp;Aufsichtsbehörde beschweren. Diese ist für Österreich
            die&nbsp;&nbsp;Datenschutzbehörde, deren Website Sie unter
            https://www.dsb.gv.at/ finden. In Deutschland gibt es für jedes
            Bundesland einen&nbsp;&nbsp;Datenschutzbeauftragten. Für nähere
            Informationen können Sie sich an die Bundesbeauftragte für den
            Datenschutz und die Informationsfreiheit (BfDI) wenden. Für unser
            Unternehmen ist die folgende lokale Datenschutzbehörde zuständig:
            <br />
            Erklärung verwendeter Begriffe
            <br />
            Wir sind stets bemüht unsere Datenschutzerklärung so klar
            und&nbsp;&nbsp;verständlich wie möglich zu verfassen. Besonders bei
            technischen und&nbsp;&nbsp;rechtlichen Themen ist das allerdings
            nicht immer ganz einfach. Es macht oft Sinn juristische Begriffe
            (wie z. B. personenbezogene Daten) oder&nbsp;&nbsp;bestimmte
            technische Ausdrücke (wie z. B. Cookies, IP-Adresse)
            zu&nbsp;&nbsp;verwenden. Wir möchte diese aber nicht ohne Erklärung
            verwenden.&nbsp;&nbsp;Nachfolgend finden Sie nun eine alphabetische
            Liste von wichtigen&nbsp;&nbsp;verwendeten Begriffen, auf die wir in
            der bisherigen&nbsp;&nbsp;Datenschutzerklärung vielleicht noch nicht
            ausreichend eingegangen sind. Falls diese Begriffe der DSGVO
            entnommen wurden und es sich um&nbsp;&nbsp;Begriffsbestimmungen
            handelt, werden wir hier auch die DSGVO-Texte&nbsp;&nbsp;anführen
            und gegebenenfalls noch eigene Erläuterungen hinzufügen.
            <br />
            Auftragsverarbeiter
            <br />
            Begriffsbestimmung nach Artikel 4 der DSGVO
            <br />
            Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            <br />
            „Auftragsverarbeiter“ eine natürliche oder juristische Person,
            Behörde, Einrichtung oder&nbsp;&nbsp;andere Stelle, die
            personenbezogene Daten im Auftrag des&nbsp;&nbsp;Verantwortlichen
            verarbeitet;
            <br />
            Erläuterung: Wir sind als Unternehmen und Websiteinhaber für alle
            Daten, die wir von Ihnen&nbsp;&nbsp;verarbeiten verantwortlich.
            Neben den Verantwortlichen kann es auch&nbsp;&nbsp;sogenannte
            Auftragsverarbeiter geben. Dazu zählt jedes Unternehmen
            bzw.&nbsp;&nbsp;jede Person, die in unserem Auftrag personenbezogene
            Daten verarbeitet.&nbsp;&nbsp;Auftragsverarbeiter können folglich,
            neben Dienstleistern wie&nbsp;&nbsp;Steuerberater, etwa auch
            Hosting- oder Cloudanbieter, Bezahlungs-
            oder&nbsp;&nbsp;Newsletter-Anbieter oder große Unternehmen wie
            beispielsweise Google&nbsp;&nbsp;oder Microsoft sein.
            <br />
            Einwilligung
            <br />
            Begriffsbestimmung nach Artikel 4 der DSGVO
            <br />
            Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            <br />
            „Einwilligung“ der&nbsp;&nbsp;betroffenen Person jede freiwillig für
            den bestimmten Fall, in&nbsp;&nbsp;informierter Weise und
            unmissverständlich abgegebene Willensbekundung in Form einer
            Erklärung oder einer sonstigen eindeutigen
            bestätigenden&nbsp;&nbsp;Handlung, mit der die betroffene Person zu
            verstehen gibt, dass sie mit&nbsp;&nbsp;der Verarbeitung der sie
            betreffenden personenbezogenen Daten&nbsp;&nbsp;einverstanden ist;
            <br />
            Erläuterung: In der Regel erfolgt bei Websites eine solche
            Einwilligung über ein&nbsp;&nbsp;Cookie-Consent-Tool. Sie kennen das
            bestimmt. Immer wenn Sie erstmals&nbsp;&nbsp;eine Website besuchen,
            werden Sie meist über einen Banner gefragt, ob&nbsp;&nbsp;Sie der
            Datenverarbeitung zustimmen bzw. einwilligen. Meist können
            Sie&nbsp;&nbsp;auch individuelle Einstellungen treffen und so selbst
            entscheiden,&nbsp;&nbsp;welche Datenverarbeitung Sie erlauben und
            welche nicht. Wenn Sie nicht&nbsp;&nbsp;einwilligen, dürfen auch
            keine personenbezogene Daten von Ihnen&nbsp;&nbsp;verarbeitet
            werden. Grundsätzlich kann eine Einwilligung natürlich
            auch&nbsp;&nbsp;schriftlich, also nicht über ein Tool, erfolgen.
            <br />
            Personenbezogene Daten
            <br />
            Begriffsbestimmung nach Artikel 4 der DSGVO
            <br />
            Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            <br />
            „personenbezogene Daten“ alle Informationen, die sich auf eine
            identifizierte oder&nbsp;&nbsp;identifizierbare natürliche Person
            (im Folgenden „betroffene Person“)&nbsp;&nbsp;beziehen; als
            identifizierbar wird eine natürliche Person angesehen, die direkt
            oder indirekt, insbesondere mittels Zuordnung zu einer
            Kennung&nbsp;&nbsp;wie einem Namen, zu einer Kennnummer, zu
            Standortdaten, zu einer&nbsp;&nbsp;Online-Kennung oder zu einem oder
            mehreren besonderen Merkmalen, die&nbsp;&nbsp;Ausdruck der
            physischen, physiologischen, genetischen,
            psychischen,&nbsp;&nbsp;wirtschaftlichen, kulturellen oder sozialen
            Identität dieser natürlichen Person sind, identifiziert werden kann;
            <br />
            Erläuterung:&nbsp;&nbsp;Personenbezogene Daten sind also all jene
            Daten, die Sie als Person&nbsp;&nbsp;identifizieren können. Das sind
            in der Regel Daten wie etwa:
            <br />
            Name
            <br />
            Adresse
            <br />
            E-Mail-Adresse
            <br />
            Post-Anschrift
            <br />
            Telefonnummer
            <br />
            Geburtsdatum
            <br />
            Kennnummern wie Sozialversicherungsnummer,
            Steueridentifikationsnummer, Personalausweisnummer oder
            Matrikelnummer
            <br />
            Bankdaten wie Kontonummer, Kreditinformationen, Kontostände uvm.
            <br />
            Laut Europäischem Gerichtshof (EuGH) zählt auch Ihre IP-Adresse zu
            den personenbezogenen Daten. IT-Experten können anhand Ihrer
            IP-Adresse zumindest den ungefähren&nbsp;&nbsp;Standort Ihres Geräts
            und in weiterer Folge Sie als
            Anschlussinhabers&nbsp;&nbsp;feststellen. Daher benötigt auch das
            Speichern einer IP-Adresse eine&nbsp;&nbsp;Rechtsgrundlage im Sinne
            der DSGVO. Es gibt auch noch sogenannte „besondere Kategorien“ der
            personenbezogenen Daten, die auch besonders schützenswert sind. Dazu
            zählen:
            <br />
            rassische und ethnische Herkunft
            <br />
            politische Meinungen
            <br />
            religiöse bzw. weltanschauliche Überzeugungen
            <br />
            die Gewerkschaftszugehörigkeit
            <br />
            genetische Daten wie beispielsweise Daten, die aus Blut- oder
            Speichelproben entnommen werden
            <br />
            biometrische Daten (das sind&nbsp;&nbsp;Informationen zu
            psychischen, körperlichen oder
            verhaltenstypischen&nbsp;&nbsp;Merkmalen, die eine Person
            identifizieren können).
            <br /> Gesundheitsdaten
            <br />
            Daten zur sexuellen Orientierung oder zum Sexualleben
            <br />
            Profiling
            <br />
            Begriffsbestimmung nach Artikel 4 der DSGVO
            <br />
            Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            <br />
            „Profiling“ jede Art&nbsp;&nbsp;der automatisierten Verarbeitung
            personenbezogener Daten, die darin&nbsp;&nbsp;besteht, dass diese
            personenbezogenen Daten verwendet werden, um&nbsp;&nbsp;bestimmte
            persönliche Aspekte, die sich auf eine natürliche
            Person&nbsp;&nbsp;beziehen, zu bewerten, insbesondere um Aspekte
            bezüglich&nbsp;&nbsp;Arbeitsleistung, wirtschaftliche Lage,
            Gesundheit, persönliche&nbsp;&nbsp;Vorlieben, Interessen,
            Zuverlässigkeit, Verhalten, Aufenthaltsort
            oder&nbsp;&nbsp;Ortswechsel dieser natürlichen Person zu analysieren
            oder vorherzusagen;
            <br />
            Erläuterung: Beim&nbsp;&nbsp;Profiling werden verschiedene
            Informationen über eine Person&nbsp;&nbsp;zusammengetragen, um
            daraus mehr über diese Person zu erfahren. Im&nbsp;&nbsp;Webbereich
            wird Profiling häufig für Werbezwecke oder auch
            für&nbsp;&nbsp;Bonitätsprüfungen angewandt. Web- bzw.
            Werbeanalyseprogramme sammeln zum Beispiel Daten über Ihre Verhalten
            und Ihre Interessen auf einer&nbsp;&nbsp;Website. Daraus ergibt sich
            ein spezielles Userprofil, mit dessen Hilfe&nbsp;&nbsp;Werbung
            gezielt an eine Zielgruppe ausgespielt werden kann.
            <br /> <br />
            Verantwortlicher
            <br />
            Begriffsbestimmung nach Artikel 4 der DSGVO
            <br />
            Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            <br />
            „Verantwortlicher“&nbsp;&nbsp;die natürliche oder juristische
            Person, Behörde, Einrichtung oder andere Stelle, die allein oder
            gemeinsam mit anderen über die Zwecke und&nbsp;&nbsp;Mittel der
            Verarbeitung von personenbezogenen Daten entscheidet;
            sind&nbsp;&nbsp;die Zwecke und Mittel dieser Verarbeitung durch das
            Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so kann
            der Verantwortliche&nbsp;&nbsp;beziehungsweise können die bestimmten
            Kriterien seiner Benennung nach&nbsp;&nbsp;dem Unionsrecht oder dem
            Recht der Mitgliedstaaten vorgesehen werden;
            <br />
            Erläuterung: In unserem&nbsp;&nbsp;Fall sind wir für die
            Verarbeitung Ihrer personenbezogenen Daten&nbsp;&nbsp;verantwortlich
            und folglich der “Verantwortliche”. Wenn wir
            erhobene&nbsp;&nbsp;Daten zur Verarbeitung an andere Dienstleister
            weitergeben, sind diese&nbsp;&nbsp;“Auftragsverarbeiter”. Dafür muss
            ein “Auftragsverarbeitungsvertrag&nbsp;&nbsp;(AVV)” unterzeichnet
            werden.
            <br /> <br />
            Verarbeitung
            <br />
            Begriffsbestimmung nach Artikel 4 der DSGVO
            <br />
            Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            <br />
            „Verarbeitung“ jeden mit oder ohne Hilfe automatisierter Verfahren
            ausgeführten&nbsp;&nbsp;Vorgang oder jede solche Vorgangsreihe im
            Zusammenhang mit&nbsp;&nbsp;personenbezogenen Daten wie das Erheben,
            das Erfassen, die Organisation, das Ordnen, die Speicherung, die
            Anpassung oder Veränderung, das&nbsp;&nbsp;Auslesen, das Abfragen,
            die Verwendung, die Offenlegung durch&nbsp;&nbsp;Übermittlung,
            Verbreitung oder eine andere Form der Bereitstellung,
            den&nbsp;&nbsp;Abgleich oder die Verknüpfung, die Einschränkung, das
            Löschen oder die&nbsp;&nbsp;Vernichtung;
            <br />
            Anmerkung: Wenn wir in&nbsp;&nbsp;unserer Datenschutzerklärung von
            Verarbeitung sprechen, meinen wir damit jegliche Art von
            Datenverarbeitung. Dazu zählt, wie oben in der&nbsp;&nbsp;originalen
            DSGVO-Erklärung erwähnt, nicht nur das Erheben sondern
            auch&nbsp;&nbsp;das Speichern und Verarbeiten von Daten.
            <br />
            Schlusswort
            <br />
            Herzlichen Glückwunsch! Wenn Sie diese Zeilen lesen, haben Sie
            sich&nbsp;&nbsp;wirklich durch unsere gesamte Datenschutzerklärung
            „gekämpft“ oder&nbsp;&nbsp;zumindest bis hier hin gescrollt. Wie Sie
            am Umfang unserer&nbsp;&nbsp;Datenschutzerklärung sehen, nehmen wir
            den Schutz Ihrer persönlichen&nbsp;&nbsp;Daten, alles andere als auf
            die leichte Schulter.
            <br /> Uns ist es wichtig, Sie nach bestem Wissen und Gewissen über
            die&nbsp;&nbsp;Verarbeitung personenbezogener Daten zu informieren.
            Dabei wollen wir&nbsp;&nbsp;Ihnen aber nicht nur mitteilen, welche
            Daten verarbeitet werden, sondern auch die Beweggründe für die
            Verwendung diverser Softwareprogramme&nbsp;&nbsp;näherbringen. In
            der Regel klingen Datenschutzerklärung sehr technisch&nbsp;&nbsp;und
            juristisch. Da die meisten von Ihnen aber keine Webentwickler
            oder&nbsp;&nbsp;Juristen sind, wollten wir auch sprachlich einen
            anderen Weg gehen und&nbsp;&nbsp;den Sachverhalt in einfacher und
            klarer Sprache erklären. Immer ist dies natürlich aufgrund der
            Thematik nicht möglich. Daher werden die&nbsp;&nbsp;wichtigsten
            Begriffe am Ende der Datenschutzerklärung näher erläutert.
            <br /> Bei Fragen zum Thema Datenschutz auf unserer Website zögern
            Sie bitte&nbsp;&nbsp;nicht, uns oder die verantwortliche Stelle zu
            kontaktieren. Wir wünschen Ihnen noch eine schöne Zeit und hoffen,
            Sie auf unserer Website bald&nbsp;&nbsp;wieder begrüßen zu dürfen.
            <br />
            Alle Texte sind urheberrechtlich geschützt.Impressum
            <br />
            Informationen über den Diensteanbieter.
            <br />
            MusterFirma
            <br />
            Max Mustermann
            <br />
            Musterstrasse 1, Stiege 1 Tür 1, <br />
            1234 Musterhausen, <br />
            Österreich
            <br />
            Tel.: 01234/56789
            <br />
            Fax: 01234/56789-0
            <br />
            E-Mail: office@musterfirma.com
            <br />
            Unternehmensgegenstand: IT Dienstleistungen
            <br />
            UID-Nummer: ATU12345678
            <br />
            GLN (Global Location Number): 1234567891234
            <br />
            GISA (Gewerbeinformationssystem Austria): 12345678
            <br />
            Mitglied bei: WKO, Landesinnung, etc.
            <br />
            Berufsrecht: Muster Berufsrechtliche Regelung
            <br /> z.B: www.ris.bka.gv.at
            <br />
            Aufsichtsbehörde:
            <br />
            Bezirkshauptmannschaft Musterhausen
            <br />
            Musterstraße 47, 12312 Musterstadt, <br /> Musterland
            <br />
            Webseite: https://www.aufsichtsbeoerde-musterhausen.at/
            <br />
            Berufsbezeichnung: Webdesigner, Grafiker
            <br />
            Verleihungsstaat: Österreich
            <br />
            Datenschutz Verantwortlicher
            <br />
            Max Muster
            <br /> Musterstraße 47, 12312 Musterstadt,
            <br /> Musterland
            <br />
            E-Mail: office@musterfirma.com
            <br />
            Tel.: 01234/56789
            <br />
            Impressum: https://www.testfirma.at/impressum/
            <br />
            Vertretungsberechtigter: Markus Mustermann
            <br />
            EU-Streitschlichtung
            <br />
            Gemäß Verordnung über&nbsp;&nbsp;Online-Streitbeilegung in
            Verbraucherangelegenheiten (ODR-Verordnung)&nbsp;&nbsp;möchten wir
            Sie über die Online-Streitbeilegungsplattform (OS-Plattform)
            informieren.
            <br /> Verbraucher haben die Möglichkeit, Beschwerden an die Online
            Streitbeilegungsplattform der Europäischen Kommission unter
            https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE
            zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb
            in unserem Impressum.
            <br />
            Wir möchten Sie jedoch darauf hinweisen,&nbsp;&nbsp;dass wir nicht
            bereit oder verpflichtet sind,
            an&nbsp;&nbsp;Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle&nbsp;&nbsp;teilzunehmen.
            <br />
            Bildernachweis
            <br />
            Die Bilder, Fotos und Grafiken auf dieser Webseite sind
            urheberrechtlich geschützt.
            <br />
            Die Bilderrechte liegen bei:
            <br />
            Fotograf Max Mustermann
            <br /> Fotografin Pia Musterfrau
            <br />
            Alle Texte sind urheberrechtlich geschützt.
            <br />
            Quelle: Erstellt mit dem Impressum Generator von AdSimple
            <br />
            Datenschutzerklärung
            <br />
            Einleitung und Überblick
            <br />
            Wir haben diese Datenschutzerklärung (Fassung 08.11.2024-122904580)
            verfasst, um Ihnen gemäß der Vorgaben der
            Datenschutz-Grundverordnung (EU) 2016/679 und anwendbaren nationalen
            Gesetzen zu erklären, welche&nbsp;&nbsp;personenbezogenen Daten
            (kurz Daten) wir als Verantwortliche – und die&nbsp;&nbsp;von uns
            beauftragten Auftragsverarbeiter (z. B. Provider) – verarbeiten,
            zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten
            Sie&nbsp;&nbsp;haben. Die verwendeten Begriffe sind
            geschlechtsneutral zu verstehen.
            <br />
            Kurz gesagt: Wir informieren Sie umfassend über Daten, die wir über
            Sie verarbeiten.
            <br />
            Datenschutzerklärungen klingen für gewöhnlich sehr technisch
            und&nbsp;&nbsp;verwenden juristische Fachbegriffe. Diese
            Datenschutzerklärung soll&nbsp;&nbsp;Ihnen hingegen die wichtigsten
            Dinge so einfach und transparent wie&nbsp;&nbsp;möglich beschreiben.
            Soweit es der Transparenz förderlich ist,
            werden&nbsp;&nbsp;technische Begriffe leserfreundlich erklärt, Links
            zu weiterführenden Informationen geboten und Grafiken zum Einsatz
            gebracht. Wir informieren damit in klarer und
            einfacher&nbsp;&nbsp;Sprache, dass wir im Rahmen unserer
            Geschäftstätigkeiten nur dann&nbsp;&nbsp;personenbezogene Daten
            verarbeiten, wenn eine entsprechende
            gesetzliche&nbsp;&nbsp;Grundlage gegeben ist. Das ist sicher nicht
            möglich, wenn man möglichst&nbsp;&nbsp;knappe, unklare und
            juristisch-technische Erklärungen abgibt, so wie sie im Internet oft
            Standard sind, wenn es um Datenschutz geht. Ich
            hoffe,&nbsp;&nbsp;Sie finden die folgenden Erläuterungen interessant
            und informativ und&nbsp;&nbsp;vielleicht ist die eine oder andere
            Information dabei, die Sie noch&nbsp;&nbsp;nicht kannten.
            <br /> Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an
            die unten&nbsp;&nbsp;bzw. im Impressum genannte verantwortliche
            Stelle zu wenden, den&nbsp;&nbsp;vorhandenen Links zu folgen und
            sich weitere Informationen auf&nbsp;&nbsp;Drittseiten anzusehen.
            Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.
            <br />
            Anwendungsbereich
            <br />
            Diese Datenschutzerklärung gilt für alle von uns im
            Unternehmen&nbsp;&nbsp;verarbeiteten personenbezogenen Daten und für
            alle personenbezogenen&nbsp;&nbsp;Daten, die von uns beauftragte
            Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen
            Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie
            zum Beispiel Name, E-Mail-Adresse und
            postalische&nbsp;&nbsp;Anschrift einer Person. Die Verarbeitung
            personenbezogener Daten sorgt&nbsp;&nbsp;dafür, dass wir unsere
            Dienstleistungen und Produkte anbieten und&nbsp;&nbsp;abrechnen
            können, sei es online oder offline. Der
            Anwendungsbereich&nbsp;&nbsp;dieser Datenschutzerklärung umfasst:
            <br />
            alle Onlineauftritte (Websites, Onlineshops), die wir betreiben
            <br />
            Social Media Auftritte und E-Mail-Kommunikation
            <br />
            mobile Apps für Smartphones und andere Geräte
            <br />
            Kurz gesagt: Die&nbsp;&nbsp;Datenschutzerklärung gilt für alle
            Bereiche, in denen personenbezogene&nbsp;&nbsp;Daten im Unternehmen
            über die genannten Kanäle strukturiert
            verarbeitet&nbsp;&nbsp;werden. Sollten wir außerhalb dieser Kanäle
            mit Ihnen in&nbsp;&nbsp;Rechtsbeziehungen eintreten, werden wir Sie
            gegebenenfalls gesondert&nbsp;&nbsp;informieren.
            <br />
            Rechtsgrundlagen
            <br />
            In der folgenden Datenschutzerklärung geben wir Ihnen
            transparente&nbsp;&nbsp;Informationen zu den rechtlichen Grundsätzen
            und Vorschriften, also den&nbsp;&nbsp;Rechtsgrundlagen der
            Datenschutz-Grundverordnung, die uns
            ermöglichen,&nbsp;&nbsp;personenbezogene Daten zu verarbeiten.
            <br /> Was das EU-Recht betrifft, beziehen wir uns auf die
            VERORDNUNG (EU)&nbsp;&nbsp;2016/679 DES EUROPÄISCHEN PARLAMENTS UND
            DES RATES vom 27. April 2016.&nbsp;&nbsp;Diese
            Datenschutz-Grundverordnung der EU können Sie
            selbstverständlich&nbsp;&nbsp;online auf EUR-Lex, dem Zugang zum
            EU-Recht, unter
            https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679
            nachlesen.
            <br />
            Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden
            Bedingungen zutrifft:
            <br />
            Einwilligung (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre
            Einwilligung&nbsp;&nbsp;gegeben, Daten zu einem bestimmten Zweck zu
            verarbeiten. Ein Beispiel&nbsp;&nbsp;wäre die Speicherung Ihrer
            eingegebenen Daten eines Kontaktformulars.
            <br />
            Vertrag (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag
            oder&nbsp;&nbsp;vorvertragliche Verpflichtungen mit Ihnen zu
            erfüllen, verarbeiten wir&nbsp;&nbsp;Ihre Daten. Wenn wir zum
            Beispiel einen Kaufvertrag mit Ihnen&nbsp;&nbsp;abschließen,
            benötigen wir vorab personenbezogene Informationen.
            <br />
            Rechtliche Verpflichtung (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir
            einer rechtlichen&nbsp;&nbsp;Verpflichtung unterliegen, verarbeiten
            wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet
            Rechnungen für die Buchhaltung aufzuheben.&nbsp;&nbsp;Diese
            enthalten in der Regel personenbezogene Daten.
            <br />
            Berechtigte Interessen (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle
            berechtigter Interessen,&nbsp;&nbsp;die Ihre Grundrechte nicht
            einschränken, behalten wir uns die&nbsp;&nbsp;Verarbeitung
            personenbezogener Daten vor. Wir müssen zum
            Beispiel&nbsp;&nbsp;gewisse Daten verarbeiten, um unsere Website
            sicher und wirtschaftlich&nbsp;&nbsp;effizient betreiben zu können.
            Diese Verarbeitung ist somit ein&nbsp;&nbsp;berechtigtes Interesse.
            <br />
            Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im
            öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie dem
            Schutz&nbsp;&nbsp;lebenswichtiger Interessen treten bei uns in der
            Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig
            sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.
            <br />
            Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:
            <br />
            In Österreich ist dies das Bundesgesetz zum Schutz natürlicher
            Personen bei der Verarbeitung personenbezogener Daten
            (Datenschutzgesetz), kurz DSG.
            <br />
            In Deutschland gilt das Bundesdatenschutzgesetz, kurz BDSG.
            <br />
            Sofern weitere regionale oder nationale Gesetze zur Anwendung
            kommen, informieren wir Sie in den folgenden Abschnitten darüber.
            <br />
            Kontaktdaten des Verantwortlichen
            <br />
            Sollten Sie Fragen zum Datenschutz oder zur
            Verarbeitung&nbsp;&nbsp;personenbezogener Daten haben, finden Sie
            nachfolgend die Kontaktdaten&nbsp;&nbsp;der verantwortlichen Person
            bzw. Stelle:
            <br />
            Max Muster
            <br /> Musterstraße 47, 12312 Musterstadt,
            <br /> Musterland
            <br />
            Vertretungsberechtigt: Markus Mustermann
            <br /> E-Mail: office@musterfirma.com
            <br /> Telefon: 01234/56789
            <br /> Impressum: https://www.testfirma.at/impressum/
            <br />
            Speicherdauer
            <br />
            Dass wir personenbezogene Daten nur so lange speichern, wie es
            für&nbsp;&nbsp;die Bereitstellung unserer Dienstleistungen und
            Produkte unbedingt&nbsp;&nbsp;notwendig ist, gilt als generelles
            Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten
            löschen, sobald der Grund für die&nbsp;&nbsp;Datenverarbeitung nicht
            mehr vorhanden ist. In einigen Fällen sind wir&nbsp;&nbsp;gesetzlich
            dazu verpflichtet, bestimmte Daten auch nach Wegfall
            des&nbsp;&nbsp;ursprüngliches Zwecks zu speichern, zum Beispiel zu
            Zwecken der&nbsp;&nbsp;Buchführung.
            <br />
            Sollten Sie die Löschung Ihrer Daten wünschen oder die
            Einwilligung&nbsp;&nbsp;zur Datenverarbeitung widerrufen, werden die
            Daten so rasch wie möglich&nbsp;&nbsp;und soweit keine Pflicht zur
            Speicherung besteht, gelöscht.
            <br />
            Über die konkrete Dauer der jeweiligen Datenverarbeitung
            informieren&nbsp;&nbsp;wir Sie weiter unten, sofern wir weitere
            Informationen dazu haben.
            <br />
            Rechte laut Datenschutz-Grundverordnung
            <br />
            Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die
            folgenden&nbsp;&nbsp;Rechte, die Ihnen zustehen, damit es zu einer
            fairen und transparenten&nbsp;&nbsp;Verarbeitung von Daten kommt:
            <br />
            Sie haben laut Artikel 15 DSGVO ein&nbsp;&nbsp;Auskunftsrecht
            darüber, ob wir Daten von Ihnen verarbeiten. Sollte
            das&nbsp;&nbsp;zutreffen, haben Sie Recht darauf eine Kopie der
            Daten zu erhalten und&nbsp;&nbsp;die folgenden Informationen zu
            erfahren:
            <br />
            zu welchem Zweck wir die Verarbeitung durchführen;
            <br />
            die Kategorien, also die Arten von Daten, die verarbeitet werden;
            <br />
            wer diese Daten erhält und wenn die Daten an Drittländer übermittelt
            werden, wie die Sicherheit garantiert werden kann;
            <br />
            wie lange die Daten gespeichert werden;
            <br />
            das Bestehen des Rechts auf Berichtigung, Löschung oder
            Einschränkung der Verarbeitung und dem
            Widerspruchsrecht&nbsp;&nbsp;gegen die Verarbeitung;
            <br />
            dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu
            diesen Behörden finden Sie weiter unten);
            <br />
            die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;
            <br />
            ob Profiling durchgeführt wird, ob also&nbsp;&nbsp;Daten automatisch
            ausgewertet werden, um zu einem persönlichen Profil&nbsp;&nbsp;von
            Ihnen zu gelangen.
            <br />
            Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der
            Daten, was bedeutet, dass wir Daten richtig&nbsp;&nbsp;stellen
            müssen, falls Sie Fehler finden.
            <br />
            Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf
            Vergessenwerden“), was konkret bedeutet, dass&nbsp;&nbsp;Sie die
            Löschung Ihrer Daten verlangen dürfen.
            <br />
            Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der
            Verarbeitung, was bedeutet, dass wir die Daten&nbsp;&nbsp;nur mehr
            speichern dürfen aber nicht weiter verwenden.
            <br />
            Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit,
            was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem
            gängigen Format zur Verfügung stellen.
            <br />
            Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach
            Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
            <br />
            Wenn die Verarbeitung Ihrer Daten auf&nbsp;&nbsp;Artikel 6 Abs. 1
            lit. e (öffentliches Interesse, Ausübung
            öffentlicher&nbsp;&nbsp;Gewalt) oder Artikel 6 Abs. 1 lit. f
            (berechtigtes Interesse) basiert,&nbsp;&nbsp;können Sie gegen die
            Verarbeitung Widerspruch einlegen. Wir prüfen&nbsp;&nbsp;danach so
            rasch wie möglich, ob wir diesem Widerspruch
            rechtlich&nbsp;&nbsp;nachkommen können.
            <br />
            Werden Daten verwendet, um Direktwerbung&nbsp;&nbsp;zu betreiben,
            können Sie jederzeit gegen diese Art der Datenverarbeitung
            widersprechen. Wir dürfen Ihre Daten danach nicht mehr
            für&nbsp;&nbsp;Direktmarketing verwenden.
            <br />
            Werden Daten verwendet, um Profiling zu&nbsp;&nbsp;betreiben, können
            Sie jederzeit gegen diese Art der
            Datenverarbeitung&nbsp;&nbsp;widersprechen. Wir dürfen Ihre Daten
            danach nicht mehr für Profiling&nbsp;&nbsp;verwenden.
            <br />
            Sie haben laut Artikel 22 DSGVO unter&nbsp;&nbsp;Umständen das
            Recht, nicht einer ausschließlich auf
            einer&nbsp;&nbsp;automatisierten Verarbeitung (zum Beispiel
            Profiling) beruhenden&nbsp;&nbsp;Entscheidung unterworfen zu werden.
            <br />
            Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt,
            Sie können sich jederzeit bei der&nbsp;&nbsp;Datenschutzbehörde
            beschweren, wenn Sie der Meinung sind, dass
            die&nbsp;&nbsp;Datenverarbeitung von personenbezogenen Daten gegen
            die DSGVO verstößt.
            <br />
            Kurz gesagt: Sie haben Rechte – zögern Sie nicht, die oben gelistete
            verantwortliche Stelle bei uns zu kontaktieren!
            <br />
            Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen
            das&nbsp;&nbsp;Datenschutzrecht verstößt oder Ihre
            datenschutzrechtlichen Ansprüche in&nbsp;&nbsp;sonst einer Weise
            verletzt worden sind, können Sie sich bei
            der&nbsp;&nbsp;Aufsichtsbehörde beschweren. Diese ist für Österreich
            die&nbsp;&nbsp;Datenschutzbehörde, deren Website Sie unter
            https://www.dsb.gv.at/ finden. In Deutschland gibt es für jedes
            Bundesland einen&nbsp;&nbsp;Datenschutzbeauftragten. Für nähere
            Informationen können Sie sich an die Bundesbeauftragte für den
            Datenschutz und die Informationsfreiheit (BfDI) wenden. Für unser
            Unternehmen ist die folgende lokale Datenschutzbehörde zuständig:
            <br />
            Erklärung verwendeter Begriffe
            <br />
            Wir sind stets bemüht unsere Datenschutzerklärung so klar
            und&nbsp;&nbsp;verständlich wie möglich zu verfassen. Besonders bei
            technischen und&nbsp;&nbsp;rechtlichen Themen ist das allerdings
            nicht immer ganz einfach. Es macht oft Sinn juristische Begriffe
            (wie z. B. personenbezogene Daten) oder&nbsp;&nbsp;bestimmte
            technische Ausdrücke (wie z. B. Cookies, IP-Adresse)
            zu&nbsp;&nbsp;verwenden. Wir möchte diese aber nicht ohne Erklärung
            verwenden.&nbsp;&nbsp;Nachfolgend finden Sie nun eine alphabetische
            Liste von wichtigen&nbsp;&nbsp;verwendeten Begriffen, auf die wir in
            der bisherigen&nbsp;&nbsp;Datenschutzerklärung vielleicht noch nicht
            ausreichend eingegangen sind. Falls diese Begriffe der DSGVO
            entnommen wurden und es sich um&nbsp;&nbsp;Begriffsbestimmungen
            handelt, werden wir hier auch die DSGVO-Texte&nbsp;&nbsp;anführen
            und gegebenenfalls noch eigene Erläuterungen hinzufügen.
            <br />
            Auftragsverarbeiter
            <br />
            Begriffsbestimmung nach Artikel 4 der DSGVO
            <br />
            Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            <br />
            „Auftragsverarbeiter“ eine natürliche oder juristische Person,
            Behörde, Einrichtung oder&nbsp;&nbsp;andere Stelle, die
            personenbezogene Daten im Auftrag des&nbsp;&nbsp;Verantwortlichen
            verarbeitet;
            <br />
            Erläuterung: Wir sind als Unternehmen und Websiteinhaber für alle
            Daten, die wir von Ihnen&nbsp;&nbsp;verarbeiten verantwortlich.
            Neben den Verantwortlichen kann es auch&nbsp;&nbsp;sogenannte
            Auftragsverarbeiter geben. Dazu zählt jedes Unternehmen
            bzw.&nbsp;&nbsp;jede Person, die in unserem Auftrag personenbezogene
            Daten verarbeitet.&nbsp;&nbsp;Auftragsverarbeiter können folglich,
            neben Dienstleistern wie&nbsp;&nbsp;Steuerberater, etwa auch
            Hosting- oder Cloudanbieter, Bezahlungs-
            oder&nbsp;&nbsp;Newsletter-Anbieter oder große Unternehmen wie
            beispielsweise Google&nbsp;&nbsp;oder Microsoft sein.
            <br />
            Einwilligung
            <br />
            Begriffsbestimmung nach Artikel 4 der DSGVO
            <br />
            Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            <br />
            „Einwilligung“ der&nbsp;&nbsp;betroffenen Person jede freiwillig für
            den bestimmten Fall, in&nbsp;&nbsp;informierter Weise und
            unmissverständlich abgegebene Willensbekundung in Form einer
            Erklärung oder einer sonstigen eindeutigen
            bestätigenden&nbsp;&nbsp;Handlung, mit der die betroffene Person zu
            verstehen gibt, dass sie mit&nbsp;&nbsp;der Verarbeitung der sie
            betreffenden personenbezogenen Daten&nbsp;&nbsp;einverstanden ist;
            <br />
            Erläuterung: In der Regel erfolgt bei Websites eine solche
            Einwilligung über ein&nbsp;&nbsp;Cookie-Consent-Tool. Sie kennen das
            bestimmt. Immer wenn Sie erstmals&nbsp;&nbsp;eine Website besuchen,
            werden Sie meist über einen Banner gefragt, ob&nbsp;&nbsp;Sie der
            Datenverarbeitung zustimmen bzw. einwilligen. Meist können
            Sie&nbsp;&nbsp;auch individuelle Einstellungen treffen und so selbst
            entscheiden,&nbsp;&nbsp;welche Datenverarbeitung Sie erlauben und
            welche nicht. Wenn Sie nicht&nbsp;&nbsp;einwilligen, dürfen auch
            keine personenbezogene Daten von Ihnen&nbsp;&nbsp;verarbeitet
            werden. Grundsätzlich kann eine Einwilligung natürlich
            auch&nbsp;&nbsp;schriftlich, also nicht über ein Tool, erfolgen.
            <br />
            Personenbezogene Daten
            <br />
            Begriffsbestimmung nach Artikel 4 der DSGVO
            <br />
            Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            <br />
            „personenbezogene Daten“ alle Informationen, die sich auf eine
            identifizierte oder&nbsp;&nbsp;identifizierbare natürliche Person
            (im Folgenden „betroffene Person“)&nbsp;&nbsp;beziehen; als
            identifizierbar wird eine natürliche Person angesehen, die direkt
            oder indirekt, insbesondere mittels Zuordnung zu einer
            Kennung&nbsp;&nbsp;wie einem Namen, zu einer Kennnummer, zu
            Standortdaten, zu einer&nbsp;&nbsp;Online-Kennung oder zu einem oder
            mehreren besonderen Merkmalen, die&nbsp;&nbsp;Ausdruck der
            physischen, physiologischen, genetischen,
            psychischen,&nbsp;&nbsp;wirtschaftlichen, kulturellen oder sozialen
            Identität dieser natürlichen Person sind, identifiziert werden kann;
            <br />
            Erläuterung:&nbsp;&nbsp;Personenbezogene Daten sind also all jene
            Daten, die Sie als Person&nbsp;&nbsp;identifizieren können. Das sind
            in der Regel Daten wie etwa:
            <br />
            Name
            <br />
            Adresse
            <br />
            E-Mail-Adresse
            <br />
            Post-Anschrift
            <br />
            Telefonnummer
            <br />
            Geburtsdatum
            <br />
            Kennnummern wie Sozialversicherungsnummer,
            Steueridentifikationsnummer, Personalausweisnummer oder
            Matrikelnummer
            <br />
            Bankdaten wie Kontonummer, Kreditinformationen, Kontostände uvm.
            <br />
            Laut Europäischem Gerichtshof (EuGH) zählt auch Ihre IP-Adresse zu
            den personenbezogenen Daten. IT-Experten können anhand Ihrer
            IP-Adresse zumindest den ungefähren&nbsp;&nbsp;Standort Ihres Geräts
            und in weiterer Folge Sie als
            Anschlussinhabers&nbsp;&nbsp;feststellen. Daher benötigt auch das
            Speichern einer IP-Adresse eine&nbsp;&nbsp;Rechtsgrundlage im Sinne
            der DSGVO. Es gibt auch noch sogenannte „besondere Kategorien“ der
            personenbezogenen Daten, die auch besonders schützenswert sind. Dazu
            zählen:
            <br />
            rassische und ethnische Herkunft
            <br />
            politische Meinungen
            <br />
            religiöse bzw. weltanschauliche Überzeugungen
            <br />
            die Gewerkschaftszugehörigkeit
            <br />
            genetische Daten wie beispielsweise Daten, die aus Blut- oder
            Speichelproben entnommen werden
            <br />
            biometrische Daten (das sind&nbsp;&nbsp;Informationen zu
            psychischen, körperlichen oder
            verhaltenstypischen&nbsp;&nbsp;Merkmalen, die eine Person
            identifizieren können).
            <br /> Gesundheitsdaten
            <br />
            Daten zur sexuellen Orientierung oder zum Sexualleben
            <br />
            Profiling
            <br />
            Begriffsbestimmung nach Artikel 4 der DSGVO
            <br />
            Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            <br />
            „Profiling“ jede Art&nbsp;&nbsp;der automatisierten Verarbeitung
            personenbezogener Daten, die darin&nbsp;&nbsp;besteht, dass diese
            personenbezogenen Daten verwendet werden, um&nbsp;&nbsp;bestimmte
            persönliche Aspekte, die sich auf eine natürliche
            Person&nbsp;&nbsp;beziehen, zu bewerten, insbesondere um Aspekte
            bezüglich&nbsp;&nbsp;Arbeitsleistung, wirtschaftliche Lage,
            Gesundheit, persönliche&nbsp;&nbsp;Vorlieben, Interessen,
            Zuverlässigkeit, Verhalten, Aufenthaltsort
            oder&nbsp;&nbsp;Ortswechsel dieser natürlichen Person zu analysieren
            oder vorherzusagen;
            <br />
            Erläuterung: Beim&nbsp;&nbsp;Profiling werden verschiedene
            Informationen über eine Person&nbsp;&nbsp;zusammengetragen, um
            daraus mehr über diese Person zu erfahren. Im&nbsp;&nbsp;Webbereich
            wird Profiling häufig für Werbezwecke oder auch
            für&nbsp;&nbsp;Bonitätsprüfungen angewandt. Web- bzw.
            Werbeanalyseprogramme sammeln zum Beispiel Daten über Ihre Verhalten
            und Ihre Interessen auf einer&nbsp;&nbsp;Website. Daraus ergibt sich
            ein spezielles Userprofil, mit dessen Hilfe&nbsp;&nbsp;Werbung
            gezielt an eine Zielgruppe ausgespielt werden kann.
            <br /> <br />
            Verantwortlicher
            <br />
            Begriffsbestimmung nach Artikel 4 der DSGVO
            <br />
            Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            <br />
            „Verantwortlicher“&nbsp;&nbsp;die natürliche oder juristische
            Person, Behörde, Einrichtung oder andere Stelle, die allein oder
            gemeinsam mit anderen über die Zwecke und&nbsp;&nbsp;Mittel der
            Verarbeitung von personenbezogenen Daten entscheidet;
            sind&nbsp;&nbsp;die Zwecke und Mittel dieser Verarbeitung durch das
            Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so kann
            der Verantwortliche&nbsp;&nbsp;beziehungsweise können die bestimmten
            Kriterien seiner Benennung nach&nbsp;&nbsp;dem Unionsrecht oder dem
            Recht der Mitgliedstaaten vorgesehen werden;
            <br />
            Erläuterung: In unserem&nbsp;&nbsp;Fall sind wir für die
            Verarbeitung Ihrer personenbezogenen Daten&nbsp;&nbsp;verantwortlich
            und folglich der “Verantwortliche”. Wenn wir
            erhobene&nbsp;&nbsp;Daten zur Verarbeitung an andere Dienstleister
            weitergeben, sind diese&nbsp;&nbsp;“Auftragsverarbeiter”. Dafür muss
            ein “Auftragsverarbeitungsvertrag&nbsp;&nbsp;(AVV)” unterzeichnet
            werden.
            <br /> <br />
            Verarbeitung
            <br />
            Begriffsbestimmung nach Artikel 4 der DSGVO
            <br />
            Im Sinne dieser Verordnung bezeichnet der Ausdruck:
            <br />
            „Verarbeitung“ jeden mit oder ohne Hilfe automatisierter Verfahren
            ausgeführten&nbsp;&nbsp;Vorgang oder jede solche Vorgangsreihe im
            Zusammenhang mit&nbsp;&nbsp;personenbezogenen Daten wie das Erheben,
            das Erfassen, die Organisation, das Ordnen, die Speicherung, die
            Anpassung oder Veränderung, das&nbsp;&nbsp;Auslesen, das Abfragen,
            die Verwendung, die Offenlegung durch&nbsp;&nbsp;Übermittlung,
            Verbreitung oder eine andere Form der Bereitstellung,
            den&nbsp;&nbsp;Abgleich oder die Verknüpfung, die Einschränkung, das
            Löschen oder die&nbsp;&nbsp;Vernichtung;
            <br />
            Anmerkung: Wenn wir in&nbsp;&nbsp;unserer Datenschutzerklärung von
            Verarbeitung sprechen, meinen wir damit jegliche Art von
            Datenverarbeitung. Dazu zählt, wie oben in der&nbsp;&nbsp;originalen
            DSGVO-Erklärung erwähnt, nicht nur das Erheben sondern
            auch&nbsp;&nbsp;das Speichern und Verarbeiten von Daten.
            <br />
            Schlusswort
            <br />
            Herzlichen Glückwunsch! Wenn Sie diese Zeilen lesen, haben Sie
            sich&nbsp;&nbsp;wirklich durch unsere gesamte Datenschutzerklärung
            „gekämpft“ oder&nbsp;&nbsp;zumindest bis hier hin gescrollt. Wie Sie
            am Umfang unserer&nbsp;&nbsp;Datenschutzerklärung sehen, nehmen wir
            den Schutz Ihrer persönlichen&nbsp;&nbsp;Daten, alles andere als auf
            die leichte Schulter.
            <br /> Uns ist es wichtig, Sie nach bestem Wissen und Gewissen über
            die&nbsp;&nbsp;Verarbeitung personenbezogener Daten zu informieren.
            Dabei wollen wir&nbsp;&nbsp;Ihnen aber nicht nur mitteilen, welche
            Daten verarbeitet werden, sondern auch die Beweggründe für die
            Verwendung diverser Softwareprogramme&nbsp;&nbsp;näherbringen. In
            der Regel klingen Datenschutzerklärung sehr technisch&nbsp;&nbsp;und
            juristisch. Da die meisten von Ihnen aber keine Webentwickler
            oder&nbsp;&nbsp;Juristen sind, wollten wir auch sprachlich einen
            anderen Weg gehen und&nbsp;&nbsp;den Sachverhalt in einfacher und
            klarer Sprache erklären. Immer ist dies natürlich aufgrund der
            Thematik nicht möglich. Daher werden die&nbsp;&nbsp;wichtigsten
            Begriffe am Ende der Datenschutzerklärung näher erläutert.
            <br /> Bei Fragen zum Thema Datenschutz auf unserer Website zögern
            Sie bitte&nbsp;&nbsp;nicht, uns oder die verantwortliche Stelle zu
            kontaktieren. Wir wünschen Ihnen noch eine schöne Zeit und hoffen,
            Sie auf unserer Website bald&nbsp;&nbsp;wieder begrüßen zu dürfen.
            <br />
            Alle Texte sind urheberrechtlich geschützt.
          </p>
        </div>
      </div>

      <OutroBottom
        className="outro-bottom-8"
        to="/desktop-account"
        to1="/desktop-shoppingcart"
        to2="/desktop-order-details"
        to3="/desktop-favorites"
        to4="/desktop-notifications"
        to5="/desktop-impressum"
      />
    </div>
  );
};
