// LocationScreenMobile.jsx
import React from "react";
import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";
import { NavbarBottomPhone } from "../../components/NavbarBottomPhone";
import "./LocationScreenMobile.css";


export default function LocationScreenMobile(props) {
    const {
        companyLocation,
        directionsResponse,
        userLocation,
        setUserLocation,
        userCoords,
        setUserCoords,
        setMapInstance,
        getUserLocation,
        fetchDirections,
    } = props;

    return (
            <div className="location-screen">
                <div className="google-map-container">
                    <GoogleMap
                        mapContainerStyle={{ height: "400px", width: "100%" }}
                        zoom={12}
                        center={companyLocation}
                        options={{
                            mapId: "66ec27780ea5a6fd",
                        }}
                        onLoad={(map) => setMapInstance(map)}
                    >
                        {/* show route */}
                        {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
                    </GoogleMap>
                </div>

                <div className="route-input-container">
                    <button onClick={getUserLocation} className="route-button">
                        Meinen Standort verwenden
                    </button>
                </div>

                <div className="route-input-container">
                    <input
                        type="text"
                        placeholder="Geben Sie Ihre Adresse ein"
                        value={userLocation}
                        onChange={(e) => setUserLocation(e.target.value)}
                        className="route-input"
                    />
                    <button onClick={() => fetchDirections()} className="route-button">
                        Route berechnen
                    </button>
                </div>

                <div className="company-info">
                    <h2 className="company-title">Darkstone Mycology</h2>
                    <p className="company-address">Uttendorf 13, 3385 Prinzersdorf, Österreich</p>
                </div>

                <NavbarBottomPhone className="navbar-bottom-phone-instance" />
            </div>

    );
}
