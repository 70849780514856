import React, {useEffect} from "react";
import {RouterProvider, createBrowserRouter, Link} from "react-router-dom";
import { AccountScreen } from "./screens/AccountScreen";
import { DesktopImpressum } from "./screens/DesktopImpressum";
import { FeedbackScreen } from "./screens/FeedbackScreen";
import { LocationScreen } from "./screens/LocationScreen";
import { MyAccount } from "./screens/MyAccountScreen";
import { MyOrderScreen } from "./screens/MyOrdersScreen";
import { MushroomDetail } from "./screens/MushroomDetail";
import { Notifications } from "./screens/Notifications";
import { PasswordChange } from "./screens/PasswordChange";
import { PopupConfirmeOrder } from "./screens/PopupConfirmeOrder";
import { SettingMushroomNotificationScreen } from "./screens/NotificationSettings/SettingMushroomNotificationScreen";
import { SettingOrderNotificationScreen } from "./screens/NotificationSettings/SettingOrderNotificationScreen";
import { SettingScreen } from "./screens/NotificationSettings/SettingScreen";
import { ShoppingcartScreen } from "./screens/ShoppingcartScreen";
import { StartScreen } from "./screens/StartScreen";
import {CartProvider} from "./context/CartContext";
import {LoginScreen} from "./screens/LoginScreen";
import {RegisterScreen} from "./screens/RegisterScreen";
import {AuthProvider, useAuth} from "./context/AuthContext";
import {OrderDetail} from "./components/OrderDetail";
import {OrderProvider} from "./context/OrderContext";
import {getFirebaseToken, listenToForegroundMessages, requestNotificationPermission} from "./firebase-messaging";
import {NotificationProvider, useNotifications} from "./context/NotificationContext";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import {PrivacyPolicy} from "./screens/PrivcayPolicyScreen/PrivacyPolicy";
import {TermsOfService} from "./screens/TermsOfServiceScreen/TermsOfService";
import {NotificationsHelp} from "./screens/NotificationsHelp/NotificationsHelp";
import {ConfirmOrderScreen} from "./screens/ConfirmOrderScreen/ConfirmOrderScreen";



export const App = () => {
  return (
      <AuthProvider>
        <NotificationProvider>
           <OrderProvider>
             <CartProvider>
               <ToastContainer />
                  <AppLogic />
               </CartProvider>
             </OrderProvider>
        </NotificationProvider>
      </AuthProvider>
  );
};

/*

<ToastContainer
  position="top-center"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={true}
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
/>

 */


const router = createBrowserRouter([
  {
    path: "/*",
    element: <StartScreen />,
  },
  {
    path: "/start-screen",
    element: <StartScreen />,
  },
  {
    path: "/setting-detail-mushrooms",
    element: <SettingMushroomNotificationScreen />,
  },
  {
    path: "/setting-detail-order",
    element: <SettingOrderNotificationScreen />,
  },
  {
    path: "/login-screen",
    element: <LoginScreen />,
  },
  {
    path: "/register-screen",
    element: <RegisterScreen />,
  },
  {
    path: "/password-change",
    element: <PasswordChange />,
  },
  {
    path: "/notifications",
    element: <Notifications />,
  },
  {
    path: "/account-screen",
    element: <AccountScreen />,
  },
  {
    path: "/myorder-screen",
    element: <MyOrderScreen />,
  },
  {
    path: "/myorder-detail/:orderId",
    element: <OrderDetail />,
  },
  {
    path: "/location-screen",
    element: <LocationScreen />,
  },
  {
    path: "/mushroom-detail",
    element: <MushroomDetail />,
  },
  {
    path: "/product/:productId",
    element: <MushroomDetail />,
  },
  {
    path: "/shoppingcart-screen",
    element: <ShoppingcartScreen />,
  },
  {
    path: "/desktop-impressum",
    element: <DesktopImpressum />,
  },
  {
    path: "/settings",
    element: <SettingScreen />,
  },
  {
    path: "/popup-confirme-order",
    element: <PopupConfirmeOrder />,
  },
  {
    path: "/my-account",
    element: <MyAccount />,
  },
  {
    path: "/feedbackscreen",
    element: <FeedbackScreen />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy/>,
  },
  {
    path: "/terms-of-service",
    element: <TermsOfService />,
  },
  {
    path: "/notifications-help",
    element: <NotificationsHelp />,
  },
  {
    path: "/confirm-order",
    element: <ConfirmOrderScreen />,
  },

]);

export const AppLogic = () => {
  const { user } = useAuth();
  const { addNotification } = useNotifications();
  const [errorMessage, setErrorMessage] = React.useState("");

  useEffect(() => {
    // request permission
    const setupNotifications = async () => {
      const hasPermission = await requestNotificationPermission();
      if (hasPermission) {
        const token = await getFirebaseToken();

      }
      else{
        setErrorMessage("Benachrichtigungen sind blockiert. Bitte gehe in deine Geräteeinstellungen, um sie zu aktivieren. Klicke hier für Hilfe.");
      }
    };


}, [user]);


  useEffect(() => {
    // config message listener
    const listener = (payload) => {
      console.log("Notification received in AppLogic:", payload);


      let title = 'Neue Nachricht'; // Standardwert
      let body = 'Keine weiteren Informationen. Foreground'; // Standardwert

/*
      const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
      if(isIOS){
        //return
      }
*/
        title = payload.data?.title || title;
        body = payload.data?.body || body;


      // toast works wonderfully on android, iOS hates me
      toast.info(
          <div>
            <strong>{title}</strong>
            <p>{body}</p>
          </div>,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
      );

      // store to indexedDB
      addNotification({
        id: payload.data?.messageId || 'default-id',
        title,
        body,
        timestamp: new Date().toISOString(),
      });
    };

    // register listener
    listenToForegroundMessages(listener);

    // cleanup
    return () => {
      console.log("Cleanup: Remove listener");
    };
  }, []);



  return (
      <>
      {errorMessage && (
          <div className="error-popup">
            <p>{errorMessage}</p>
            <Link to="/notifications-help">Hilfe zu Benachrichtigungen</Link>
            <button onClick={() => setErrorMessage(null)}>OK</button>
          </div>
      )}
                <RouterProvider router={router} />

      </>
  );
};
