import {ProductItem} from "../components/ProductItem";
import React from "react";

export const ProductScrollView = ({ products }) => {

    return (
        <div className="product-scroll-view">
            {products.map((product) => (
                <ProductItem
                    key={product.productId}
                    to={`/product/${product.productId}`}
                    mushroomSpecies={product.mushroomSpecies}
                    description={product.description}
                    remainingWeight={product.remainingWeight ?? 0}
                    productId={product.productId}
                    price={product.price}
                />
            ))}
        </div>
    );
};


export default ProductScrollView;
