import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import "./PopupAvailableStyle.css";
import {
    actualizeRemainingWeightForProduct,
    fetchSuggestedWeights,
    releaseHarvests,
    reserveHarvest
} from "../../Utils/DatabaseFetch";
import PropTypes from "prop-types";
import {useCart} from "../../context/CartContext";

export const PopupAvailable = ({ suggestedWeights, onClose, productId, mushroomSpecies, price }) => {
    const navigate = useNavigate();
    const [timeRemaining, setTimeRemaining] = useState("");
    const [selectedWeight, setSelectedWeight] = useState(null);
    const [isExpired, setIsExpired] = useState(false);
    const { addToCart } = useCart();
    const location = useLocation();

    const belowTarget = suggestedWeights.belowTarget;
    const aboveTarget = suggestedWeights.aboveTarget;
    const expiryDate = belowTarget?.reservationExpiry || aboveTarget?.reservationExpiry;

    // Countdown-Logic
    useEffect(() => {
        if (!expiryDate) return;

        const calculateTimeRemaining = () => {
            const now = new Date();
            const expiry = new Date(expiryDate);
            const diff = expiry - now;

            if (diff <= 0) {
                setTimeRemaining("Abgelaufen");
                const combinedHarvestIds = [
                    ...(suggestedWeights.belowTarget?.harvestIds || []),
                    ...(suggestedWeights.aboveTarget?.harvestIds || [])
                ];
                releaseHarvests(combinedHarvestIds);

                setIsExpired(true);
            } else {
                const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((diff % (1000 * 60)) / 1000);
                setTimeRemaining(`${minutes}m ${seconds}s`);
            }
        };


        calculateTimeRemaining();
        const timer = setInterval(calculateTimeRemaining, 1000);

        return () => clearInterval(timer);
    }, [expiryDate]);

    useEffect(() => {
        if (isExpired) {
            alert("Die Reservierung ist abgelaufen. Bitte wählen Sie eine neue Menge.");
            actualizeRemainingWeightForProduct(productId);
            onClose();
        }
    }, [isExpired, onClose]);



    const handleWeightSelection = (weightData) => {
        setSelectedWeight(weightData);
    };

    const handleAddToCart = async () => {

        if (selectedWeight) {

            const reserveResults = await reserveHarvest(selectedWeight.harvestIds);

            addToCart({
                productId,
                quantity: selectedWeight.totalWeight,
                price,
                mushroomSpecies,
                harvestIds: reserveResults.map((res) => res.harvestId),
                expiryDates: reserveResults.map((res) => res.expiryDate),
            });

            //get the non-selected weight, to release the harvest again
            const combinedHarvestIds = [
                ...(belowTarget && selectedWeight !== belowTarget ? belowTarget.harvestIds : []),
                ...(aboveTarget && selectedWeight !== aboveTarget ? aboveTarget.harvestIds : []),
            ];

            //release them
            if (combinedHarvestIds.length > 0) {
                await releaseHarvests(combinedHarvestIds);
            }



            //await actualizeRemainingWeightForProduct(productId);
            if (location.pathname === "/shoppingcart-screen") {
                onClose(); // Popup schließen, keine Navigation
            } else {
                navigate("/shoppingcart-screen", {
                    state: { productId, quantity: selectedWeight.totalWeight, harvestIds: selectedWeight.harvestIds, mushroomSpecies },
                });
            }
        }
    };



    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <button className="close-button" onClick={onClose}>X</button>
                <p className="popup-title">Die nächst verfügbaren Mengen sind:</p>
                <p className="popup-subtitle">Die Pilze werden in Traubenform geerntet, ein teilen dieser
                verringert die Haltbarkeit, sie trocknen schneller aus. Um jeden Kunden mit der gleichen Qualität zu bedienen, möchte ich dies vermeiden.</p>

                {/* Render below target weight */}
                {belowTarget && (
                    <div
                        className={`weight-option ${selectedWeight === belowTarget ? "selected" : ""}`}
                        onClick={() => handleWeightSelection(belowTarget)}
                    >
                        <div className="weight-text">
                            {belowTarget.totalWeight} g
                        </div>
                    </div>
                )}

                {/* Render above target weight */}
                {aboveTarget && (
                    <div
                        className={`weight-option ${selectedWeight === aboveTarget ? "selected" : ""}`}
                        onClick={() => handleWeightSelection(aboveTarget)}
                    >
                        <div className="weight-text">
                            {aboveTarget.totalWeight} g
                        </div>
                    </div>
                )}

                {expiryDate && (
                    <div>
                        <p className="reservation-header">
                            Die Mengen sind für dich reserviert.
                        </p>
                        <p className="reservation-expiry">
                            Reservierung läuft ab in: {timeRemaining}
                        </p>
                    </div>

                        )}

                        <p className="selection-prompt">Bitte wähle eine Menge aus</p>

                        <div className="popup-available_button-container">
                            <button
                                className="popup-available_button-container_add-to-cart-button"
                                onClick={handleAddToCart}
                                disabled={!selectedWeight}
                            >
                                In den Warenkorb legen
                            </button>
                            <button className="popup-available_button-container_cancel-button" onClick={onClose}>Abbrechen</button>
                        </div>

                    </div>
                    </div>
    );
};

PopupAvailable.propTypes = {
    suggestedWeights: PropTypes.shape({
        belowTarget: PropTypes.shape({
            totalWeight: PropTypes.number.isRequired,
            harvestIds: PropTypes.arrayOf(PropTypes.number).isRequired,
            reservationExpiry: PropTypes.string.isRequired,
        }),
        aboveTarget: PropTypes.shape({
            totalWeight: PropTypes.number.isRequired,
            harvestIds: PropTypes.arrayOf(PropTypes.number).isRequired,
            reservationExpiry: PropTypes.string.isRequired,
        }),
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    productId: PropTypes.number.isRequired,
};
