/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

export const OutroBottom = ({ className, to, to1, to2, to3, to4, to5 }) => {
  return (
    <div className={`outro-bottom ${className}`}>
      <img
        className="leonardo-phoenix"
        alt="Leonardo phoenix"
        src="/img/leonardo-phoenix-change-the-lettering-to-darkstone-mycology-as-0-6.png"
      />

      <div className="div-3">
        <div className="header-2">
          <div className="text-wrapper-8">Account</div>
        </div>

        <div className="body">
          <Link className="text-wrapper-9" to={to}>
            Profil
          </Link>

          <Link className="text-wrapper-10" to={to1}>
            Warenkorb
          </Link>

          <Link className="text-wrapper-10" to={to2}>
            {" "}
            Bestellungen
          </Link>

          <Link className="text-wrapper-10" to={to3}>
            Favoriten
          </Link>

          <Link className="text-wrapper-10" to={to4}>
            Benachrichtungen
          </Link>
        </div>
      </div>

      <div className="div-3">
        <div className="header-2">
          <div className="text-wrapper-8">Nützliches</div>
        </div>

        <div className="body">
          <div className="text-wrapper-11">Über mich</div>

          <div className="text-wrapper-12">Kontakt</div>

          <div className="text-wrapper-12"> Rezepte</div>

          <div className="text-wrapper-12">Promotions</div>

          <div className="text-wrapper-12">Neuheiten</div>
        </div>
      </div>

      <div className="div-3">
        <div className="header-2">
          <div className="text-wrapper-8">Hilfe Center</div>
        </div>

        <div className="body">
          <div className="text-wrapper-13">Zahlungsmöglichkeiten</div>

          <div className="text-wrapper-12">Abholungen</div>

          <div className="text-wrapper-12">Q&amp;A</div>

          <div className="text-wrapper-12">Business Kunden</div>

          <Link className="text-wrapper-10" to={to5}>
            Impressum
          </Link>
        </div>
      </div>
    </div>
  );
};

OutroBottom.propTypes = {
  to: PropTypes.string,
  to1: PropTypes.string,
  to2: PropTypes.string,
  to3: PropTypes.string,
  to4: PropTypes.string,
  to5: PropTypes.string,
};
