import PropTypes from "prop-types";
import { NotificationIcon } from "../NotificationIcon";
import "./style.css";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import {useNotifications} from "../../context/NotificationContext";

export const WelcomeHeader = ({ currentScreen   }) => {
    const { notifications } = useNotifications();
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate("/login-screen");
    };

    const handleSettingsClick = () => {
        // Navigate to the settings screen with the currentScreen in state
        navigate("/settings", { state: { from: currentScreen } });
    };


    return (
      <div className="welcome-header">
          <div className="user-name">
              {user ? (
                  <p>Hallo, {user.firstName}!</p>
                  ) : (
                  <p onClick={() => navigate("/login-screen")}>Hallo Gast!
          </p>
          )}
          </div>

          <div className="icon-container">
              {user ? (
                  <>
                      <NotificationIcon notifications={notifications} to="/notifications" />
                      <img
                          src="/img/settings-icon.png"
                          alt="Einstellungen"
                          className="settings-icon"
                          onClick={handleSettingsClick}
                      />
                  </>
              ) : (
                  <div className="login-container">
                      <p onClick={() => navigate("/login-screen")} className="login-link2">
                          Registrieren / Login
                      </p>
                  </div>
              )}
          </div>
      </div>
    );
};

WelcomeHeader.propTypes = {
  property1: PropTypes.oneOf(["logout", "signed-in"]),
  to: PropTypes.string,
  to1: PropTypes.string,
};
