import React from "react";
import {Link} from "react-router-dom";
import "./MyAccountMobile.css";
import {ErrorPopup} from "../../components/Utils/ErrorPopup";
import {validateName} from "../../Validation/validateName";
import {validateEmail} from "../../Validation/validateEmail";
import {NavbarBottomPhone} from "../../components/NavbarBottomPhone";


export default function MyAccountMobile(props) {
    const {
        firstName, setFirstName,
        lastName, setLastName,
        email, setEmail,
        phoneNumber, setPhone,
        address, setAddress,
        addressPlaceholder, setAddressPlaceholder,
        firstNameError, setFirstNameError,
        lastNameError, setLastNameError,
        emailError, setEmailError,
        touchedFields,
        handleBackClick,
        handleUpdate,
        handleInputChange,
        showErrorPopup,
        errorMessage,
        handleCloseErrorPopup,
        handleConfirmDelete,
    } = props;

    return (
        <div className="my-account-settings-screen">
            <header className="my-account-settings-header">
                <img
                    className="my-account-settings-back-icon"
                    alt="Back"
                    src="/img/ep-back.png"
                    onClick={handleBackClick}
                />
                <div className="my-account-settings-title">Mein Konto</div>
            </header>

            <div className="my-account-settings-body">

                <div
                    className={`mobile_input-container ${firstNameError ? "invalid" : touchedFields.firstName && "valid"}`}>
                    <input
                        type="text"
                        className="mobile_input-field"
                        placeholder=" "
                        value={firstName}
                        onChange={(e) =>
                            handleInputChange(e.target.value, setFirstName, setFirstNameError, validateName, "firstName")
                        }
                    />
                    <label className="mobile_input-label">Vorname</label>
                    <span
                        className={`my-account-settings_validation-input-icon ${firstNameError ? "" : firstName ? "valid" : ""}`}>
            {firstNameError ? "❌" : firstName ? "✅" : ""}
          </span>
                    {firstNameError && <p className="my-account-settings_validation-error-tooltip">{firstNameError}</p>}
                </div>


                <div
                    className={`mobile_input-container ${lastNameError ? "invalid" : touchedFields.lastName && "valid"}`}>
                    <input
                        type="text"
                        className="mobile_input-field"
                        placeholder=" "
                        value={lastName}
                        onChange={(e) =>
                            handleInputChange(e.target.value, setLastName, setLastNameError, validateName, "lastName")
                        }
                    />
                    <label className="mobile_input-label">Nachname</label>
                    <span
                        className={`my-account-settings_validation-input-icon ${lastNameError ? "" : lastName ? "valid" : ""}`}>
            {lastNameError ? "❌" : lastName ? "✅" : ""}
          </span>
                    {lastNameError && <p className="my-account-settings_validation-error-tooltip">{lastNameError}</p>}
                </div>

                <div
                    className={`mobile_input-container ${emailError ? "invalid" : touchedFields.email && "valid"}`}>
                    <input
                        type="email"
                        className="mobile_input-field"
                        placeholder=" "
                        value={email}
                        onChange={(e) =>
                            handleInputChange(e.target.value, setEmail, setEmailError, validateEmail, "email")
                        }
                    />
                    <label className="mobile_input-label">E-Mail</label>
                    <span
                        className={`my-account-settings_validation-input-icon ${emailError ? "" : email ? "valid" : ""}`}>
              {emailError ? "❌" : email ? "✅" : ""}
            </span>
                    {emailError && <p className="my-account-settings_validation-error-tooltip">{emailError}</p>}
                </div>

                <div className="mobile_input-container">
                    <input
                        type="text"
                        className="mobile_input-field"
                        placeholder=" "
                        value={phoneNumber}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <label className="mobile_input-label">Telefonnummer (optional)</label>
                </div>

                <div className="mobile_input-container">
                    <input
                        type="text"
                        className="mobile_input-field"
                        placeholder={addressPlaceholder || " "}
                        onFocus={() => setAddressPlaceholder("Straße / Hausnummer / PLZ")}
                        onBlur={() => setAddressPlaceholder("")}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    <label className="mobile_input-label">Adresse (optional)</label>
                </div>


                <button className="my-account-settings-button" onClick={handleUpdate}>
                    aktualisieren
                </button>


                <Link className="my-account-settings-mobile_change-password" to="/password-change">
                    Passwort ändern
                </Link>

                <button className="mobile_delete-account-button" onClick={handleConfirmDelete}>
                    Konto löschen
                </button>

                {showErrorPopup && (
                    <ErrorPopup
                        message={errorMessage}
                        onClose={handleCloseErrorPopup}
                    />
                )}

            </div>
            <NavbarBottomPhone className="navbar-bottom-phone-instance"/>
        </div>
    );
}
