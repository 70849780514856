import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import "./MyOrderItem.css";
import { getStatusIconPath, getStateDescription } from "../Utils/stateIconMapping";

export const MyOrderItem = ({ className, order, to }) => {
  // Destructure order properties
  const { orderId, date, state, productOrders } = order;

  // Extract product names from productOrders
  const productNames = productOrders && productOrders.length > 0
      ? productOrders.map((po) => po.mushroomSpecies).join(", ")
      : "Keine Produkte";

  // Format the order date
  const orderDate = date ? `Bestellt: ${new Date(date).toLocaleDateString()}` : "";




  return (
      <Link className={`my-order-item ${className}`} to={to}>
          <img
              src={getStatusIconPath(state)}
              alt="Status Icon"
              className="my-order-item-status-icon"
          />
          <div className="my-order-item__details">
              <div className="my-order-item__info">
                  <div className="my-order-item__product-name">{productNames}</div>
                  <div className="my-order-item__status-text">{getStateDescription(state)}</div>
                  <div className="my-order-item__order-date">{orderDate}</div>
              </div>
              <img
                  className="my-order-item__arrow-icon"
                  alt="Details anzeigen"
                  src="/img/ic-baseline-greater-than-16.png"
              />
          </div>
      </Link>
  );
};

MyOrderItem.propTypes = {
    className: PropTypes.string,
    order: PropTypes.shape({
        orderId: PropTypes.number.isRequired,
        date: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
    productOrders: PropTypes.arrayOf(
        PropTypes.shape({
            mushroomSpecies: PropTypes.string.isRequired,

        })
    ),
  }).isRequired,
  to: PropTypes.string.isRequired,
};
