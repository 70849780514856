/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/


import React from "react";
import "./style.css";

export const MushroomIcon = ({className

}) => {
  return (
      <div className={`mushroom-icon ${className}`}>
        <img
            className="img"
            alt="Mushroom icon"
            src="/img/mushroom%20icon_colored.png"
        />
      </div>
  );
};




