/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { DescriptionBar } from "../DescriptionBar";
import { Kastanienseitling } from "../Kastanienseitling";
import "./style.css";
import {Shiitake} from "../Shiitake";
import {Kraeuterseitling} from "../Kraeuterseitling";
import {ShiitakeImage} from "../ShiitakeImage";
import { MushroomImage } from "../Utils/MushroomImage";

export const ProductItem = ({
                              className,
                              mushroomSpecies,
                              description,
                              remainingWeight,
                              productId,
                              price,
                              to,
                            }) => {



  return (
      <Link
          to={`/product/${productId}`}
          state={{ product: { mushroomSpecies, description, remainingWeight, productId, price } }} // product data for state
          className="product-item"
      >
          <MushroomImage mushroomSpecies={mushroomSpecies} size="large" />
        <DescriptionBar
            description={mushroomSpecies}
            remainingWeight={remainingWeight}
        />
      </Link>
  );
};

ProductItem.propTypes = {
  productId: PropTypes.number.isRequired,
  mushroomSpecies: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  remainingWeight: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  to: PropTypes.string.isRequired,
};
