import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./SettingScreen.css";
import { NavbarBottomPhone } from "../../components/NavbarBottomPhone";
import {NotificationOption} from "../../components/NotificationOption";
import {NotificationSettingsDesktop} from "./NotificationSettingsDesktop";
import {useWindowWidth} from "../../breakpoints";

export const SettingScreen = () => {
    const screenWidth = useWindowWidth();
    const navigate = useNavigate();
    const location = useLocation();
    const { from } = location.state || { from: "/start-screen" };

    const handleBackClick = () => {
        navigate(from);
    };

    if (screenWidth >= 1024) {
        return <NotificationSettingsDesktop />;
    }

    return (
        <div className="settings">
            <header className="settings-header">
                <button
                    className="settings-screen_back-button"
                    onClick={handleBackClick}
                >
                    <img className="setting-back-icon" alt="Back" src="/img/ep-back-56.png"/>
                </button>
                <div className="settings-title">
                    Einstellung von
                    <br />
                    Mitteilungen
                </div>
            </header>

            <div className="settings-options">
                <NotificationOption
                    header="Bestellungen"
                    body="Aktualisierungen des Bestellstatus und Vormerkungen"
                    icon="/img/ic-baseline-greater-than-22.png"
                    to="/setting-detail-order"
                    state={{ from }}
                />
                <NotificationOption
                    header="Pilze"
                    body="Hinzugefügte Sorten und Favoriten einstellen"
                    icon="/img/ic-baseline-greater-than-21.png"
                    to="/setting-detail-mushrooms"
                    state={{ from }}
                />
            </div>
            <NavbarBottomPhone className="navbar-bottom-phone-instance" />
        </div>
    );
};
