import { useAuth } from "../../context/AuthContext";
import {fetchProductNames, updateNotificationSettingsOnServer} from "../../Utils/DatabaseFetch";
import { SwitchIcon } from "../SwitchIcon";
import { useEffect, useState } from "react";
import "./SettingDetailItem.css";
import PropTypes from "prop-types";

export const SettingDetailItem = ({
                                      className,
                                      title,
                                      description,
                                      notificationKey, // key for notification option
                                      hasSubList = false,
                                      isReservationSubList = false, //flag for reservation
                                      onToggle,      // callback for parent
                                      defaultState = "off",
                                  }) => {
    const {user, setNotificationSettings} = useAuth();
    const [state, setState] = useState(defaultState);
    const [productList, setProductList] = useState([]);
    const [favoriteSelections, setFavoriteSelections] = useState({});
    const [reservationSelections, setReservationSelections] = useState([]);
    const [isLoading, setIsLoading] = useState(false);



    useEffect(() => {
        // initial state from settings
        const currentState = user?.notificationSettings?.[notificationKey] ? "on" : "off";
        setState(currentState);

        //favorite sublist
        if (hasSubList && currentState === "on") {
            if (isReservationSubList) {
                initializeReservations()
            }
            else {
                fetchProduct();
            }
        } else {
            setProductList([]);
            setFavoriteSelections({});
            setReservationSelections([]);
        }


    }, [user.notificationSettings, notificationKey, hasSubList]);


    const fetchProduct = async () => {
        try {
            const products = await fetchProductNames();
            setProductList(products);

            // initialize favorites
            const initialFavorites = products.reduce((acc, product) => {
                acc[product.mushroomSpecies] = user?.notificationSettings?.favoriteMushroomsList?.includes(product.mushroomSpecies) || false;
                return acc;
            }, {});
           // console.log("Initialisierte Favoriten:", initialFavorites);
            setFavoriteSelections(initialFavorites);
        } catch (error) {
            console.error("Fehler beim Abrufen der Produktliste:", error);
        }
    };

    const initializeReservations = () => {
        const initialReservations = user?.notificationSettings?.reservationList || [];
        setReservationSelections(Array.isArray(initialReservations) ? initialReservations : []);
    };


    const handleToggle = async () => {
        if (isLoading) return;

        const newState = state === "on" ? "off" : "on";

        try {
            setIsLoading(true);
            //setState(newState); // ui actualize
            const updatedSettings = {[notificationKey]: newState === "on"};


            //backend storage
            const updatedResponse = await updateNotificationSettingsOnServer(user.customerId, updatedSettings);

            // local storage
            setNotificationSettings(updatedResponse);

            setState(updatedResponse[notificationKey] ? "on" : "off");

            //inform parent about switching state
            if (onToggle) {
               onToggle(notificationKey, updatedResponse[notificationKey]);
            }




        } catch (error) {
            console.error("Fehler beim Speichern der Benachrichtigungseinstellungen:", error);
            setState(state); // reset state if error occurs
        }
         finally {
            setIsLoading(false);
        }
    };

    // favorite status actualize
    const handleFavoriteToggle = async (productName) => {
        const updatedSelections = {
            ...favoriteSelections,
            [productName]: !favoriteSelections[productName],
        };

        setFavoriteSelections(updatedSelections);

        try {
            setIsLoading(true);

            const selectedFavorites = Object.entries(updatedSelections)
                .filter(([, isSelected]) => isSelected)
                .map(([name]) => name);

            const updatedSettings = {favoriteMushroomsList: selectedFavorites};

            const updatedResponse = await updateNotificationSettingsOnServer(user.customerId, updatedSettings);

            setNotificationSettings(updatedResponse);

        } catch (error) {
            console.error("Fehler beim Aktualisieren der Favoriten:", error);
        }
         finally {
            setIsLoading(false);
        }
    };


    //handle reservation from forecast predictions
    const handleReservationToggle = async (mushroomSpecies, date) => {
        const isAlreadySelected = reservationSelections.some(
            (item) => item.mushroomSpecies === mushroomSpecies && item.date === date
        );

        const updatedSelections = isAlreadySelected
            ? reservationSelections.filter(
                (item) => !(item.mushroomSpecies === mushroomSpecies && item.date === date)
            )
            : [...reservationSelections, { mushroomSpecies, date }];

        setReservationSelections(updatedSelections);

        try {
            setIsLoading(true);

            const updatedSettings = { reservationList: updatedSelections };
            const updatedResponse = await updateNotificationSettingsOnServer(user.customerId, updatedSettings);

            setNotificationSettings(updatedResponse);
        } catch (error) {
            console.error("Fehler beim Aktualisieren der Vormerkungen:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString("de-DE", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    }


    return (
        <>
            <div className={`setting-detail-item ${className}`}>
                <div className="setting-detail-content">
                    <h3 className="setting-detail-title">{title}</h3>
                    <p className="setting-detail-description">{description}</p>
                </div>
                <div className="setting-detail-switch-wrapper" onClick={handleToggle}>
                    <SwitchIcon
                        className="setting-detail-switch"
                        property1={state}
                    />
                </div>
            </div>

            {/* render sub-list as a separate container */}
            {hasSubList && state === "on" && (
                <div className="setting-detail-item_favorites-list-container">
                    {isReservationSubList
                        ? reservationSelections.map((item, index) => (
                            <div className="setting-detail-item_sublist_favorite-item" key={index}>
                                  <span>
                                    {formatDate(item.date)} - {item.mushroomSpecies}
                                  </span>
                            <div
                                    className="setting-detail-switch-wrapper"
                                    onClick={() => handleReservationToggle(item.mushroomSpecies, item.date)}
                                >
                                    <SwitchIcon
                                        className="setting-detail-switch"
                                        property1="on"
                                        propertyOn="/img/switch-on.png"
                                        propertyOff="/img/switch-off.png"
                                    />
                                </div>
                            </div>
                        ))
                        : productList.map((product) => (
                            <div className="setting-detail-item_sublist_favorite-item" key={product.productId}>
                                <span>{product.mushroomSpecies || "Unbekannt"}</span>
                                <div
                                    className="setting-detail-switch-wrapper"
                                    onClick={() => handleFavoriteToggle(product.mushroomSpecies)}
                                >
                                    <SwitchIcon
                                        className="setting-detail-switch"
                                        property1={favoriteSelections[product.mushroomSpecies] ? "on" : "off"}
                                        propertyOn="/img/switch-on.png"
                                        propertyOff="/img/switch-off.png"
                                    />
                                </div>
                            </div>
                        ))}
                </div>
            )}
        </>
    );
};





            SettingDetailItem.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    notificationKey: PropTypes.string.isRequired,
};
