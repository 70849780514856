import React from "react";
import PropTypes from "prop-types";
import { Kastanienseitling } from "../Kastanienseitling";
import "./MushroomImage.css";

export const MushroomImage = ({ mushroomSpecies, size }) => {
    const imageSrc = getImageSrc(mushroomSpecies);

    return (
        <img
            className={`test_mushroom-image ${size}`}
            alt={mushroomSpecies}
            src={imageSrc}
        />
    );
};

// helper function to images paths
const getImageSrc = (species) => {
    switch (species) {
        case "Kastanienseitling":
            return "/img/kastanienseitling-1-3.png";
        case "Shiitake":
            return "/img/shiitake-image-1-3.png";
        case "Kräuterseitling":
            return "/img/kr-uterseitlinge-image-1-3.png";
        default:
            return "/img/kastanienseitling-1-3.png";
    }
};

MushroomImage.propTypes = {
    mushroomSpecies: PropTypes.string.isRequired,
    size: PropTypes.oneOf(["small", "medium", "large"]),
};
