import React from "react";
import ReactDOMClient from "react-dom/client";
import { App } from "./App";

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(`${window.location.origin}/firebase-messaging-sw.js`)
        .then((registration) => {
            console.log('Service Worker registriert mit Scope:', registration.scope);

            // if controller changes (new version) refresh the site
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                console.log('Neuer Service Worker aktiv - lade Seite neu...');
                window.location.reload();
            });
        })
        .catch((err) => {
            console.error('Service Worker Registrierung fehlgeschlagen:', err);
        });
}


const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render(<App />);
