import { useState, useEffect } from "react";
import {handleFetchError} from "./handleFetchError";

export const useFetchWithErrorHandling = (fetchFunction, dependencies = []) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        let isMounted = true; //prevent to state after unmount

        const fetchData = async () => {
            try {
                setLoading(true);
                const result = await fetchFunction();
                if (isMounted) {
                    setData(result);
                }
            } catch (error) {
                const handledError = handleFetchError(error);
                //setErrorMessage(handledError.message);

                if (isMounted) {
                    setErrorMessage(handledError.message);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        //clean up
        return () => {
            isMounted = false;
        };
    }, dependencies);


    return { data, loading, errorMessage, setErrorMessage };
};
