import React from "react";
import "./PrivacyPolicy.css";

export const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <h1>Datenschutzerklärung</h1>
            <p>
                Ihre Privatsphäre ist uns wichtig. Wir erfassen folgende Daten über die Google-Login-Funktion:
            </p>
            <ul>
                <li>Ihr Name</li>
                <li>Ihre E-Mail-Adresse</li>
            </ul>
            <p>
                Diese Daten werden ausschließlich zur Anmeldung und Authentifizierung verwendet und nicht an Dritte weitergegeben.
            </p>
            <p>Bei Fragen kontaktieren Sie uns unter: <a href="mailto:info@example.com">info@example.com</a></p>
        </div>
    );
};
