import React from 'react';
import { NavbarBottomPhone } from '../../components/NavbarBottomPhone';
import { ShoppingCartScroll } from '../../components/ShoppingCartScroll';
import './ShoppingcartScreenMobile.css';
import {ErrorPopup} from "../../components/Utils/ErrorPopup";
import {ExpiredItemsPopup} from "../../components/Utils/ExpiredItemsPopup";
import {PopupAvailable} from "../PopupAvailable";

export default function ShoppingcartScreenMobile(props) {
    const {
        cart,
        removeFromCart,
        addToCart,
        clearCart,
        totalSum,
        totalWeight,
        note,
        setNote,
        handlePlaceOrder,
        navigate,
        showErrorPopup,
        errorPopupMessage,
        handleCloseErrorPopup,
        showExpiredPopup,
        expiredItems,
        setShowExpiredPopup,
        handleExpiredOption,
        handleRemoveExpiredItem,
        handleRemoveAllExpiredItems,
        showSuggestedWeightsPopup,
        selectedItem,
        handleSuggestedWeightsSelection,
        setShowSuggestedWeightsPopup,
        showThankYouPopup,
        setShowThankYouPopup,
    } = props;



    return (
        <div className="shoppingcart-screen-mobile">
            {/* Header */}
            <header className="header-container">
                <div className="header-text">Warenkorb</div>
            </header>

            {/* error-Popup */}
            {showErrorPopup && (
                <ErrorPopup
                    message={errorPopupMessage}
                    onClose={handleCloseErrorPopup}
                />
            )}

            {showExpiredPopup && (
                <ExpiredItemsPopup
                    items={expiredItems}
                    onClose={() => setShowExpiredPopup(false)}
                    onItemRequest={handleExpiredOption}
                    onItemRemove={handleRemoveExpiredItem}
                    onRemoveAll={handleRemoveAllExpiredItems}
                />
            )}

            {/* Suggested weights popup */}
            {showSuggestedWeightsPopup && selectedItem && (

                <PopupAvailable
                    suggestedWeights={selectedItem.suggestedWeights}
                    productId={selectedItem.productId}
                    mushroomSpecies={selectedItem.mushroomSpecies}
                    price={selectedItem.price}
                    onClose={() => setShowSuggestedWeightsPopup(false)}
                    onWeightSelect={handleSuggestedWeightsSelection}
                />
            )}


            {/* Place Order Section */}
            <div className="place-order">
                <div className="note-field">
                  <textarea placeholder="Anmerkungen zur Bestellung hinzufügen..."
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                  />
                </div>

                <div className="summary">
                    <div className="summary-row">
                        <p className="total-label">Gesamtgewicht:</p>
                        <p className="total-amount">{totalWeight} g</p>
                    </div>
                    <div className="summary-row">
                        <p className="total-label">Gesamtsumme:</p>
                        <p className="total-amount">{totalSum} €</p>
                    </div>
                </div>

                <div className="action-buttons">
                    <button
                        className="continue-shopping-button"
                        onClick={() => navigate('/start-screen')}
                    >
                        Weiter shoppen
                    </button>
                    <button
                        className="order-button"
                        onClick={handlePlaceOrder}
                    >
                        Zur Kasse gehen
                    </button>
                </div>
            </div>




            <div className="shoppingcart-screen-mobile_scrollable-content">
                <ShoppingCartScroll cartItems={cart.items} />
            </div>

            {showThankYouPopup && (
                <div className="thank-you-popup-overlay">
                    <div className="thank-you-popup">
                        <p>Danke für deine Bestellung!</p>
                        <button
                            className="continue-shopping-button"
                            onClick={() => {
                                setShowThankYouPopup(false);
                                navigate('/start-screen')
                            }}

                        >
                            Weiter shoppen
                        </button>
                    </div>
                </div>
            )}


            <NavbarBottomPhone className="navbar-bottom-phone-instance"/>

        </div>
    );
};
