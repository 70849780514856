import React from "react";
import "./SuccessPopup.css";

export const SuccessPopup = ({ message, onClose }) => {
    return (
        <div className="popup-overlay">
            <div className="popup">
                <p>{message}</p>
                <button onClick={onClose} className="popup-close-button">Okay</button>
            </div>
        </div>
    );
};
