/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const Kraeuterseitling = ({ size }) => {
  return (
      <img
          className={`mushroom-image ${size}`}
          alt="Kraeuterseitling"
          src="/img/kr-uterseitlinge-image-1-3.png"
      />

  );
};
