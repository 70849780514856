import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import "./style.css";
import {MushroomImage} from "../Utils/MushroomImage";
import {releaseHarvests} from "../../Utils/DatabaseFetch";
import {useCart} from "../../context/CartContext";

export const ShoppingCartItem = ({ mushroomSpecies, quantity, price, expiryDates, harvestIds, onRemove, productId}) => {
    const [timeRemaining, setTimeRemaining] = useState("");
    const { markItemAsExpired } = useCart();
    const [isExpired, setIsExpired] = useState(false); // flag to control expired state


    const earliestExpiryDate = expiryDates && expiryDates.length > 0
        ? expiryDates.reduce((earliest, current) => {
            return new Date(earliest) < new Date(current) ? earliest : current;
        })
        : null;

    useEffect(() => {
        setIsExpired(false);
    }, [productId, earliestExpiryDate]);

    useEffect(() => {
        if (!earliestExpiryDate || isExpired) return;


        const calculateTimeRemaining = () => {
            const now = new Date();
            const expiry = new Date(earliestExpiryDate);
            const diff = expiry - now;

            if (diff <= 0 && !isExpired) {
                setTimeRemaining("Abgelaufen");
                setIsExpired(true);
                releaseHarvests(harvestIds);
                markItemAsExpired(productId);
                //clearInterval(timer); // Timer stop
            } else if (diff > 0) {
                const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((diff % (1000 * 60)) / 1000);
                setTimeRemaining(`${minutes}m ${seconds}s`);

            }
        };

        calculateTimeRemaining();
        const timer = setInterval(calculateTimeRemaining, 1000);

        return () => clearInterval(timer);
    }, [expiryDates, earliestExpiryDate, isExpired, markItemAsExpired, productId]);

    const handleRemove = () => {
        releaseHarvests(harvestIds);
        onRemove();
    };

    return (
        <div className="shopping-cart-item">
            <MushroomImage mushroomSpecies={mushroomSpecies} size="small"/>
            <div className="cart-item-details">
                <h4 className="cart-item-name">{mushroomSpecies}</h4>
                <div className="cart-item-info">
                    <div className="cart-item-row">
                        <span className="cart-item-label">Menge:</span>
                        <span className="cart-item-value">{quantity} g</span>
                    </div>
                    <div className="cart-item-row">
                        <span className="cart-item-label">Preis:</span>
                        <span className="cart-item-value">
              {(quantity * price / 1000).toFixed(2)} €
            </span>
                    </div>
                    <div className="cart-item-row">
                        <span className="cart-item-label">Reservierung läuft ab in:</span>
                        <span className="cart-item-value">{timeRemaining}</span>
                    </div>
                </div>
            </div>
            <button className="remove-button-shoppingCartItem" onClick={handleRemove}>
                <img src="/img/trash-icon-27.png" alt="Remove"/>
            </button>
        </div>
    );
};

ShoppingCartItem.propTypes = {
    mushroomSpecies: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    onRemove: PropTypes.func.isRequired,
    expiryDates: PropTypes.arrayOf(PropTypes.string).isRequired,
};
