import React, {useEffect, useState} from "react";
import "./OrderDetailMobile.css";
import {useNavigate, useParams} from "react-router-dom";
import {useOrders} from "../../context/OrderContext";
import {useFetchWithErrorHandling} from "../../Utils/genericFetchWithErrorHandling";
import {cancelOrder, fetchOrderDetail} from "../../Utils/DatabaseFetch";
import {calculatePriceBreakdown} from "../../Utils/calculatePriceBreakdown";
import {ErrorPopup} from "../Utils/ErrorPopup";
import OrderDetailMobile from "./OrderDetailMobile";
import OrderDetailDesktop from "./OrderDetailDesktop";
import {useWindowWidth} from "../../breakpoints";
import {ConfirmPopup} from "../Utils/ConfirmPopup";
import {SuccessPopup} from "../Utils/SuccessPopup";


export const OrderDetail = () => {
    const screenWidth = useWindowWidth();
    const { orders, setOrders } = useOrders();
    const { orderId } = useParams();
    const navigate = useNavigate();

    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [cancelOrderId, setCancelOrderId] = useState(null);




    const existingOrder = Array.isArray(orders)
        ? orders.find((o) => o?.orderId?.toString() === orderId)
        : null;

    const shouldFetch = !existingOrder;

    const { data: fetchedOrder, loading,  errorMessage, setErrorMessage } = useFetchWithErrorHandling(
        shouldFetch ? () => fetchOrderDetail(orderId) : () => Promise.resolve(null),        //only fetch when order does not exit in context
        [shouldFetch, orderId]
    );


    useEffect(() => {
        if (fetchedOrder && shouldFetch) {
            setOrders((prevOrders) => [...prevOrders, fetchedOrder]);
        }
    }, [fetchedOrder, shouldFetch, setOrders]);

    const handlePopupConfirm = async () => {
        setShowConfirmPopup(false);
        if (!cancelOrderId) return;

        try {
            const response = await cancelOrder(cancelOrderId);
            setSuccessMessage(response);
            setShowSuccessPopup(true);

        } catch (error) {
            setErrorMessage(error.message);
            setShowErrorPopup(true);
        }
    };

    const handlePopupCancel = () => {
        setShowConfirmPopup(false);
        setCancelOrderId(null);
    };

    const initiateCancelOrder = (orderId) => {
        setCancelOrderId(orderId);
        setShowConfirmPopup(true);
    };

    // order from context or fetch
    const order = existingOrder || fetchedOrder;

    const handleCloseErrorPopup = () => {
        setShowErrorPopup(false);
        setErrorMessage(null);  //delete backend error

    };

    const handleSuccessPopup = () => {
        setShowSuccessPopup(false);
        setSuccessMessage(null);
        navigate("/myorder-screen");

    };


    if (loading && !order) {
        return <div>Lädt...</div>;
    }

    if (!order && errorMessage) {
        return (
            <>
                <div>Bestellung nicht gefunden.</div>
                <ErrorPopup message={errorMessage} onClose={handleCloseErrorPopup} />
            </>
        );
    }

    if (!order) {
        return <div>Bestellung nicht gefunden.</div>;
    }



    const orderDate = order.date ? ` ${new Date(order.date).toLocaleDateString()}` : "";

    const { netPrice, taxPrice, totalPrice: formattedTotalPrice } = calculatePriceBreakdown(order.totalPrice);

    //props for displaying both designs
    const commonProps = {
        order,
        orderDate,
        netPrice,
        taxPrice,
        formattedTotalPrice,
        errorMessage,
        handleCloseErrorPopup,
        initiateCancelOrder,
    };

    return(
    <>
        {screenWidth < 1024 ? (
        <OrderDetailMobile {...commonProps} />
    ) : (
        <OrderDetailDesktop {...commonProps} />
    )}
        {showConfirmPopup && (
            <ConfirmPopup
                title="Bestellung stornieren"
                message="Möchtest du diese Bestellung wirklich stornieren?"
                onConfirm={handlePopupConfirm}
                onCancel={handlePopupCancel}
            />
        )}
        {showSuccessPopup && (
            <SuccessPopup
                title="Erfolg"
                message={successMessage}
                onClose={handleSuccessPopup}
            />
        )}
    </>
    );
};

OrderDetail.propTypes = {

};
