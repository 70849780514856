/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/


import React from "react";
import "./style.css";


export const FavoriteIcon = ({ date, isActive, onToggle }) => {

    const handleClick = () => {
        onToggle(date);// callback
    };

  return (
      <div
          className={`favorite-icon ${isActive ? "active" : ""}`}
          onClick={handleClick}
      >
          <img
              className="favorite-icon"
              src={isActive ? "/img/favorite-icon-active.png" : "/img/favorite-icon-inactive.png"}
              alt="Favorite"
          />
      </div>
  );;
};


