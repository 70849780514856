import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import "./NotificationsMobileStyle.css";
import {useNotifications} from "../../context/NotificationContext";
import {useWindowWidth} from "../../breakpoints";
import NotificationsDesktopScreen from "./NotificationsDesktopScreen";
import NotificationsMobileScreen from "./NotificationsMobileScreen";
import {deleteNotification, getNotifications} from "../../Utils/DatabaseFetch";
import {useAuth} from "../../context/AuthContext";

export const Notifications = () => {
    const screenWidth = useWindowWidth();
    const {user} = useAuth();
    const navigate = useNavigate();
   // const { notifications, removeNotification } = useNotifications();
    const [notifications, setNotifications] = useState([]);
    const [swipePosition, setSwipePosition] = useState({});
    const threshold = 100;


    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedNotifications = await getNotifications(user.customerId);
                // sort notifications after timestamp, newest first
                const sortedNotifications = fetchedNotifications.sort((a, b) => {
                    return new Date(b.timestamp) - new Date(a.timestamp);
                });
                setNotifications(sortedNotifications);
            } catch (error) {
                console.error("Fehler beim Abrufen der Benachrichtigungen: ", error);
            }
        };
        fetchData();
    }, [user]);


    const handleTouchStart = (id, e) => {
        const touch = e.touches[0];
        setSwipePosition((prev) => ({ ...prev, [id]: { startX: touch.clientX, translateX: 0 } }));
    };

    const handleTouchMove = (id, e) => {
        const touch = e.touches[0];
        const currentSwipe = swipePosition[id];
        if (currentSwipe) {
            const translateX = Math.min(0, touch.clientX - currentSwipe.startX); // only swipe to left
            setSwipePosition((prev) => ({
                ...prev,
                [id]: { ...currentSwipe, translateX },
            }));
        }
    };

    const handleTouchEnd = (id) => {
        setSwipePosition((prev) => {
            const { translateX } = prev[id];
            if (Math.abs(translateX) > threshold) {
                // greater than threshold -> delete
                //removeNotification(id);
                handleDeleteClick(id);
                return { ...prev, [id]: { ...prev[id], translateX: -200 } };
            } else {
                if (Math.abs(translateX) > 37) {
                    return { ...prev, [id]: { ...prev[id], translateX} };
                }
                else{
                    return { ...prev, [id]: { ...prev[id], translateX: 0 } };
                }

            }
        });
    };


    const handleDeleteClick = async (id) => {
        //removeNotification(id); // delete with button
        try {
            await deleteNotification(id);
            setNotifications((prev) => prev.filter((notification) => notification.id !== id));
        } catch (error) {
            console.error("Fehler beim Löschen der Benachrichtigung: ", error);
        }
    };


    const handleBackClick = () => {
        navigate(-1);
    };


    return (
        <>
            {screenWidth < 1024 ? (
                <NotificationsMobileScreen
                    notifications={notifications}
                    swipePosition={swipePosition}
                    handleTouchStart={handleTouchStart}
                    handleTouchMove={handleTouchMove}
                    handleTouchEnd={handleTouchEnd}
                    handleDeleteClick={handleDeleteClick}
                    handleBackClick={handleBackClick}
                />
            ) : (
                <NotificationsDesktopScreen
                    notifications={notifications}
                    handleDeleteClick={handleDeleteClick}
                    handleBackClick={handleBackClick}
                />
            )}
        </>
    );
};
