import React from "react";
import { Link } from "react-router-dom";
import { MyOrderItem } from "../../components/MyOrderItem";
import { ErrorPopup } from "../../components/Utils/ErrorPopup";
import { NavbarBottomPhone } from "../../components/NavbarBottomPhone";
import "./MyOrdersScreenMobile.css";

export default function MyOrderScreenMobile({
                                                loading,
                                                errorMessage,
                                                filteredOrders,
                                                handleFilterChange,
                                                handleSearchChange,
                                                handleCloseErrorPopup,
                                                searchTerm,
                                                filterOption,
                                                yearOptions,
                                            }) {

    return (
        <div className="my-order-screen">
            <header className="my-order-screen_header">
                <Link to="/account-screen">
                    <img className="my-order-screen_back-icon" alt="Back" src="/img/ep-back-56.png" />
                </Link>
                <h1 className="header-title">Meine Bestellungen</h1>
            </header>

            <div className="search-and-filter">
                <input
                    className="search-bar"
                    type="text"
                    placeholder="Suchen: Status, Pilz, Datum..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />

                <select
                    className="filter-dropdown"
                    value={filterOption}
                    onChange={handleFilterChange}
                >
                    <option value="30Days">Letzte 30 Tage</option>
                    <option value="3Months">Letzte 3 Monate</option>
                    {yearOptions.map((year) => (
                        <option key={year} value={year}>
                            Jahr {year}
                        </option>
                    ))}
                </select>
            </div>

            {loading ? (
                <div className="loading">Lädt Bestellungen...</div>
            ) : errorMessage ? (
                <div className="error-message">{errorMessage}</div>
            ) : filteredOrders.length > 0 ? (
                <div className="order-list">
                    {filteredOrders.map((order) => (
                        <MyOrderItem
                            key={order.orderId}
                            className="order-item"
                            order={order}
                            to={`/myorder-detail/${order.orderId}`}
                        />
                    ))}
                </div>
            ) : (
                <div className="no-orders">Keine Bestellungen für den angegebenen Zeitraum gefunden.</div>
            )}

            {errorMessage && (
                <ErrorPopup message={errorMessage} onClose={handleCloseErrorPopup} />
            )}

            <NavbarBottomPhone className="navbar-bottom-phone-instance" />

        </div>
    );
}
