import React from "react";
import { useNavigate } from "react-router-dom";
import { SettingDetailItem } from "../../components/SettingDetailItem";
import "./SettingOrderNotificationScreen.css";
import { NavbarBottomPhone } from "../../components/NavbarBottomPhone";
import {useNotificationSettings} from "./NotificationSettingsLogic";
import {useWindowWidth} from "../../breakpoints";
import {NotificationSettingsDesktop} from "./NotificationSettingsDesktop";

export const SettingOrderNotificationScreen = () => {
    const screenWidth = useWindowWidth();
    const navigate = useNavigate();
    const { handleMushroomToggle, reservationSelections, handleReservationToggle } = useNotificationSettings();

    const handleBackClick = () => {
        navigate(-1);
    };

    if (screenWidth >= 1024) {
        return <NotificationSettingsDesktop />;
    }


    return (
        <div className="setting-order-notification-screen">
            <header className="setting-order-notification_header">
                <button
                    className="settings-screen_back-button"
                    onClick={handleBackClick}
                >
                    <img className="setting-back-icon" alt="Back" src="/img/ep-back-56.png"/>
                </button>
                <h1 className="setting-order-notification-title">Bestellmitteilungen</h1>
            </header>

            <div className="setting-order-notification-options">
                <SettingDetailItem
                    className="setting-option"
                    title="Bestellung abholbereit"
                    description="Benachrichtige bei abholbereiten Bestellungen"
                    notificationKey="orderReady"
                    defaultState="on"
                    onToggle={handleMushroomToggle}
                />
                <SettingDetailItem
                    className="setting-option"
                    title="Vormerkungen"
                    description="Informiere über geerntete Vormerkungen"
                    notificationKey="reservation"
                    defaultState="on"
                    hasSubList={true}
                    isReservationSubList={true}
                    onToggle={handleMushroomToggle}
                />
            </div>
            <NavbarBottomPhone className="navbar-bottom-phone-instance" />
        </div>
    );
};
