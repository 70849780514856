import React from "react";
import "./ConfirmPopup.css";

export const ConfirmPopup = ({ title, message, onConfirm, onCancel }) => {
    return (
        <div className="confirm-popup-overlay">
            <div className="confirm-popup">
                <h2>{title}</h2>
                <p>{message}</p>
                <div className="confirm-popup-actions">
                    <button className="confirm-popup-cancel" onClick={onCancel}>
                        Abbrechen
                    </button>
                    <button className="confirm-popup-confirm" onClick={onConfirm}>
                        Bestätigen
                    </button>
                </div>
            </div>
        </div>
    );
};
