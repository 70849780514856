import React from "react";
import "./NotificationsMobileStyle.css";
import {formatRelativeTime} from "../../Utils/formateRelativeTime";

export default function NotificationsMobileScreen({
                                                notifications,
                                                swipePosition,
                                                handleTouchStart,
                                                handleTouchMove,
                                                handleTouchEnd,
                                                handleDeleteClick,
                                                handleBackClick,
                                            }) {
    return (
        <>
            <div className="notification-screen">
                <header className="notification-screen_header">
                    <button
                        className="notification-screen_back-button"
                        onClick={handleBackClick}
                    >
                        <img className="notification-screen_back-icon" alt="Back" src="/img/ep-back-56.png"/>
                    </button>
                    <h1 className="notification-screen_header-title">Mitteilungen</h1>
                </header>
                {notifications.length > 0 ? (
                    notifications
                        .slice()    // copy
                        //.reverse()   //new messages first
                        .map((notification) => (
                            <div className="notification-wrapper" key={notification.id}>
                                <div
                                    className="notification-item"
                                    onTouchStart={(e) => handleTouchStart(notification.id, e)}
                                    onTouchMove={(e) => handleTouchMove(notification.id, e)}
                                    onTouchEnd={() => handleTouchEnd(notification.id)}
                                    style={{
                                        transform: `translateX(${swipePosition[notification.id]?.translateX || 0}px)`,
                                        transition: swipePosition[notification.id]?.translateX === 0 ? "transform 0.3s" : "none",
                                    }}
                                >
                                    <div className="notification-content">
                                        <h3>{notification.title}</h3>
                                        <p>{notification.body}</p>
                                        <span className="notification-timestamp">
                                            {formatRelativeTime(notification.timestamp)}
                                        </span>
                                    </div>
                                </div>
                                <div className="notification-delete">
                                <span className="delete-icon" onClick={() => handleDeleteClick(notification.id)}>
                                     <img className={"notification-delete_trash-icon"} src="/img/trash-icon-27.png"
                                          alt="Remove"/>
                                </span>
                                </div>
                            </div>

                        ))
                ) : (
                    <div className="no-notifications-message">
                        Keine Benachrichtigungen vorhanden.
                    </div>
                )}
            </div>
        </>
    );
}
