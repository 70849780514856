export const validatePassword = (password) => {
    if (!password || password.trim().length === 0) {
        return "Das Passwort darf nicht leer sein.";
    }
    if (/\s/.test(password)) {
        return "Das Passwort darf keine Leerzeichen enthalten.";
    }
    //advanced validation, for testing disabled
  /*
    if (password.length < 8) {
        return "Das Passwort muss mindestens 8 Zeichen lang sein.";
    }

    if (!/[A-Z]/.test(password)) {
        return "Das Passwort muss mindestens einen Großbuchstaben enthalten.";
    }

    if (!/[a-z]/.test(password)) {
        return "Das Passwort muss mindestens einen Kleinbuchstaben enthalten.";
    }

    if (!/[0-9]/.test(password)) {
        return "Das Passwort muss mindestens eine Zahl enthalten.";
    }

    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        return "Das Passwort muss mindestens ein Sonderzeichen enthalten.";
    }
*/
    return null; // Kein Fehler, Passwort ist gültig.
};
