import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { ErrorPopup } from "../../components/Utils/ErrorPopup";
import { NavbarBottomPhone } from "../../components/NavbarBottomPhone";

export default function LoginScreenMobile(props) {
    const {
        email, setEmail,
        password, setPassword,
        errorMessage, clearError,
        handleBackClick,
        handleLogin
    } = props;

    return (
        <div className="login-screen">
            <header className="login-header">
                <img className="back-icon"
                     alt="Back" src="/img/ep-back.png"
                     onClick={handleBackClick}
                />
                <div className="header-text">Anmelden</div>
            </header>

            <div className="login-body">
                <div className="mobile_input-container">
                    <input
                        type="email"
                        className="mobile_input-field"
                        placeholder=" "
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <label className="mobile_input-label">E-Mail</label>
                </div>

                <div className="mobile_input-container">
                    <input
                        type="password"
                        className="mobile_input-field"
                        placeholder=" "
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <label className="mobile_input-label">Passwort</label>
                </div>

                <button className="login-button" onClick={handleLogin}>
                    Einloggen
                </button>

                <Link to="/register-screen" className="register-link">
                    Registrieren
                </Link>

                {errorMessage && (
                    <ErrorPopup message={errorMessage} onClose={clearError} />
                )}

            </div>
            <NavbarBottomPhone className="navbar-bottom-phone-instance"/>
        </div>
    );
}
