import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { app } from "./firebase-config";
import {toast} from "react-toastify";
import {sendTokenToServer} from "./Utils/DatabaseFetch";

// initialize
const messaging = getMessaging(app);


// function to request notifications
export const requestNotificationPermission = async () => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            console.log("Benachrichtigungsberechtigungen erteilt.");
            return true;
        } else {
            console.error("Benachrichtigungsberechtigungen wurden verweigert.");
            return false;
        }
    } catch (error) {
        console.error("Fehler beim Anfordern von Benachrichtigungsberechtigungen:", error);
        return false;
    }
};

// function to generate firebase token
export const getFirebaseToken = async () => {
    try {
        const controller = new AbortController();
        const timeout = setTimeout(() => controller.abort(), 10000); // 10 seconds timeout
        const currentToken = await getToken(messaging, {
            vapidKey: "BOuJpOOhIlgm4XpMNLwFCZoRUZVTISO5T8Uzf6R6lRx1cdY_ba1dOjKJS7_s_M0JUEPEB5aNN3fX1azitkvYKuM",
            signal: controller.signal,
        });

        clearTimeout(timeout);

        if (currentToken) {
            console.log("Firebase Token erhalten:", currentToken);
            return currentToken;
        } else {
            console.error("Kein Token verfügbar. Benutzer muss Benachrichtigungen aktivieren.");
            return null;
        }
    } catch (error) {
        console.error("Fehler beim Abrufen des Firebase Tokens:", error);
        return null;
    }
};
let notificationHandler = null;
//
export const listenToForegroundMessages = (handler) => {
    notificationHandler = handler;
    onMessage(messaging, (payload) => {
        console.log("Nachricht im Vordergrund empfangen:", payload);
        if (notificationHandler) {

                notificationHandler(payload);     // Forward message to handler
        }

    });
};




