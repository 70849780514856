import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import ConfirmOrderScreenMobile from "./ConfirmOrderScreenMobile";
import ConfirmOrderScreenDesktop from "./ConfirmOrderScreenDesktop";
import {useWindowWidth} from "../../breakpoints";
import {useCart} from "../../context/CartContext";
import {useAuth} from "../../context/AuthContext";
import {createOrder} from "../../Utils/DatabaseFetch";



export const ConfirmOrderScreen = () => {
    const screenWidth = useWindowWidth();
    const { state } = useLocation();
    const navigate = useNavigate();
    const { cart, clearCart } = useCart();
    const { user } = useAuth();

    const [note, setNote] = useState(state.note || '');
    const [paymentMethod, setPaymentMethod] = useState('BAR'); // default
    const [showThankYouPopup, setShowThankYouPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [errorPopupMessage, setErrorPopupMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const totalSum = state.totalSum;
    const totalWeight = state.totalWeight;

    const paymentMethodMapping = {
        "Barzahlung": "CASH",
        "Überweisung": "BANK_TRANSFER",
        "PayPal": "PAYPAL"
    };



    const handleConfirmOrder = async () => {
        if(user === null) {
            setErrorPopupMessage(
                "Bitte zuerst einloggen, bevor du eine Bestellung aufgibst."
            );
            setShowErrorPopup(true);
            return;
        }

        if(cart.items.length === 0) {
            setErrorPopupMessage(
                "Bitte lege zuerst Produkte in den Warenkorb, bevor du eine Bestellung aufgibst."
            );
            setShowErrorPopup(true);
            return;
        }

        setIsLoading(true);
        try {
            console.log(paymentMethod);
            const orderRequest = {
                order: {
                    customerId: Number(user.customerId),
                    date: new Date().toISOString().split('T')[0],
                    state: 'PENDING',
                    weightG: totalWeight,
                    price: totalSum,
                    customerNote: note,
                    paymentMethod: paymentMethod,
                },
                productOrders: cart.items.map((item) => ({
                    productId: item.productId,
                    weightG: item.quantity,
                    harvestIds: item.harvestIds,
                    mushroomSpecies: item.mushroomSpecies,
                })),
            };

            await createOrder(orderRequest);
            setIsLoading(false);
            setShowThankYouPopup(true);
            clearCart();
        } catch (error) {
            setIsLoading(false);
            console.error('Fehler beim Erstellen der Bestellung:', error);
            setErrorPopupMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.');
            setShowErrorPopup(true);
        }
    };

    const handleCloseErrorPopup = () => {
        setShowErrorPopup(false);
        setErrorPopupMessage("");
    };

    const handleCloseThankYouPopup = () => {
        setShowThankYouPopup(false);
        navigate('/start-screen');
    };

    if (isLoading) {
        return <div className="confirm-order-loading">Lädt...</div>;
    }

    return (
        <>
            {screenWidth < 1024 ? (
                <ConfirmOrderScreenMobile
                    cart={cart}
                    note={note}
                    setNote={setNote}
                    totalSum={totalSum}
                    totalWeight={totalWeight}
                    paymentMethod={paymentMethod}
                    setPaymentMethod={setPaymentMethod}
                    handleConfirmOrder={handleConfirmOrder}
                    showThankYouPopup={showThankYouPopup}
                    onCloseThankYouPopup={handleCloseThankYouPopup}
                    showErrorPopup={showErrorPopup}
                    errorPopupMessage={errorPopupMessage}
                    handleCloseErrorPopup={handleCloseErrorPopup}
                    navigate={navigate}
                />
            ) : (
                <ConfirmOrderScreenDesktop
                    cart={cart}
                    note={note}
                    setNote={setNote}
                    totalSum={totalSum}
                    totalWeight={totalWeight}
                    paymentMethod={paymentMethod}
                    setPaymentMethod={setPaymentMethod}
                    handleConfirmOrder={handleConfirmOrder}
                    showThankYouPopup={showThankYouPopup}
                    onCloseThankYouPopup={handleCloseThankYouPopup}
                    showErrorPopup={showErrorPopup}
                    errorPopupMessage={errorPopupMessage}
                    handleCloseErrorPopup={handleCloseErrorPopup}
                    navigate={navigate}
                />
            )}
        </>
    );
};
