export const validateConfirmPassword = (password, confirmPassword) => {
    if (!confirmPassword) {
        return "Bitte bestätige dein Passwort.";
    }

    if (password !== confirmPassword) {
        return "Die Passwörter stimmen nicht überein.";
    }

    return null; // match
};
