import React from 'react';
import { Link, useLocation  } from 'react-router-dom';
import { ShoppingCartIcon } from '../ShoppingCartIcon';
import { NotificationIcon } from '../NotificationIcon'; // Assuming you have a NotificationIcon component
import './NavigationbarTop.css';
import { useAuth } from "../../context/AuthContext";
import {useNotifications} from "../../context/NotificationContext";

export const NavigationbarTop = () => {
    const { user } = useAuth();
    const { notifications } = useNotifications();
    const location = useLocation();

    const isActive = (path) => {
        // mark start screen as active if there is no specific route
        return location.pathname === path || (path === "/start-screen" && location.pathname === "/");
    };


    return (
        <nav className="navbar">
            <div className="navbar-section navbar-left">
                <Link
                    to="/start-screen"
                    className={`navbar-link ${isActive("/start-screen") ? "active" : ""}`}
                >
                    <div className="navbar-title">Shop</div>
                </Link>
            </div>

            <div className="navbar-section navbar-center-1">
                <Link
                    to="/location-screen"
                    className={`navbar-link ${isActive("/location-screen") ? "active" : ""}`}
                >
                    <div className="navbar-item">Standort</div>
                </Link>
            </div>

            <div className="navbar-section navbar-center-2">
                <Link to="/shoppingcart-screen"
                      className={`navbar-link ${isActive("/shoppingcart-screen") ? "active" : ""}`}
                >
                    <ShoppingCartIcon
                        isActive={isActive("/shoppingcart-screen")}
                        variant={"white"}
                    />
                    <span className="navbar-item">Warenkorb</span>
                </Link>
            </div>

                <div className="navbar-section navbar-right">

                    {/* User */}
                    {user ? (
                        <div className="user-container">
                            <span>Hallo {user.firstName}!</span>
                            {/* notificationIcon only when user is logged in */}
                            <NotificationIcon
                                notifications={notifications}
                                to="/notifications"
                                variant={"white"}
                            />
                            {/* account screen only when user is logged in */}
                            <Link
                                to="/account-screen"
                                className={`navbar-link ${isActive("/account-screen") ? "active" : ""}`}
                            >
                                <div className="navbar-item">Mein Konto</div>
                            </Link>
                        </div>
                    ) : (
                        <div className="user-container">
                            <span>Hallo Gast!</span>
                            {/* login / register when user is logged out */}
                            <Link to="/login-screen" className="navbar-link">
                                <div className="navbar-item">Login / Registrieren</div>
                            </Link>
                        </div>
                    )}
                </div>
        </nav>
    );
};
