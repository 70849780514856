import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./NotificationOption.css";

export const NotificationOption = ({ body, header, icon, to, state }) => {
    return (
        <Link  to={{ pathname: to, state }}  className="notification-option">
            <div className="notification-option-content">
                <h3 className="notification-option-header">{header}</h3>
                <p className="notification-option-body">{body}</p>
            </div>
            <img className="notification-option-icon" src={icon} alt="Details" />
        </Link>
    );
};

NotificationOption.propTypes = {
    body: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};
