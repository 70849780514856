import React, {useContext, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import "./style.css";
import {ErrorPopup} from "../../components/Utils/ErrorPopup";
import {loginUser} from "../../Utils/DatabaseFetch";
import {useAuth} from "../../context/AuthContext";
import {NavbarBottomPhone} from "../../components/NavbarBottomPhone";
import {useWindowWidth} from "../../breakpoints";
import LoginScreenMobile from "./LoginScreenMobile";
import LoginScreenDesktop from "./LoginScreenDesktop";
import {saveCustomerID} from "../../Utils/indexedDB";


export const LoginScreen = () => {
  const screenWidth = useWindowWidth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleBackClick = () => {
    navigate(-1); // back to previous page
  };

  const handleLogin = async () => {
    // check if every field is filled
    if (!email || !password) {
      setErrorMessage("Bitte E-Mail und Passwort eingeben.");
      return;
    }


    const loginData = { email, password };

    try {

      const userData = await loginUser(loginData);
      login(userData);
      await saveCustomerID(userData.customerId);
      navigate("/start-screen"); // to start screen
    } catch (error) {
      console.error("Fehler beim Einloggen:", error);
      setErrorMessage(error.message);
    }
  };

  const clearError = () => setErrorMessage(null);

  const commonProps = {
    email, setEmail,
    password, setPassword,
    errorMessage, clearError,
    handleBackClick,
    handleLogin
  }

  return (
      screenWidth < 1024 ?
          <LoginScreenMobile {...commonProps} /> :
          <LoginScreenDesktop {...commonProps} />
  );
};
