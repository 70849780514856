const stateMapping = {
    PENDING: "Offen",
    COMPLETED: "Abgeschlossen",
    CANCELLED: "Storniert",
    IN_PROGRESS: "In Bearbeitung",
    READY: "Abholbereit"
};




export const stateIconColors = {
    PENDING: "orange", // Offen
    COMPLETED: "gray", // Abgeschlossen
    CANCELLED: "red", // Storniert
    IN_PROGRESS: "blue", // In Bearbeitung
    READY: "green", // Abholbereit
};


export const getStatusIconPath = (state) => {
    const color = stateIconColors[state] || "gray"; // Fallback zu "default"
    return `/img/status-icon-${color}.png`;
};

export const getStateDescription = (state) => {
    return stateMapping[state] || "Unbekannt";
};
