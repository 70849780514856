/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { OysterIcon } from "../OysterIcon";
import "./style.css";


export const HorizontalScroll = ({
                                   horizontalscrollText = "Seitlinge",
                                   className,
                                   override,
                                   divClassName,
                                   onClick,
                                 }) => {
  return (
      <div className={`horizontal-scroll ${className}`} onClick={onClick}>
        {override}
        <div className={`scroll-text ${divClassName}`}>{horizontalscrollText}</div>
      </div>
  );
};

HorizontalScroll.propTypes = {
  horizontalscrollText: PropTypes.string,
  override: PropTypes.element, // PropTyp für Override als Elementtyp festlegen
  onClick: PropTypes.func,
};
