import React from 'react';
import { NavigationbarTop } from '../../components/NavigationbarTop';
import { LastOrderScrollView } from '../../components/LastOrderScrollView';
import './AccountScreenDesktop.css';
import {Footer} from "../../components/Footer/Footer";
import {getStateDescription, getStatusIconPath} from "../../components/Utils/stateIconMapping";
import {ErrorPopup} from "../../components/Utils/ErrorPopup";
import {useNavigate} from "react-router-dom";

export default function AccountScreenDesktop({
                                                 user,
                                                 lastOrderProducts,
                                                 safeLastOrderProducts,
                                                 showErrorPopup,
                                                 initiateCancelOrder,
                                                 errorMessage,
                                                 handleLogout,
                                                 handleCloseErrorPopup
                                             })
{
    const navigate = useNavigate();

    const firstProduct = safeLastOrderProducts && safeLastOrderProducts.length > 0 ? safeLastOrderProducts[0] : null;

    const handleFavoritesClick = () => {
        navigate("/settings?scrollTo=favorites");
    };


    return (
        <>
            <NavigationbarTop />

        <div className="account-screen-desktop">

            <div className="account-content">
                <aside className="account-sidebar">
                    <h2>Mein Konto</h2>
                    <ul>
                        <li><a href="/notifications">Mitteilungen</a></li>
                        <li>
                            <button onClick={handleFavoritesClick}>Favoriten</button>
                        </li>
                        <li><a href="/feedbackscreen">Feedback</a></li>
                        <li><a href="/myorder-screen">Meine Bestellungen</a></li>
                        <li>
                            <button onClick={handleLogout}>Abmelden</button>
                        </li>

                        <li>
                            <h3>Einstellungen</h3>
                        </li>
                        <li><a href="/my-account">Konto</a></li>
                        <li><a href="/settings">Mitteilungen</a></li>


                    </ul>
                </aside>
                <main className="account-main">
                    <h1>Willkommen {user.firstName},</h1>
                    <section className="account-screen-desktop_last-order-section">
                        {/* header */}
                        <div className="account-screen-desktop_last-order-header">
                            <h3>Zuletzt bestellt:</h3>
                            <img
                                src={getStatusIconPath(firstProduct?.state)}
                                alt="Status Icon"
                                className="account-screen-desktop_status-icon"
                            />
                        </div>

                        {/* scroll view */}
                        <div className="account-screen-desktop_last-order-scrollview">
                            <LastOrderScrollView products={lastOrderProducts || []} />
                        </div>

                        {/* status & summary */}
                        <div className="account-screen-desktop_last-order-status">
                            <div className="account-screen-desktop_status">
                                <span>Status:</span>
                                <div className="account-screen-desktop_status-details">
                                    <span className="account-screen-desktop_status-text">
                                        {getStateDescription(firstProduct?.state)}
                                    </span>
                                </div>
                            </div>
                            <div className="account-screen-desktop_order-summary">
                                <span>Gesamtsumme:</span>
                                <span>{safeLastOrderProducts[0]?.price ? `${firstProduct?.price.toFixed(2)} €` : "0,00 €"}</span>
                            </div>
                            <div className="account-screen-desktop_order-summary">
                                <span>Bestellnummer:</span>
                                <span>{safeLastOrderProducts[0]?.orderId ? `${firstProduct?.orderId} ` : "0"}</span>
                            </div>
                            {/* cancel button only visible when pending state */}
                            {firstProduct?.state === 'PENDING' && (
                                <button
                                    className="account-screen-desktop_cancel-button"
                                    onClick={() => initiateCancelOrder(firstProduct?.orderId)}
                                >
                                    Bestellung stornieren
                                </button>
                            )}
                        </div>
                    </section>
                </main>
            </div>
            {showErrorPopup && (
                <ErrorPopup
                    message={errorMessage}
                    onClose={handleCloseErrorPopup}
                />
            )}
            <Footer />
        </div>
        </>
    );
}
