export const calculatePriceBreakdown = (totalPrice) => {
    const taxRate = 0.10;

    const totalPriceInCents  = Math.round(totalPrice * 100);

    // netto and tax price
    const taxPrice = Math.round(totalPriceInCents / (taxRate*100));
    const netPrice = totalPriceInCents - taxPrice;


    return {
        netPrice: (netPrice / 100).toFixed(2),
        taxPrice: (taxPrice / 100).toFixed(2),
        totalPrice: totalPrice.toFixed(2),
    };
};
