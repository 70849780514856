import React from "react";
import {ErrorPopup} from "../../components/Utils/ErrorPopup";
import {SuccessPopup} from "../../components/Utils/SuccessPopup";
import {NavbarBottomPhone} from "../../components/NavbarBottomPhone";
import "./PasswordChangeMobile.css";
import {validatePassword} from "../../Validation/validatePassword";
import {validateConfirmPassword} from "../../Validation/validateConfirmPassword";

export default function PasswordChangeMobile(props) {
    const {
        oldPassword, setOldPassword,
        newPassword, setNewPassword,
        confirmPassword, setConfirmPassword,
        newPasswordError, setNewPasswordError,
        confirmPasswordError, setConfirmPasswordError,
        touchedFields,
        handleBackClick,
        handleUpdate,
        handleInputChange,
        showSuccessPopup,
        responseMessage,
        errorMessage,
        handleCloseErrorPopup,
        handleCloseSuccessPopup,
    } = props;

    return (
        <div className="my-account-settings-password-change-screen">
            <header className="my-account-settings-password-change-header">
                <img
                    className="my-account-settings-password-change-back-icon"
                    alt="Back"
                    src="/img/ep-back.png"
                    onClick={handleBackClick}
                />
                <div className="my-account-settings-password-change-title">Mein Konto</div>
            </header>

            <div className="my-account-settings-password-change-body">
                <div className="mobile_input-container">
                    <input
                        type="password"
                        className="mobile_input-field"
                        placeholder=" "
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <label className="mobile_input-label">altes Passwort</label>
                </div>

                {/* new password */}
                <div
                    className={`mobile_input-container ${newPasswordError ? "invalid" : touchedFields.newPassword && newPassword ? "valid" : ""}`}>
                    <input
                        type="password"
                        className="mobile_input-field"
                        placeholder=" "
                        value={newPassword}
                        onChange={(e) =>
                            handleInputChange(
                                e.target.value,
                                setNewPassword,
                                setNewPasswordError,
                                validatePassword,
                                "newPassword"
                            )
                        }
                    />
                    <label className="mobile_input-label">neues Passwort</label>
                    <span className={`my-account-settings-password-change_mobile-validation-icon ${newPasswordError ? "" : newPassword ? "valid" : ""}`}>
                         {newPasswordError ? "❌" : newPassword ? "✅" : ""}
                    </span>
                    {newPasswordError && <p className="my-account-settings-password-change_mobile-validation-error">{newPasswordError}</p>}
                </div>

                <div
                    className={`mobile_input-container ${confirmPasswordError ? "invalid" : touchedFields.confirmPassword && confirmPassword ? "valid" : ""}`}>
                    <input
                        type="password"
                        className="mobile_input-field"
                        placeholder=" "
                        value={confirmPassword}
                        onChange={(e) =>
                            handleInputChange(
                                e.target.value,
                                setConfirmPassword,
                                setConfirmPasswordError,
                                (val) => validateConfirmPassword(newPassword, val),
                                "confirmPassword"
                            )
                        }
                    />
                    <label className="mobile_input-label">Passwort wiederholen</label>
                    <span className={`my-account-settings-password-change_mobile-validation-icon ${confirmPasswordError ? "" : confirmPassword ? "valid" : ""}`}>
                        {confirmPasswordError ? "❌" : confirmPassword ? "✅" : ""}
                    </span>
                    {confirmPasswordError && <p className="my-account-settings-password-change_mobile-validation-error">{confirmPasswordError}</p>}
                </div>

                <button className="my-account-settings-button" onClick={handleUpdate}>
                    bestätigen
                </button>
            </div>

            {errorMessage && (
                <ErrorPopup
                    message={errorMessage}
                    onClose={handleCloseErrorPopup}
                />
            )}

            {showSuccessPopup && (
                <SuccessPopup
                    message={responseMessage}
                    onClose={handleCloseSuccessPopup}
                />
            )}

            <NavbarBottomPhone className="navbar-bottom-phone-instance"/>
        </div>
    );
}
