import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import "./MyAccountMobile.css";
import {useAuth} from "../../context/AuthContext";
import {deleteAccount, fetchAccountDetails, updateAccount} from "../../Utils/DatabaseFetch";
import {useFetchWithErrorHandling} from "../../Utils/genericFetchWithErrorHandling";
import {validateName} from "../../Validation/validateName";
import {useWindowWidth} from "../../breakpoints";
import MyAccountMobile from "./MyAccountMobile";
import MyAccountDesktop from "./MyAccountDesktop";
import {ConfirmPopup} from "../../components/Utils/ConfirmPopup";
import {ErrorPopup} from "../../components/Utils/ErrorPopup";

export const MyAccount = () => {
  const screenWidth = useWindowWidth();
  const { user, setUser, logout } = useAuth();
  const { data: accountData, loading, errorMessage, setErrorMessage } =
      useFetchWithErrorHandling(() => fetchAccountDetails(user?.customerId), [user?.customerId]);


  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [addressPlaceholder, setAddressPlaceholder] = useState("");
  const navigate = useNavigate();
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [touchedFields, setTouchedFields] = useState({});
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);
  const [errorMessagePopup, setErrorMessagePopup] = useState("");


  React.useEffect(() => {
    // if data is loaded from backend, actualize fields
    if (accountData) {
      setFirstName(accountData.firstName || "");
      setLastName(accountData.lastName || "");
      setEmail(accountData.email || "");
      setPhone(accountData.phoneNumber || "");
      setAddress(accountData.address || "");
    } else if (errorMessage && user) {
      // if there is an error from backend, show local storage data
      setFirstName(user.firstName || "");
      setLastName(user.lastName || "");
      setEmail(user.email || "");
      setPhone(user.phoneNumber || "");
      setAddress(user.address || "");
    }
  }, [accountData, errorMessage, user]);

  useEffect(() => {
    if (errorMessage && !showErrorPopup) {
      setShowErrorPopup(true);
    }
  }, [errorMessage, showErrorPopup]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleUpdate = async () => {
    let valid = true;
    if (!validateName(firstName)) {
      setFirstNameError("Vorname muss mindestens 2 Zeichen lang sein.");
      console.log("Fehler bei Vorname:", firstName);
      valid = false;
    } else {
      setFirstNameError(null);
    }

    const updatedData = {
      firstName,
      lastName,
      email,
      phoneNumber,
      address,
    };

    try {
      if (!firstName || !lastName || !email) {
        setErrorMessage("Vorname, Nachname und E-Mail dürfen nicht leer sein.");
        return;
      }

      await updateAccount(user.customerId, updatedData);

      setUser(updatedData);

      navigate("/account-screen");
    } catch (error) {
      console.error("Fehler beim Aktualisieren des Kontos:", error);
      setShowErrorPopup(true);
      setErrorMessage("Fehler beim Aktualisieren des Kontos. Bitte versuchen Sie es später erneut.");
    }
  };

  if (loading) {
    return <div>Lädt Kontoinformationen...</div>;
  }

  const handleInputChange = (value, setValue, setError, validator, field) => {
    setValue(value);

    // Update touched status
    setTouchedFields((prev) => ({ ...prev, [field]: true }));

    const validationError = validator(value);
    setError(validationError);


  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false);
    setErrorMessage(null);  //delete backend error

  };

  const handleDeleteAccount  = async () => {
    try {
      const responseMessage = await deleteAccount(user.customerId);
      setUser(null);
      logout();
      navigate("/start-screen");
    } catch (error) {
      setErrorMessagePopup("Fehler beim Löschen des Kontos. Bitte versuchen Sie es später erneut.");
    }
  };

  const handleConfirmDelete = () => {
    setShowConfirmDeletePopup(true);
  };




  const commonProps = {
    firstName, setFirstName,
    lastName, setLastName,
    email, setEmail,
    phoneNumber, setPhone,
    address, setAddress,
    addressPlaceholder, setAddressPlaceholder,
    firstNameError, setFirstNameError,
    lastNameError, setLastNameError,
    emailError, setEmailError,
    touchedFields,
    handleBackClick,
    handleUpdate,
    handleInputChange,
    showErrorPopup,
    errorMessage,
    handleCloseErrorPopup,
    handleConfirmDelete,
  };



  return (
      <>
        {screenWidth < 1024 ? (
            <MyAccountMobile {...commonProps} />
        ) : (
            <MyAccountDesktop {...commonProps} />
        )}

        {showConfirmDeletePopup && (
            <ConfirmPopup
                title="Konto löschen"
                message="Möchten Sie Ihr Konto wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden."
                onConfirm={handleDeleteAccount}
                onCancel={() => setShowConfirmDeletePopup(false)}
            />
        )}

        {showErrorPopup && (
            <ErrorPopup message={errorMessagePopup} onClose={() => setShowErrorPopup(false)} />
        )}
      </>
  );
};
