import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import "./PasswordChangeMobile.css";
import {changePassword} from "../../Utils/DatabaseFetch";
import {useAuth} from "../../context/AuthContext";
import PasswordChangeMobile from "./PasswordChangeMobile";
import PasswordChangeDesktop from "./PasswordChangeDesktop";
import {useWindowWidth} from "../../breakpoints";
import {validatePassword} from "../../Validation/validatePassword";
import {validateConfirmPassword} from "../../Validation/validateConfirmPassword";

export const PasswordChange = () => {
  const screenWidth = useWindowWidth();
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [touchedFields, setTouchedFields] = useState({});


  const handleBackClick = () => {
    navigate(-1);
  };

  const handleCloseErrorPopup = () => {
    setErrorMessage(null);
  };

  const handleCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
    navigate("/account-screen");
  };

  const handleInputChange = (value, setValue, setError, validator, field) => {
    setValue(value);
    setTouchedFields((prev) => ({ ...prev, [field]: true }));

    const validationError = validator(value);
    setError(validationError);
  };

  const handleUpdate = async () => {
    // final check
    const pwError = validatePassword(newPassword);
    setNewPasswordError(pwError);

    const cpwError = validateConfirmPassword(newPassword, confirmPassword);
    setConfirmPasswordError(cpwError);

    if (!newPassword || !confirmPassword) {
      setErrorMessage("Passwort und Bestätigung dürfen nicht leer sein.");
      return;
    }

    if (pwError || cpwError) {
      setErrorMessage(pwError || cpwError);
      return;
    }

    const updatedData = { oldPassword, newPassword };

    try {
      const message = await changePassword(user?.customerId, updatedData);
      setResponseMessage(message);
      setUser(updatedData);
      setShowSuccessPopup(true);
    } catch (error) {
      console.error("Fehler beim Aktualisieren des Kontos:", error);
      setErrorMessage("Fehler beim Aktualisieren des Kontos. Bitte versuchen Sie es später erneut.");
    }
  };

  const commonProps = {
    oldPassword, setOldPassword,
    newPassword, setNewPassword,
    confirmPassword, setConfirmPassword,
    newPasswordError, setNewPasswordError,
    confirmPasswordError, setConfirmPasswordError,
    touchedFields,
    handleBackClick,
    handleUpdate,
    handleInputChange,
    showSuccessPopup,
    responseMessage,
    errorMessage,
    handleCloseErrorPopup,
    handleCloseSuccessPopup,
  };


  return screenWidth < 1024 ? (
      <PasswordChangeMobile {...commonProps} />
  ) : (
      <PasswordChangeDesktop {...commonProps} />
  );
};
