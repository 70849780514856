import React, {useEffect, useState} from "react";
import { useWindowWidth } from "../../breakpoints";
import { fetchProducts} from '../../Utils/DatabaseFetch';
import {useFetchWithErrorHandling} from "../../Utils/genericFetchWithErrorHandling";
import StartScreenMobile from "./StartScreenMobile";
import StartScreenDesktop from "./StartScreenDesktop";

export const StartScreen = () => {

   // const [loading, setLoading] = useState(true);
    const [showLoadingMessage, setShowLoadingMessage] = useState(false); // state for delayed charge
    const screenWidth = useWindowWidth();
    const [selectedMushroomType, setSelectedMushroomType] = useState("Alle"); // IconSlider-Filter
    const [searchText, setSearchText] = useState(""); // Searchbar-Filter
    const [availabilityFilter, setAvailabilityFilter] = useState("Alle"); // Dropdown-Filter
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const { data: products, loading, errorMessage, setErrorMessage } = useFetchWithErrorHandling(fetchProducts);

    useEffect(() => {
        // set time for delayed charge
        const timer = setTimeout(() => {
            setShowLoadingMessage(true);
        }, 300); // after that loading product info will appear

        // clear timer
        return () => clearTimeout(timer);
    }, []);

    //this map foreshorten names from icon description
    const mushroomTypeMap = {
        "Alle": "Alle",
        "Shiitake": "Shiitake",
        "Kräuters.": "Kräuterseitling",
        "Seitlinge": "seitling"

    };

    useEffect(() => {
        if (errorMessage && !showErrorPopup) {
            setShowErrorPopup(true);
        }
    }, [errorMessage, showErrorPopup]);


    //filter logic
    const filteredProducts = (products || [])       //empty array fallback for null values
        .filter((product) => {
            // IconSlider-Filter
            const filterName = mushroomTypeMap[selectedMushroomType] || selectedMushroomType;

            // IconSlider-Filter:
            if (filterName === "Alle") {
                return true;
            } else if (filterName === "seitling") {
                // find all products with seitling in their name
                return product.mushroomSpecies.toLowerCase().includes(filterName);
            } else {
                // exact name match
                return product.mushroomSpecies.toLowerCase() === filterName.toLowerCase();
            }
        })
        .filter((product) => {
            // Searchbar-Filter:
            const lowerCaseSearchText = searchText.toLowerCase();
            return (
                product.mushroomSpecies.toLowerCase().includes(lowerCaseSearchText) ||
                product.description.toLowerCase().includes(lowerCaseSearchText)
            );
        })
        .filter((product) => {
            // Dropdown-Filter:
            return availabilityFilter === "Alle" || product.remainingWeight > 0;
        });

    const handleCloseErrorPopup = () => {
        setShowErrorPopup(false);
        setErrorMessage(null);  //delete backend error

    };

    return (
        <div
            className="start-screen"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: screenWidth < 1024 ? "center" : "flex-start",
                justifyContent: screenWidth < 1024 ? "space-between" : "flex-start",
                width: "100%",
                padding: screenWidth < 1024 ? "46px 15px 0px" : "0px 0px",
            }}

        >
            {screenWidth < 1024 && (
                <StartScreenMobile
                    selectedMushroomType={selectedMushroomType}
                    setSelectedMushroomType={setSelectedMushroomType}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    availabilityFilter={availabilityFilter}
                    setAvailabilityFilter={setAvailabilityFilter}
                    loading={loading}
                    showLoadingMessage={showLoadingMessage}
                    filteredProducts={filteredProducts}
                    showErrorPopup={showErrorPopup}
                    errorMessage={errorMessage}
                    handleCloseErrorPopup={handleCloseErrorPopup}
                />
            )}

            {screenWidth >= 1024 && (
                <StartScreenDesktop
                    selectedMushroomType={selectedMushroomType}
                    setSelectedMushroomType={setSelectedMushroomType}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    availabilityFilter={availabilityFilter}
                    setAvailabilityFilter={setAvailabilityFilter}
                    loading={loading}
                    showLoadingMessage={showLoadingMessage}
                    filteredProducts={filteredProducts}
                    showErrorPopup={showErrorPopup}
                    errorMessage={errorMessage}
                    handleCloseErrorPopup={handleCloseErrorPopup}
                />
            )}
        </div>
    );
};
