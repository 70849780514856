import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

export const PopupConfirmeOrder = () => {
  return (
    <div className="popup-confirme-order">
      <div className="popup-confirme-order-wrapper">
        <div className="div-8">
          <div className="text-wrapper-90">Bestellung jetzt aufgeben ?</div>

          <Link className="frame-39" to="/account-screen">
            <div className="text-wrapper-91">Ja</div>
          </Link>

          <Link className="frame-40" to="/shoppingcart-screen">
            <div className="text-wrapper-91">Nein</div>
          </Link>
        </div>
      </div>
    </div>
  );
};
