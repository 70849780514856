import React from "react";
import { ShoppingCartItem } from "../ShoppingCartItem";
import "./style.css";
import {useCart} from "../../context/CartContext";

export const ShoppingCartScroll = ({ cartItems = [] }) => {
    const { removeFromCart } = useCart();

    return (
        <div className="shopping-cart-scroll">
            {Array.isArray(cartItems) && cartItems.length > 0 ? (
                cartItems.map((item, index) => (
                    <ShoppingCartItem
                        key={item.productId}
                        productName={item.mushroomSpecies}
                        quantity={Number(item.quantity)}
                        price={item.price}
                        mushroomSpecies={item.mushroomSpecies}
                        expiryDates={item.expiryDates}
                        harvestIds={item.harvestIds}
                        productId={item.productId}
                        onRemove={() => removeFromCart(item.productId)}
                    />
                ))
            ) : (
                <p>Dein Warenkorb ist leer.</p>
            )}
        </div>
    );
};
