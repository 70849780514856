// MushroomDetailMobile.jsx
import React from "react";
import { NavbarBottomPhone } from "../../components/NavbarBottomPhone";
import { ProductItem } from "../../components/ProductItem";
import { OrderLayout } from "../../components/OrderLayout";
import { KwForecast } from "../../components/KwForecast";
import "./MushroomDetailMobile.css"; // Import der mobilen CSS-Datei

export default function MushroomDetailMobile(props) {
    const {
        product,
        markdownContent,
        showOverlay,
        setShowOverlay,
        errorMessage,
        setErrorMessage,
        refetchProduct,
    } = props;

    return (
        <div className="mushroom-detail">
            <div className="mushroom-detail-container">
                <div className="product-detail-view">
                    <div className="product-item-wrapper">
                        {product && product.productId && product.mushroomSpecies && product.description ? (
                            <ProductItem
                                productId={product.productId}
                                mushroomSpecies={product.mushroomSpecies}
                                description={product.description}
                                remainingWeight={product.remainingWeight ?? 0}
                                price={product.price ?? 0}
                                to={`/product/${product.productId}`}
                            />
                        ) : (
                            <p>Loading product details...</p> // oder ein Lade-Spinner
                        )}
                    </div>

                    {product && product.productId && product.mushroomSpecies ? (
                        <OrderLayout
                            price={product.price}
                            productId={product.productId}
                            mushroomSpecies={product.mushroomSpecies}
                            onFetchError={() => refetchProduct(product.productId)}
                        />
                    ) : ""}
                </div>

                <div className="mushroom-detail-kw-forecast">
                    <div className="mushroom-detail-kw-forecast_header">
                        <div className="mushroom-detail-kw-forecast_header_text-wrapper">
                            erwartete Ernten:
                            <img
                                className="question-mark-icon"
                                src="/img/question-mark-icon.png"
                                alt="Info"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowOverlay(true);
                                }}
                            />
                        </div>
                    </div>

                    <div className="mushroom-detail-kw-forecast_content">
                        <KwForecast
                            productId={product.productId}
                            mushroomSpecies={product.mushroomSpecies}
                        />

                    </div>
                </div>


                {showOverlay && (
                    <div
                        className="overlay"
                        onClick={() => setShowOverlay(false)} // close with a click outside
                    >
                        <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
                            {/* prevents that a click inside the overlay closes */}
                            <h3>Experimentelles Feature</h3>
                            <p>
                                Die Funktion "Erwartete Ernten" basiert auf KI-gestützten Prognosen,
                                die historische Daten auswerten. Da derzeit nur ein kleiner Datensatz
                                verfügbar ist, können die Vorhersagen ungenau sein. Mit der Zeit, und einem größeren
                                Datensatz, werden
                                die Vorhersagen immer präziser werden. Durch einen Klick auf
                                den Stern wird das Datum für dich vorgemerkt, sobald eine Ernte an diesem Tag
                                erfolgt wirst du mit einer Nachricht informiert. Du kannst deine markierten Favoriten in
                                den Kontoeinstellungen einsehen und bearbeiten.
                            </p>
                            <button onClick={() => setShowOverlay(false)}>Schließen</button>
                        </div>
                    </div>
                )}


                <div
                    className="markdown-content"
                    dangerouslySetInnerHTML={{__html: markdownContent}}
                />

            </div>

            {errorMessage && (
                <div className="error-popup">
                    <p>{errorMessage}</p>
                    <button onClick={() => setErrorMessage(null)}>OK</button>
                </div>
            )}

            <NavbarBottomPhone className="navbar-bottom-phone-instance"/>
        </div>
    );
}
