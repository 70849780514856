export const validateEmail = (email) => {
    if (!email) {
        return "E-Mail darf nicht leer sein."; // not empty
    }

    const trimmedEmail = email.trim();

    // max length
    if (trimmedEmail.length > 320) {
        return "E-Mail darf maximal 320 Zeichen lang sein.";  //
    }

    // format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // example:  (name@domain.com)
    if (!emailRegex.test(trimmedEmail)) {
        return "Bitte eine gültige E-Mail-Adresse eingeben.";
    }

    return null;
};
