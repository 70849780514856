
import React from 'react';
import PropTypes from 'prop-types';
import "./ExpiredItemsPopupStyle.css";

export const ExpiredItemsPopup = ({ items, onClose, onItemRequest, onItemRemove, onRemoveAll }) => {
    const expiredItems = items.filter(item => item.isExpired);
    return (
        <div className="popup-overlay">
            <div className="expired-items-popup">
                <button className="close-button" onClick={onClose}>X</button>
                <h3>Abgelaufene Reservierungen</h3>

                <div className="expired-items-list">
                    {expiredItems.map((item, index) => (
                        <div key={index} className="expired-item">
                            <div className="item-container">
                                <div className="item-info">
                                    <span className="expired-item-name">{item.mushroomSpecies}</span>
                                    <span className="expired-item-quantity">{item.quantity} g</span>
                                </div>
                                <div className="item-actions">
                                    <button className="request-button" onClick={() => onItemRequest(item)}>
                                        Erneut anfragen
                                    </button>
                                    <button className="remove-button" onClick={() => onItemRemove(item)}>
                                        <img src="/img/trash-icon-27.png" alt="Entfernen" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="expired-items-actions">
                    <button className="remove-all-button" onClick={onRemoveAll}>
                        Alle Löschen
                    </button>
                    <button className="cancel-button" onClick={onClose}>
                        Abbrechen
                    </button>
                </div>
            </div>
        </div>
    );
};

ExpiredItemsPopup.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        mushroomSpecies: PropTypes.string.isRequired,

        productId: PropTypes.number.isRequired,
        harvestIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    })).isRequired,
    onClose: PropTypes.func.isRequired,
    onItemRequest: PropTypes.func.isRequired,
    onItemRemove: PropTypes.func.isRequired,
    onRemoveAll: PropTypes.func.isRequired,
};
