import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./style.css";
import { FavoriteIcon } from "../FavoriteIcon";
import { fetchForecastByProduct } from "../../Utils/DatabaseFetch";
import {useAuth} from "../../context/AuthContext";

export const KwForecast = ({ productId, mushroomSpecies }) => {
  const [forecastData, setForecastData] = useState([]);
  const { user, updateReservationList } = useAuth();
  const reservationList = user?.notificationSettings?.reservationList || [];


  // Fetch Forecast Data
  const loadForecastData = async () => {
    try {
      const data = await fetchForecastByProduct(productId);

      // format dates (remove year) and sort weeks in ascending order
      const formattedAndSortedData = data
          .map((weekData) => ({
            ...weekData,
            days: weekData.days.map((day) => ({
              ...day,
              fullDate: day.date, // keep full date for logic
              displayDate: new Date(day.date).toLocaleDateString("de-DE", {
                day: "2-digit",
                month: "2-digit",
              }), // format for display
            })),
          }))
          .sort((a, b) => {
            // extract first dates of each week for accurate sorting
            const firstDateA = new Date(a.days[0].date);
            const firstDateB = new Date(b.days[0].date);

            // compare based on actual dates
            return firstDateA - firstDateB;
          });

      setForecastData(formattedAndSortedData);
    } catch (error) {
      console.error("Fehler beim Laden der Vorhersagedaten:", error);
    }
  };

  useEffect(() => {
    if (productId) {
      loadForecastData();
    }
  }, [productId]);

  const handleFavoriteToggle = (date) => {

    const isFavorite = reservationList.some(
        (item) => item.date === date && item.mushroomSpecies === mushroomSpecies
    );

    const updatedList = isFavorite
        ? reservationList.filter(
            (item) => !(item.date === date && item.mushroomSpecies === mushroomSpecies)
        ) // remove
        : [...reservationList, { date, mushroomSpecies }]; // add

    updateReservationList(updatedList); // local storage and backend update
  };

  return (
      <div className="kw-forecast-container">
        {forecastData.map((weekData) => (
            <div key={weekData.week} className="kw-forecast-week">
              {/* calendar week header */}
              <div className="kw-forecast-header">
                <span className="kw-forecast-week-title">{weekData.week}</span>

              </div>

              {/* days of the week */}
              <div className="kw-forecast-days">
                {weekData.days.map((day, index) => (
                    <div key={index} className="kw-forecast-day">
                      <span className="kw-forecast-day-date">{day.displayDate}</span>
                      <span className="kw-forecast-day-amount">{day.amount}g</span>
                      <FavoriteIcon
                          className="kw-forecast-favorite-icon"
                          date={day.fullDate}
                          isActive={reservationList.some(
                              (item) => item.date === day.date && item.mushroomSpecies === mushroomSpecies
                          )}
                          onToggle={handleFavoriteToggle}
                      />
                    </div>
                ))}
              </div>
            </div>
        ))}
      </div>
  );
};

KwForecast.propTypes = {
  productId: PropTypes.number.isRequired,
};
