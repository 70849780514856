export const validateName = (name) => {
  if (!name) {
    return "Name darf nicht leer sein."; //name must not be empty
  }

  const trimmedName = name.trim();

  // min length
  if (trimmedName.length < 2) {
    return "Name muss mindestens 2 Zeichen lang sein.";
  }

  // max length
  if (trimmedName.length > 50) {
    return "Name darf maximal 50 Zeichen lang sein.";
  }

  //only letters and space allowed
  const nameRegex = /^[A-Za-zÄäÖöÜüß\s-]+$/; // inkl. umlaute and -
  if (!nameRegex.test(trimmedName)) {
     return "Name darf nur Buchstaben, Leerzeichen und Bindestriche enthalten.";
  }

  return null;
};
