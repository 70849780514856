import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import {createAccount} from "../../Utils/DatabaseFetch";
import {handleFetchError} from "../../Utils/handleFetchError";
import {useWindowWidth} from "../../breakpoints";
import RegisterScreenMobile from "./RegisterScreenMobile";
import RegisterScreenDesktop from "./RegisterScreenDesktop";
import RegistrationSuccessPopup from "../../components/Utils/RegistrationSuccessPopup";

export const RegisterScreen = () => {
  const screenWidth = useWindowWidth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [addressPlaceholder, setAddressPlaceholder] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);
  const [touchedFields, setTouchedFields] = useState({});
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleRegister = async () => {
    // prevent to send if error
    if (firstNameError) {
      setErrorMessage(firstNameError);
      return;
    }

    if (lastNameError) {
      setErrorMessage(lastNameError);
      return;
    }

    if (emailError) {
      setErrorMessage(emailError);
      return;
    }

    if (phoneError) {
      setErrorMessage(phoneError);
      return;
    }

    if (passwordError) {
      setErrorMessage(passwordError);
      return;
    }

    if (confirmPasswordError) {
      setErrorMessage(confirmPasswordError);
      return;
    }


  const accountData = {
    firstName,
    lastName,
    email,
    phoneNumber,
    address,
    password,
  };

    try {

      await createAccount(accountData);
      setSuccessMessage("Was möchtest du als Nächstes tun?");
      setShowSuccessPopup(true);

    } catch (error) {
      console.error("Fehler beim Erstellen des Accounts:", error);
      const message = handleFetchError(error);
      setErrorMessage(message.message || "Fehler beim Erstellen des Accounts.");
    }
  };

  const handleInputChange = (value, setValue, setError, validator, field) => {
    setValue(value);

    // update touched status
    setTouchedFields((prev) => ({ ...prev, [field]: true }));

    const validationError = validator(value);
    setError(validationError);
  };

  const handleCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
    setSuccessMessage("");
    navigate("/login-screen");

  };

  const clearError = () => setErrorMessage(null);

  const commonProps = {
    firstName, setFirstName, firstNameError, setFirstNameError,
    lastName, setLastName, lastNameError, setLastNameError,
    email, setEmail, emailError, setEmailError,
    phoneNumber, setPhone, phoneError, setPhoneError,
    address, setAddress, addressPlaceholder, setAddressPlaceholder,
    password, setPassword, passwordError, setPasswordError,
    confirmPassword, setConfirmPassword, confirmPasswordError, setConfirmPasswordError,
    touchedFields,
    handleBackClick,
    handleRegister,
    handleInputChange,
    errorMessage, clearError,
    showSuccessPopup, successMessage, handleCloseSuccessPopup
  }

  return (
      <>
        {screenWidth < 1024 ? (
            <RegisterScreenMobile {...commonProps} />
        ) : (
            <RegisterScreenDesktop {...commonProps} />
        )}

        {showSuccessPopup && (
            <RegistrationSuccessPopup
                message={successMessage}
                onClose={() => setShowSuccessPopup(false)}
            />
        )}
      </>
  );
};
