
//database fetches
import {handleFetchError} from "./handleFetchError";
import {platform} from "process";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchProducts = async () => {
    try {
          const response = await fetch(API_BASE_URL + '/product');

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Abrufen Produkte: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching products:', error);
      // throw new Error(error.message ||"Error fetching products:");
        handleFetchError(error);
        throw error;
    }
};


export const fetchSuggestedWeights = async (productId, targetWeight) => {
    try {
        const response = await fetch(`${API_BASE_URL}/product/suggested-weights?productId=${productId}&targetWeight=${targetWeight}`);

        if (!response.ok) {
            let errorMessage = "Ein Fehler ist beim Abrufen der Daten aufgetreten.";
            try {
                const errorData = await response.json();
                errorMessage = errorData.message || errorMessage;
            } catch {
                console.error("Fehler beim Parsen der Fehlerantwort.");
            }
            throw new Error(errorMessage);
        }

        return await response.json();
    } catch (error) {
        //console.error('Error fetching suggested weights:', error);
        //throw new Error(error.message ||"Error fetching suggested weights:");
       // const errorMessage = handleFetchError(error);
        //throw new Error(errorMessage);
        handleFetchError(error);
        throw error;
    }
};

export const fetchProductById = async (productId) => {
    try {
        const response = await fetch(`${API_BASE_URL}/product/${productId}`);
        if (!response.ok) {
            throw new Error("Produkt nicht gefunden.");
        }
        return await response.json();
    } catch (error) {
        console.error("Fehler beim Abrufen des Produkts:", error);
        throw error;
    }
};


export const reserveHarvest = async (harvestIds) => {
    try {
        const response = await fetch(`${API_BASE_URL}/harvest/reserve-harvest`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(harvestIds),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Fehler bei der Reservierung der Ernte");
        }

        return await response.json();
    } catch (error) {
        //console.error("Fehler beim Reservieren der Ernte:", error);
     //   throw new Error(error.message || "Fehler beim Reservieren der Ernte");
        handleFetchError(error);
        throw error;
    }
};


export const releaseHarvests = async (harvestIds) => {
    try {
        const response = await fetch(`${API_BASE_URL}/harvest/release-harvests`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(harvestIds)
        });

        if (!response.ok)   {
            const errorData = await response.json();
            throw new Error(errorData.message ||"Fehler beim Freigeben der Ernte");
        }


        console.log("Ernten wurden freigegeben.");
    } catch (error) {
        //console.error("Fehler beim Freigeben der Ernte:", error);
        //throw new Error(error.message ||"Fehler beim Freigeben der Ernte:");
        handleFetchError(error);
        //throw error;          //ignore this, because backend scheduled handle it anyway, no impact user experience
    }
};


export const createOrder = async (orderRequest) => {
    try {
        const response = await fetch(`${API_BASE_URL}/order/place-order`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(orderRequest),
        });
        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Erstellen der Bestellung: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }
        return await response.json();

    }
    catch (error) {
        console.error("Fehler beim Erstellen der Bestellung:", error);
        throw error;
    }
}


export const actualizeRemainingWeightForProduct = async (productId) => {
    try {
        const response = await fetch(`${API_BASE_URL}/product/${productId}/actualize-remaining-weight`, {
            method: 'POST'
        });
        const message = await response.text();
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Failed to create account");
        }
    } catch (error) {
        console.error(error.message || "Network error:", error);
    }
};

export const createAccount = async (accountData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/customer`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(accountData),
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim erstellen des Accounts: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        // check if answer is json
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            return await response.text();

        }
    } catch (error) {
        throw new Error(error.message ||"Fehler bei der Registrierung.");
    }
};


export const loginUser = async (loginData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/customer/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(loginData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Login fehlgeschlagen");
        }

        return await response.json();
    } catch (error) {
        throw new Error(error.message || "Fehler beim Einloggen.");
    }
};


export const fetchLastOrderProducts = async (customerId) => {
    try {
        const response = await fetch(`${API_BASE_URL}/order/last-order?customerId=${customerId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Abrufen der letzten Order: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }
        // check if answer is json
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            const textResponse = await response.text();
            if (textResponse === "Noch keine Bestellung vorhanden") {
                return null;
            }
            throw new Error("Unerwartete Antwort vom Server");
        }
    } catch (error) {
        throw error;
    }
};


export const fetchOrdersByTimeRange = async (customerId, timeRange) => {
    try {

        const response = await fetch(
            `${API_BASE_URL}/order/by-period?customerId=${customerId}&period=${timeRange}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        //if no order where found, ignore the messages
        if (response.status === 404) {
            return []; // empty response
        }

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Abrufen der Bestellungen: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        const data = await response.json();


        return Object.values(data);
    } catch (error) {
        if (error.name === 'TypeError') {

            error.name = 'NetworkError';
        }
        throw error;
    }
}

export const fetchOrderDetail = async (orderId) => {
    try {

        const response = await fetch(
            `${API_BASE_URL}/order/my-order?orderId=${orderId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Abrufen der Bestellungen: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        const data = await response.json();


        return data;
    } catch (error) {
        if (error.name === 'TypeError') {

            error.name = 'NetworkError';
        }
        throw error;
    }
}


export const sendTokenToServer = async (customerId, token) => {
    try {

        const payload = { customerId, token};
        //console.log("Daten an das Backend:", JSON.stringify( payload));
        const response = await fetch(`${API_BASE_URL}/notifications/register-token`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });

        console.log("Im FetcheTokenToServer: " + JSON.stringify(payload));


        if (response.ok) {
            console.log("Token erfolgreich an Server gesendet.");
        } else {
            console.error("Fehler beim Senden des Tokens:", await response.text());
        }
    } catch (error) {
        console.error("Fehler beim Senden des Tokens:", error);
    }
};

export const removeTokenFromServer = async (customerId, token) => {
    try {
        const payload = { customerId, token};
        const response = await fetch(`${API_BASE_URL}/notifications/remove-token`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Löschen des Token: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            return await response.text();
        }

    }
    catch (error) {
        throw error;
    }

}



export const fetchAccountDetails = async (customerId) => {
    try {
        const response = await fetch(`${API_BASE_URL}/customer/${customerId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Abrufen der Account Informationen: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        return await response.json();
    } catch (error) {
        console.error("Fehler beim Abrufen der Kontoinformationen:", error);
        //handleFetchError(error);
        throw error;
        //throw new Error(error.message || "Fehler beim Abrufen der Kontoinformationen.");
    }
};


export const updateAccount = async (customerId, updatedData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/customer/${customerId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedData),
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim aktualsieren der Account Daten: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        return await response.json();
    } catch (error) {
        console.error("Fehler beim Aktualisieren der Kontoinformationen:", error);
        //throw new Error(error.message || "Fehler beim Aktualisieren der Kontoinformationen.");
        handleFetchError(error);
        throw error;
    }
};

export const changePassword = async (customerId, updatedData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/customer/change-password/${customerId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData || "Fehler beim Aktualisieren des Passworts.");
        }

        //standard return from backend is text
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            return await response.text();
        }

    } catch (error) {
        console.error("Fehler beim Aktualisieren des Passworts:", error);
        throw new Error(error.message || "Fehler beim Aktualisieren des Passworts.");
    }
};

export const sendFeedback = async (feedbackData) => {

    try {
        const response = await fetch(`${API_BASE_URL}/feedback`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(feedbackData),
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Senden des Feedbacks: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        return await response.json();
    } catch (error) {
        //handleFetchError(error);
        throw error;
    }
};


export const updateNotificationSettingsOnServer = async (customerId, settings) => {
    try {
        const response = await fetch(`${API_BASE_URL}/notification-settings/by-customer/${customerId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(settings),
        });

        if (!response.ok) {
            throw new Error("Fehler beim Aktualisieren der Benachrichtigungseinstellungen.");
        }

        return await response.json();
    } catch (error) {
        console.error("Error updating notification settings:", error);
        throw error;
    }
};


export const fetchNotificationSettings = async (customerId) => {
    console.log("fetchNotificationSettings", customerId);
    try {
        const response = await fetch(`${API_BASE_URL}/notification-settings/by-customer/${customerId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim laden der Notification Settings: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching notification settings:", error);
        throw error;
    }
};


export const fetchProductNames = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/product/names`);

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim laden der Produktnamen: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchForecastByProduct = async (productId) => {
    try {
        const response = await fetch(`${API_BASE_URL}/harvest/forecast/${productId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim laden der Forecast: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        // Parse JSON response
        return await response.json();

    } catch (error) {
        console.error("Fehler beim Abrufen der Forecast:", error);
        throw error;
    }
};

export const cancelOrder = async (orderId) => {
    try {
        const response = await fetch(`${API_BASE_URL}/order/cancel-order/${orderId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Abrufen der letzten Order: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            return await response.text();
        }

    }
    catch (error) {
        throw error;
    }

}

export const deleteAccount = async(customerId) => {
    try {
        const response = await fetch(`${API_BASE_URL}/customer/${customerId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler löschen des Accounts: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            return await response.text();
        }
    }
    catch (error) {
        throw error;
    }
}

export const getNotifications = async (customerId) => {
    try {
        const response = await fetch(`${API_BASE_URL}/notifications/customer/${customerId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Abrufen der Benachrichtigungen: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            return await response.text();
        }
    }
    catch (error) {
        throw error;
    }
};

export const deleteNotification = async (notificationHistoryId) => {
    try {
        const response = await fetch(`${API_BASE_URL}/notifications/customer/${notificationHistoryId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Löschen der Benachrichtigung: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            return await response.text();
        }
    }
    catch (error) {
        throw error;
    }
};











