// src/Utils/indexedDB.jsx

import { openDB } from 'idb';

const DB_NAME = 'notifications-db';
const STORE_NAME = 'notifications';

// open or create indexDB
export const openDatabase = async () => {
    return openDB(DB_NAME, 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                const store = db.createObjectStore(STORE_NAME, { keyPath: 'id' });
                store.createIndex('customerId', 'customerId', { unique: false });
            }
        },
    });
};

// add notification to indexDB
export const addNotificationToDB = async (notification) => {
    try {
        const db = await openDatabase();
        await db.put(STORE_NAME, notification);
    } catch (error) {
        console.error('Error adding notification to DB:', error);
        throw error;
    }
};

// remove notification from indexDB
export const removeNotificationFromDB = async (id) => {
    try {
        const db = await openDatabase();
        await db.delete(STORE_NAME, id);
    } catch (error) {
        console.error('Error removing notification from DB:', error);
        throw error;
    }
};

// load notifications from indexDB
export const getAllNotifications = async (customerId) => {
    try {
        const db = await openDatabase();
        return db.getAllFromIndex(STORE_NAME, 'customerId', IDBKeyRange.only(customerId));
    } catch (error) {
        console.error('Error fetching notifications from DB:', error);
        throw error;
    }
};

export function saveCustomerID(customerID) {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('app-db', 1);
        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('user-store')) {
                db.createObjectStore('user-store', { keyPath: 'id' });
            }
        };
        request.onsuccess = (event) => {
            const db = event.target.result;
            const transaction = db.transaction(['user-store'], 'readwrite');
            const store = transaction.objectStore('user-store');
            store.put({ id: 'customerID', value: customerID });
            transaction.oncomplete = () => resolve();
            transaction.onerror = (e) => reject(e.target.error);
        };
        request.onerror = (event) => reject(event.target.error);
    });
}
