import React from 'react';
import {useWindowWidth} from "../../breakpoints";
import NotificationsHelpMobile from "./NotificationsHelpMobile";
import NotificationsHelpDesktop from "./NotificationsHelpDesktop";

export const NotificationsHelp = () => {
    const screenWidth = useWindowWidth();


    return (
        <>
            {screenWidth < 1024 ? (
                <NotificationsHelpMobile />
            ) : (
                <NotificationsHelpDesktop  />
            )}
        </>
    )
}
