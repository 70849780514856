import React from "react";
import { useNavigate } from "react-router-dom";
import { SettingDetailItem } from "../../components/SettingDetailItem";
import "./SettingMushroomNotificationScreen.css";
import { NavbarBottomPhone } from "../../components/NavbarBottomPhone";
import {useNotificationSettings} from "./NotificationSettingsLogic";
import {useWindowWidth} from "../../breakpoints";
import {NotificationSettingsDesktop} from "./NotificationSettingsDesktop";

export const SettingMushroomNotificationScreen = () => {
    const screenWidth = useWindowWidth();
    const navigate = useNavigate();
    const {
        isMushroomIntervalExpanded,
        setIsMushroomIntervalExpanded,
        handleIntervalToggle,
        handleMushroomToggle,
        productList,
        favoriteSelections,
        handleFavoriteToggle
    } = useNotificationSettings();

    const handleBackClick = () => {
        navigate(-1);
    };

    if (screenWidth >= 1024) {
        return <NotificationSettingsDesktop />;
    }

    return (
        <div className="setting-screen">
            <header className="setting-header">
                <button
                    className="settings-screen_back-button"
                    onClick={handleBackClick}
                >
                    <img className="setting-back-icon" alt="Back" src="/img/ep-back-56.png"/>
                </button>
                <h1 className="setting-title">Pilzmitteilungen</h1>
            </header>

            <div className="setting-options">
                <div
                    className="expandable-container"
                    onClick={() => setIsMushroomIntervalExpanded(!isMushroomIntervalExpanded)}
                    role="button"
                >
                    <div className="expandable-header">
                        <div className="expandable-text">
                            <h3 className="expandable-title">Mitteilungsintervall</h3>
                            <p className="expandable-description">
                                Wähle, wie oft du Benachrichtigungen erhalten möchtest.
                            </p>
                        </div>
                        <img
                            className={`expandable-icon ${isMushroomIntervalExpanded ? "expanded" : ""}`}
                            src="/img/ic-baseline-greater-than-16.png"
                            alt="Toggle Expand"
                        />
                    </div>
                </div>
                {isMushroomIntervalExpanded && (
                    <div className="expandable-sublist">
                        <SettingDetailItem
                            className="setting-option"
                            title="Pilzflüsterer"
                            description="max. einmal pro Sorte/24h"
                            notificationKey="mushroomWhisperer"
                            defaultState="on"
                            onToggle={handleIntervalToggle}
                        />
                        <SettingDetailItem
                            className="setting-option"
                            title="Schwammalheißhunger"
                            description="Jede Ernte informiert"
                            notificationKey="mushroomCravings"
                            onToggle={handleIntervalToggle}
                        />
                    </div>
                )}

                <SettingDetailItem
                    className="setting-option"
                    title="Alle Pilze"
                    description="Alle Sorten benachrichtigen"
                    notificationKey="allMushrooms"
                    onToggle={handleMushroomToggle}
                />
                <SettingDetailItem
                    className="setting-option"
                    title="Favoriten"
                    description="Nur deine Favoriten"
                    notificationKey="favoriteMushrooms"
                    onToggle={handleMushroomToggle}
                    hasSubList={true}
                />
            </div>
            <NavbarBottomPhone className="navbar-bottom-phone-instance" />
        </div>
    );
};
